import { CardBody, Col, Row, Table } from "reactstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useQuery } from "@apollo/client";
import { GET_ALL_ADDITIONAL_INFO_BY_DEPT_ID, GET_ALL_USER } from "../../../../GraphQL/Queries";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

const DeptMemberTable = () => {
    const history = useHistory();
    const [deptMember, setDeptMember] = useState([])
    const deptID = history.location.state?.deptID;
    const deptName = history.location.state?.deptName;
    const LoadAllDeptMembersByDeptId = useQuery(GET_ALL_ADDITIONAL_INFO_BY_DEPT_ID, {
        variables: {
            dept_id: deptID
        }
    });
    useEffect(() => {
        if (LoadAllDeptMembersByDeptId?.data?.getAllAdditionalInfoByDeptId?.length) {
            setDeptMember(LoadAllDeptMembersByDeptId?.data?.getAllAdditionalInfoByDeptId);
            LoadAllDeptMembersByDeptId?.refetch();
        }

    }, [LoadAllDeptMembersByDeptId?.data?.getAllAdditionalInfoByDeptId, deptID])
    return (
        <CardBody>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">{deptName ? deptName + ` member list` : `Department member list`}</h3>
                </Col>
            </Row>
            <Row>
                <Table role="table" striped hover responsive>
                    <thead>
                        <tr role="row">
                            <th>#</th>
                            <th>Image</th>
                            <th>RFID</th>
                            <th>Name</th>
                            <th>Office ID</th>
                            <th>Department Name</th>
                            <th>Designation</th>
                            <th>Email</th>
                            <th>Phone</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deptMember?.map((item, index) => (<tr key={item?.user_id}>
                            <td>{index + 1}</td>
                            <td role="cell">
                                <div className="employee-list__img-wrap">
                                    <img src={item?.user?.images ? item?.user?.images : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"} alt="image" />
                                </div>
                            </td>
                            <td>{item?.rfid}</td>
                            <td>{item?.user?.name}</td>
                            <td>{item?.office_id}</td>
                            <td>{item?.departmentInfo?.name}</td>
                            <td>{item?.designationInfo?.name}</td>
                            <td>{item?.user?.email}</td>
                            <td>{item?.user?.phone}</td>
                        </tr>))}
                    </tbody>
                </Table>
            </Row>
        </CardBody>

    );
}

export default DeptMemberTable;