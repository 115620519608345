import { Modal, ModalBody, Button, ButtonToolbar, Col, Row } from "reactstrap";

import { SelectField } from "@/shared/components/form/Select";
import { useForm } from "react-hook-form";
import FormField from "../../FormField";
import useLeaveApplicationDropdownsQuery from "../../../../../GraphQL/hooks/queries/useLeaveApplicationDropdownsQuery";
import { useEffect, useState } from "react";
import useUserDropdownsQuery from "../../../../../GraphQL/hooks/queries/useUserDropdownsQuery";
import useDepartmentDropdownsQuery from "../../../../../GraphQL/hooks/queries/useDepartmentDropdownsQuery";
import useCreateLeaveDeductionMutation from "../../../../../GraphQL/hooks/mutations/useCreateLeaveDeductionMutation";
import { loginInfo } from "../../../../../utils/helpers";
import { toast } from "react-toastify";

const LeaveDeductionForm = ({ toggle, modal, leaveDeduction }) => {
  const loggedInfo = loginInfo();
  const [leaveDeductionForm, setLeaveDeductionForm] = useState({
    leaveTypes: [],
    users: [],
    departments: [],
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm();

  // ** leave deduction mutation
  const { createLeaveDeduction, loading: createLeaveDeductionLoading } =
    useCreateLeaveDeductionMutation();

  // ** leave type query
  const LoadApplicationDropdownData = useLeaveApplicationDropdownsQuery({
    skip: !watch("employee")?.value,
    variables: {
      input: {
        user_id: watch("employee")?.value,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  // ** employee dropdown query
  const LoadEmployeeDropdownData = useUserDropdownsQuery({
    skip: !watch("department")?.value,
    variables: {
      input: {
        dept_id: watch("department")?.value,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  // ** department dropdown query
  const LoadDepartmentDropdownData = useDepartmentDropdownsQuery({
    variables: {
      input: {},
    },
    notifyOnNetworkStatusChange: true,
  });

  // ** set leave types for dropdown
  useEffect(() => {
    if (LoadApplicationDropdownData?.data?.leaveTypes) {
      setLeaveDeductionForm((prevState) => ({
        ...prevState,
        leaveTypes: LoadApplicationDropdownData?.data?.leaveTypes,
      }));
    }
  }, [LoadApplicationDropdownData?.data]);

  // ** set employees for dropdown
  useEffect(() => {
    if (LoadEmployeeDropdownData?.data?.users) {
      setLeaveDeductionForm((prevState) => ({
        ...prevState,
        users: LoadEmployeeDropdownData?.data?.users,
      }));
    }
  }, [LoadEmployeeDropdownData?.data]);

  // ** set department for dropdown
  useEffect(() => {
    if (LoadDepartmentDropdownData?.data?.departments) {
      setLeaveDeductionForm((prevState) => ({
        ...prevState,
        departments: LoadDepartmentDropdownData?.data?.departments,
      }));
    }
  }, [LoadDepartmentDropdownData?.data]);

  // ** submit
  const onSubmit = async (values) => {
    const deducted = await createLeaveDeduction({
      variables: {
        input: {
          employee_id: values?.employee?.value,
          deductor_id: loggedInfo?.id,
          days: Number(values?.days),
          reason: values?.reason,
          leave_type_id: values?.leaveType?.value,
        },
      },
    });
    if (deducted?.data?.createLeaveDeduction?.status_code == 200) {
      LoadApplicationDropdownData?.refetch({
        input: {
          user_id: watch("employee")?.value,
        },
      });
      leaveDeduction?.leaveDeductions?.refetch({
        input: {
          page: leaveDeduction?.page,
          size: leaveDeduction?.size,
        },
      });
      toast.success(deducted?.data?.createLeaveDeduction?.message);
      toggle();
    }
    if (deducted?.data?.createLeaveDeduction?.status_code !== 200) {
      toast.error(deducted?.data?.createLeaveDeduction?.message);
    }
  };
  return (
    <Modal toggle={() => toggle()} isOpen={modal}>
      <ModalBody>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Department</span>
              <div className="form__form-group-field ">
                <FormField
                  name="department"
                  errors={errors}
                  control={control}
                  component={SelectField}
                  options={leaveDeductionForm?.departments}
                  placeholder="Select department"
                  rules={{ required: "This is required field" }}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Employee</span>
              <div className="form__form-group-field ">
                <FormField
                  name="employee"
                  errors={errors}
                  control={control}
                  component={SelectField}
                  options={leaveDeductionForm?.users}
                  placeholder="Select employee"
                  rules={{ required: "This is required field" }}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Leave Type</span>
              <div className="form__form-group-field ">
                <FormField
                  errors={errors}
                  name="leaveType"
                  control={control}
                  component={SelectField}
                  options={leaveDeductionForm?.leaveTypes}
                  placeholder="Select leave type"
                  onChange={(e) => {
                    setValue("leaveType", e);
                    if (e.leave_left == 0) {
                      setError("leaveType", {
                        type: "custom",
                        message: `${e.label?.split(" ")[0]} leave left only ${
                          e.leave_left
                        } day`,
                      });
                    } else {
                      clearErrors("leaveType");
                    }
                  }}
                  rules={{ required: "This is required field" }}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group">
              <label className="form__form-group-label">
                Number of deduction day
              </label>
              <div className="form__form-group-field">
                <FormField
                  errors={errors}
                  name="days"
                  component="input"
                  control={control}
                  type="number"
                  step="0"
                  placeholder={`Enter number of deduction day`}
                  rules={{ required: "This is required field" }}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group">
              <label className="form__form-group-label">Reason</label>
              <div className="form__form-group-field">
                <FormField
                  errors={errors}
                  name="reason"
                  component="textarea"
                  control={control}
                  type="text"
                  placeholder={`Enter reason`}
                  rules={{ required: "This is required field" }}
                />
              </div>
            </div>
          </Col>

          <Col xs={12}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <ButtonToolbar className="form__button-toolbar">
                <Button
                  className="btn-sm"
                  onClick={() => toggle()}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  disabled={watch("leaveType")?.leave_left == 0 ? true : false}
                  className="btn-sm ml-3"
                  color="primary"
                  type="submit"
                >
                  {createLeaveDeductionLoading ? "Processing..." : "Deduct"}
                </Button>
              </ButtonToolbar>
            </div>
          </Col>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default LeaveDeductionForm;
