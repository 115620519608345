import { gql } from "@apollo/client";
export const LeaveDeductionType = gql`
  fragment LeaveDeductionType on LeaveDeductionType {
    id
    employee_id
    deductor_id
    leave_type_id
    employee {
      id
      name
    }
    deductor {
      id
      name
    }
    leave_type {
      id
      name
    }
    days
    reason
    log_id
    status_code
    message
    createdAt
    updatedAt
  }
`;
