import { gql } from "@apollo/client";
export const GenderType = gql`
  fragment GenderType on GenderType {
    id
    name
    log_id
    status_code
    message
    createdAt
    updatedAt
  }
`;
