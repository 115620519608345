import React, { useState, useEffect } from "react";
import { Badge, Col } from "reactstrap";
import "./fan.css";
const Fan = ({
  speed,
  setSpeed,
  increaseSpeed,
  decreaseSpeed,
  onLoading,
  switchNumber,
}) => {
  const bladeStyles = {
    animation: speed === 0 ? "none" : `rotate ${10 / speed}s linear infinite`,
    transformOrigin: "50% 50%",
  };

  const bladeClasses = ["blade", "blade1", "blade2", "blade3"];

  return (
    <Col lg={6}>
      <div className="card border px-2 py-4 align-items-center shadow-sm">
        <div className="fan-container">
          <div className="fan">
            {bladeClasses.map((className, index) => (
              <div className={className} key={index} style={bladeStyles} />
            ))}
          </div>
          <div className="speed-control mt-3">
            <button
              className="inbox__email-btn border rounded"
              onClick={decreaseSpeed}
            >
              -
            </button>
            <span className="mx-3">Speed: {speed}</span>
            <button
              className="inbox__email-btn border rounded"
              onClick={increaseSpeed}
            >
              +
            </button>
          </div>
          <Badge
            style={{ fontSize: 15 }}
            className="mt-3"
            color="warning"
            size="small"
          >
            Load 3
          </Badge>
          <div>
            {onLoading?.switch === switchNumber && onLoading?.loading
              ? "Processing..."
              : ""}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default Fan;

// className="inbox__email-btn border rounded"
