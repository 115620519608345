import withAuthFirebase from "@/shared/components/auth/withAuthFirebase";
import LogInFormPhoto from '@/shared/components/loginPhotoForm/LogInFormPhoto';
import LogInForm from "@/shared/components/loginForm/LogInForm";
import showResults from "@/utils/showResults";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ALL_DISTRICT,
  ALL_UNION,
  ALL_UPAZILLA,
  LOAD_DIVISION,
} from "../../../GraphQL/Queries";
import classnames from 'classnames';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import CustomerLogin from "../CustomerLogin";
import CustomerLoginForm from "../CustomerLogin/CustomerLoginForm";

const LogIn = ({ changeIsOpenModalFireBase }) => {
  const allDivision = useQuery(LOAD_DIVISION);
  const allDistricts = useQuery(ALL_DISTRICT);
  const allUpazilla = useQuery(ALL_UPAZILLA);
  const allUnion = useQuery(ALL_UNION);
  const addressState = useSelector(state => state);
  useEffect(() => {
    localStorage.setItem(
      "division",
      JSON.stringify(allDivision?.data?.getDivision)
    );
    localStorage.setItem(
      "district",
      JSON.stringify(allDistricts?.data?.getDistrict)
    );
    localStorage.setItem(
      "upazilla",
      JSON.stringify(allUpazilla?.data?.getUpazilla)
    );
    localStorage.setItem("union", JSON.stringify(allUnion?.data?.getUnion));
    allDivision?.refetch()
  }, [
    allDistricts?.data?.getDistrict?.length,
    allUpazilla?.data?.getUpazilla?.length,
    allUnion?.data?.getUnion?.length,
    allDivision?.data?.getDivision?.length,
    addressState,
  ]);

  const [activeTab, setActiveTab] = useState('2');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };


  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        {/* <LogInForm onSubmit={showResults} form="log_in_form" /> */}
        <div className="tabs__wrap">
          <Nav tabs>
            {/* <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => toggle('1')}
              >
                Customer
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => toggle('2')}
              >
                Office
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <CustomerLoginForm
                onSubmit={showResults}
                form="log_in_form"
              />
            </TabPane>
            <TabPane tabId="2">
              <LogInForm onSubmit={showResults} form="log_in_form" />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};
LogIn.propTypes = {
  changeIsOpenModalFireBase: PropTypes.func.isRequired,
};

export default withAuthFirebase(LogIn);
