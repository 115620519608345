import React, { useState } from 'react';
import { Badge, Button, Card, CardBody, Col, Input, Table } from "reactstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from "@mui/icons-material/Edit";
import TableDataLoading from '../../../utils/HelperComponent/TableDataLoading';
import moment from 'moment';
import { textToWords } from '../../../utils/helpers';
import DetailsModal from './DetailsModal';
import { useHistory } from 'react-router';

const ProposalTable = ({ data, loading, setLoading, loggedInfo, roles, deleteHandler, setIsAssigned, deptHeadInfo, department, setDetailsId, filterHandler }) => {
    const history = useHistory();
    const [modal, setModal] = useState(false);
    const [proposal, setProposal] = useState({});

    const toggle = () => {
        setModal(prevState => !prevState);
    };
    return (
        <Col md={12} lg={12}>
            <Card>
                <CardBody className="products-list">
                    {/* <div className="table__search">
                        <Input
                            className="table__search table__search-input"
                            placeholder={`Search by name`}
                        />
                    </div> */}
                    <Table role="table" striped hover responsive>
                        <thead>
                            <tr role="row">
                                <th>#</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Start</th>
                                {roles?.includes("System Admin") ? <th>Department</th> : null}
                                <th>
                                    <select
                                        className="table-filter"
                                        style={{ border: "none", fontWeight: "bold" }}
                                        onChange={filterHandler(this)}
                                    >
                                        <option value="All">Status</option>
                                        <option value="Pending">Pending</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="Completed">Completed</option>
                                    </select>
                                </th>
                                <th>Date</th>
                                {loggedInfo?.role == "Customer" ? <th>Action</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? <TableDataLoading colSpan={6} /> : data?.length > 0 ? data?.map(item => (
                                <tr key={item?.tableIndex}>
                                    <th onClick={() => { toggle(); setProposal(item) }}>{item?.tableIndex}</th>
                                    <td onClick={() => { toggle(); setProposal(item) }}>{textToWords(item?.title, 5)}</td>
                                    <td onClick={() => { toggle(); setProposal(item) }}>{textToWords(item?.description, 8)}</td>
                                    {item?.end ? <td onClick={() => { toggle(); setProposal(item) }}>
                                        {moment(item?.start).format('DD/MM/YYYY')}
                                        -
                                        {moment(item?.end).format('DD/MM/YYYY')}
                                        &nbsp;
                                        (
                                        {moment(item?.end).diff(moment(item?.start), 'days')}
                                        {moment(item?.end).diff(moment(item?.start), 'days') > 1 ? ' days' : ' day'}
                                        )
                                    </td>
                                        : <td onClick={() => { toggle(); setProposal(item) }}>{moment(item?.start).format('DD/MM/YYYY')}</td>}

                                    {roles?.includes("System Admin") ? <td onClick={() => { toggle(); setProposal(item) }}>{item?.projectProposalDeptInfo?.name ? item?.projectProposalDeptInfo?.name : <Badge color="warning">Not Assigned</Badge>}</td> : null}
                                    <td onClick={() => { toggle(); setProposal(item) }}>
                                        <Badge
                                            color={
                                                item?.projectStatus == "Pending"
                                                    ? "danger"
                                                    : item?.projectStatus == "In Progress"
                                                        ? "primary"
                                                        : item?.projectStatus == "Completed"
                                                            ? "success"
                                                            : "warning"
                                            }
                                        >
                                            {item?.projectStatus}
                                        </Badge>
                                    </td>
                                    <td onClick={() => { toggle(); setProposal(item) }}>{moment(new Date(Number(item?.createdAt))).format('DD-MM-YYYY, h:mm a')}</td>
                                    {item?.projectStatus == "In Progress" && item?.projectProposalDeptInfo?.user?.id == loggedInfo?.id && <td>
                                        <button onClick={()=> history.push({pathname: "/project/discussion", state: {chat:item}})} className="inbox__email-btn" type="button" aria-label="edit">
                                            <span class="lnr lnr-bubble"></span>
                                        </button>
                                    </td>}
                                    {item?.projectStatus == "In Progress" && loggedInfo?.role == 'Customer' && <td>
                                        <button onClick={()=> history.push({pathname: "/project/discussion", state: {chat:item}})} className="inbox__email-btn" type="button" aria-label="edit">
                                            <span class="lnr lnr-bubble"></span>
                                        </button>
                                    </td>}
                                    {item?.projectStatus == "Pending" ? loggedInfo?.role == "Customer" ? <td>
                                        <button onClick={() => deleteHandler(item?.id)} className="inbox__email-btn" type="button" aria-label="edit">
                                            <DeleteIcon fontSize="small" />
                                        </button>
                                        <button onClick={() => history.push({ pathname: "/project/new", state: { data: item } })} className="inbox__email-btn" type="button" aria-label="edit">
                                            <EditIcon fontSize="small" />
                                        </button>
                                    </td> : null : null}
                                </tr>
                            )) : <tr><td colSpan="6" className="text-success text-center">Project proposal not available!</td></tr>}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            <DetailsModal
                setIsAssigned={setIsAssigned}
                modal={modal}
                toggle={toggle}
                data={proposal}
                roles={roles}
                department={department}
                deptHeadInfo={deptHeadInfo}
                setDetailsId={setDetailsId}
                loading={loading}
                setLoading={setLoading}
            />
        </Col>
    );
};

export default ProposalTable;