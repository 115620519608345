import { USER_ACTIONS } from "../actions/userAction";

const initialState = {
  createUser: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTIONS:
      return {
        ...state,
        createUser: !state.createUser,
      };

    default:
      return state;
  }
};
