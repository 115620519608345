import { LEAVE_ACTIONS } from "../actions/leaveAction";

const initialState = {
  updateLeave: false,
};

export const leaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEAVE_ACTIONS:
      return {
        ...state,
        updateLeave: !state.updateLeave,
      };

    default:
      return state;
  }
};
