import moment from 'moment';
import { useEffect } from 'react';

const ChatRight = ({ comment, openImageViewer, setImageArray, imageArray }) => {
    return (
        <li className="clearfix mb-4">
            <div className="message-data text-right">
                <span className="message-data-time">{comment?.projectProposalWithCommentInfo?.customerInfo?.fullname}, </span>
                <span className="message-data-time mr-3">{moment(new Date(Number(comment?.createdAt))).fromNow()}</span>
                <img src={comment?.projectProposalWithCommentInfo?.customerInfo?.avator ? comment?.projectProposalWithCommentInfo?.customerInfo?.avator : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"} />
            </div>
            <div className="message other-message float-right">
                <p>{comment?.comment}</p>
                <img src={comment?.attachment} alt="" />
                <img onClick={() => {
                        setImageArray([comment?.attachment])
                        openImageViewer(index)
                    }} style={{ height: '150px', width: '150px', borderRadius: '10px', marginTop: '10px', cursor: "pointer" }} src={comment?.attachment} alt="image" srcset="" />
                {/* {comment?.attachment ? imageArray?.map((item, index) =>
                    <img onClick={() => {
                        setImageArray([comment?.attachment])
                        openImageViewer(index)
                    }} style={{ height: '150px', width: '150px', borderRadius: '10px', marginTop: '10px', cursor: "pointer" }} src={comment?.attachment} alt="image" srcset="" />
                ) : null} */}
            </div>
        </li>
    );
};

export default ChatRight;