import { Table, Badge, Col } from "reactstrap";
import { Pagination } from "@mui/material";
import { dateFormat } from "../../../../../utils/helpers";
import ReadMore from "../../../../../utils/HelperComponent/ReadMore";

const LeaveDeductionList = ({ leaveDeduction, setLeaveDeduction }) => {
  // ** handle pagination **
  const paginationHandler = (e, p) => {
    leaveDeduction?.leaveDeductions?.refetch({
      input: {
        size: leaveDeduction?.size,
        page: p,
      },
    });
    setLeaveDeduction((prevState) => ({ ...prevState, page: p }));
  };
  return (
    <Col>
      <Table striped hover responsive>
        <thead>
          <tr>
            <th style={{ width: "60px" }}>#</th>
            <th>Employee Name</th>
            <th>Leave Name</th>
            <th>Deducted Days</th>
            <th>Deducted By</th>
            <th>Reason</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {leaveDeduction?.leaveDeductions?.data?.data?.length ? (
            leaveDeduction?.leaveDeductions?.data?.data?.map((item, index) => (
              <tr key={index + 1}>
                <td>{item?.id}</td>
                <td>{item?.employee?.name ?? "-"}</td>
                <td>{item?.leave_type?.name ?? "-"}</td>
                <td>
                  <Badge color="danger">-{item?.days ?? 0}</Badge>
                </td>
                <td>{item?.deductor?.name ?? "-"}</td>
                <td className="w-25">
                  <ReadMore
                    slice={4}
                    length={15}
                    children={item?.reason ?? "-"}
                  />
                </td>
                <td>{dateFormat(item?.createdAt)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="text-center" colSpan={7}>
                No leave deduction
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination
        count={leaveDeduction?.leaveDeductions?.data?.paginatorInfo?.lastPage}
        page={leaveDeduction?.page}
        onChange={paginationHandler}
      />
    </Col>
  );
};

export default LeaveDeductionList;
