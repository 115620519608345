import React from "react";
import DigitalLiteracy from "./DigitalLiteracy";
import FarmersProblem from "./FarmersProblem";
import FinancialInfo from "./FinancialInfo";
import PersonalInfo from "./PersonalInfo";

const ProfileActivities = ({ farmer }) => {
  return (
    <div>
      <PersonalInfo farmer={farmer} />
      {/* <AddressInfo farmer={farmer} /> */}
      <FinancialInfo
        farmer={farmer?.finance}
        gen_id={farmer.id}
        creator_id={farmer?.agent_id}
      />
      <FarmersProblem
        farmer={farmer?.farmerProblem}
        gen_id={farmer.id}
        creator_id={farmer?.agent_id}
      />
      <DigitalLiteracy
        farmer={farmer?.farmerDigital}
        gen_id={farmer.id}
        creator_id={farmer?.agent_id}
      />
      {/* <FamilyInfo farmer={farmer} /> */}
    </div>
  );
};

export default ProfileActivities;
