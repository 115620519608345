import React, { useCallback, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ChatCard from './components/ChatCard';
import { useHistory } from 'react-router';
import ImageViewer from "react-simple-image-viewer";
const index = () => {
    const history = useHistory();
    const currentChatFromList = history.location?.state?.chat;
    const currentChat = {
        ...currentChatFromList,
        conversationUsers: [currentChatFromList?.customer_id, Number(currentChatFromList?.projectProposalDeptInfo?.user?.id)]
    }

    // image viewer start
    const [imageArray, setImageArray] = useState([]);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    // image viewer close
    return (
        <>
        <Container>
            <Row>
                <ChatCard
                    currentChatFromList={currentChatFromList ? currentChat : undefined}
                    openImageViewer={openImageViewer}
                    setImageArray={setImageArray}
                    imageArray={imageArray}
                />
            </Row>
        </Container>
        
        {isViewerOpen && (
            <ImageViewer
                src={imageArray}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)"
                }}
                closeOnClickOutside={true}
            />
        )}
        </>
    );
};

export default index;