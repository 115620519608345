import React from "react";
import { Container, Row } from "reactstrap";
import { Attendance } from "../Attendance";
import { decryption } from "../HelperFunctions";
const Dashboard = () => {
  const roles = decryption(JSON.parse(localStorage?.getItem("roles")));
  return (

    <>
      {roles?.includes("System Admin") || roles?.includes("Human Resource (HR)") || roles?.includes("Employee") || roles?.includes("Head Of Department") || roles?.includes("System Manager") ? <Attendance /> : null}
    </>
  );
};

export default Dashboard;
