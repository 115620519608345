import { gql } from "@apollo/client";
export const LeaveTypesType = gql`
  fragment LeaveTypesType on LeaveTypesType {
    id
    name
    log_id
    status_code
    message
    createdAt
    updatedAt
  }
`;
