import { gql } from "@apollo/client";
export const PaginationType = gql`
  fragment PaginationType on PaginationType {
    count
    currentPage
    firstItem
    lastItem
    lastPage
    perPage
    total
    hasMorePages
  }
`;
