import PasswordField from "@/shared/components/form/Password";
import { useMutation } from "@apollo/client";
import { css } from "@emotion/react";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { connect, useDispatch } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "reactstrap";
import { LOG_IN_USER } from "../../../GraphQL/Mutation";
import renderCheckBoxField from "../form/CheckBox";
import { decryption, encryption } from "../../../containers/HelperFunctions";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { loginInfo } from "../../../utils/helpers";


const LogInForm = ({
  errorMessage,
  errorMsg,
  fieldUser,
  typeFieldUser,
  form,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [resultCode, setResultCode] = useState("");
  const loggedInfo = loginInfo();
  const [loginUser, { data, loading, error }] = useMutation(LOG_IN_USER);
  
  // redirect if already logged user
  let { from } = location.state || { from: { pathname: "/" } };
  loggedInfo?.id && history.replace(from)


  const onSubmit = async (values) => {
    console.log(values);
    const result = await loginUser({
      variables: {
        username: values.username,
        password: values.password,
      },
    });
  };
  if (resultCode) {
    alert(resultCode);
  }
  const token = data?.userLogin?.token;
  let permissions = [];
  let roles = [];
  data?.userLogin?.role?.map((role) => {
    roles.push(role?.role_name);
    role?.permissions?.map((permission) => {
      permissions.push(permission?.permission_name);
    });
  });

  useEffect(() => {
    if (token?.length) {
      localStorage.setItem("token", token);
      localStorage.setItem('permissions', encryption(permissions));
      localStorage.setItem('roles', encryption(roles));
      history.replace(from);
    }
  }, [token?.length]);

  let [color, setColor] = useState("rgb(54, 215, 183)");
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  useEffect(() => {
    if (data?.userLogin?.result_code == "002") {
      Swal.fire({
        icon: 'error',
        text: `Incorrect username or password.`,
      })
    }
  }, [data?.userLogin?.result_code]);
  return (
    <>
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">
            <span className="account__logo">
              Office Management
              {/* <span className="account_by"> by</span>
              <span className="account__logo-accent"> iPAGE</span> */}
            </span>
          </h3>
        </div>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form className="form login-form" onSubmit={handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">{fieldUser}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="username"
                    component="input"
                    type={typeFieldUser}
                    placeholder={fieldUser}
                    onBlur={(e) => e.target.value}
                    className="input-without-border-radius"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Password</span>
                <div className="form__form-group-field">
                  <Field
                    name="password"
                    component={PasswordField}
                    placeholder="Password"
                    className="input-without-border-radius"
                    onBlur={(e) => e.target.value}
                    keyIcon
                  />
                  <div className="account__forgot-password">
                    <NavLink to="/reset_password">Forgot a password?</NavLink>
                  </div>
                </div>
              </div>
              <div className="form__form-group">
                <div className="form__form-group form__form-group-field">
                  <Field
                    name="remember_me"
                    render={renderCheckBoxField}
                    label="Remember me"
                    type="checkbox"
                  />
                </div>
              </div>
              <div className="account__btns">
                {form === "modal_login" ? (
                  loading ? <Button className="btn btn-primary account__btn ml-1">
                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    Loging...
                  </Button> : <Button
                    className="account__btn"
                    type="submit"
                    color="primary"
                  >
                    Sign In
                  </Button>
                ) : (
                  // eslint-disable-next-line space-infix-ops
                  loading ? <Button className="btn btn-primary account__btn ml-1">
                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    Loging...
                  </Button> : <Button className="account__btn btn btn-primary">
                    Sign In
                  </Button>
                )}
                {/* <NavLink className="btn btn-outline-primary account__btn" to="/register">Create
            Account
          </NavLink> */}
              </div>
            </form>
          )}
        </Form>
      </div>
      <ToastContainer />
    </>
  );
};

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  errorMsg: PropTypes.string,
  fieldUser: PropTypes.string,
  typeFieldUser: PropTypes.string,
  form: PropTypes.string.isRequired,
};

LogInForm.defaultProps = {
  errorMessage: "",
  errorMsg: "",
  fieldUser: "Username",
  typeFieldUser: "text",
};

export default connect((state) => ({
  errorMsg: state.user.error,
}))(LogInForm);
