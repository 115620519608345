import React from 'react';
import { DiscussionEmbed } from 'disqus-react';
import ReactDisqusComments from 'react-disqus-comments';

import baseUrl from '../../../utils/api/baseUrl';
const DisqusComments = ({ identifier, title, category_id }) => {
    const handleNewComment = (comment) => {
        console.log(comment);
    }
    return (
        // <DiscussionEmbed
        //     shortname='conceptbyte'
        //     config={
        //         {
        //             url: baseUrl,
        //             identifier,
        //             title,
        //             language: 'en'
        //         }
        //     }
        // />
        <ReactDisqusComments
            shortname="conceptbyte"
            identifier="something-unique-12345"
            title="Example Thread"
            url="https://conceptbyte.disqus.com"
            category_id="1"
            onNewComment={handleNewComment}

        />
    );
};

export default DisqusComments;