import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
// import { SelectField } from "@/shared/components/form/Select";
import FormField from "./FormField";
import { useHistory } from "react-router";
import { useMutation } from '@apollo/client';
import { CREATE_DESIGNATION, UPDATE_DESIGNATION } from '../../GraphQL/Mutation';
import { successMessage, warningMessage } from '../../containers/ReactToastify';

const UserDesignationCreate = () => {
    const history = useHistory();
    const updateDesignationtData = history?.location?.state?.designation;
    const { handleSubmit, control } = useForm();
    const [createDesignation] = useMutation(CREATE_DESIGNATION);
    const [updateDesignation] = useMutation(UPDATE_DESIGNATION);
    const onSubmit = async (data) => {
        const desigData = {
            name: data?.name,
            description: data?.description
        }
        if (updateDesignationtData?.id) {
            const result = await updateDesignation({
                variables: {
                    ...desigData,
                    id: updateDesignationtData?.id
                }
            })
            if(result?.data?.updateDesignation?.result_code == "002"){
                warningMessage(result?.data?.updateDesignation?.status, 1500)
            }
            if(result?.data?.updateDesignation?.result_code == "000"){
                successMessage(result?.data?.updateDesignation?.status, 1500);
                history.push("/user/designation");
            }
        } else {
            const result = await createDesignation({
                variables: desigData
            })
            if(result?.data?.createDesignation?.result_code == "002"){
                warningMessage(result?.data?.createDesignation?.status, 1500)
            }
            if(result?.data?.createDesignation?.result_code == "000"){
                successMessage(result?.data?.createDesignation?.status, 1500);
                history.push("/user/designation");
            }
        }
    }
    return (
        <Col md={12}>
            <Col md={12}>
                <h3 className="page-title">{updateDesignationtData?.id ? "Update" : "New"} Designation</h3>
            </Col>
            <Card>
                <CardBody>
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>

                        <Col md={8} className="mx-auto">
                            <Row>
                                <Col md={12}>
                                    <div className="form__form-group">
                                        <label className="form__form-group-label">Name</label>
                                        <div className="form__form-group-field">
                                            <FormField
                                                name="name"
                                                component="input"
                                                control={control}
                                                rules={{ required: "This is required field" }}
                                                type="text"
                                                defaultValue={updateDesignationtData?.name}
                                                placeholder={`Enter designation name`}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="form__form-group">
                                        <label className="form__form-group-label">Desicription</label>
                                        <div className="form__form-group-field">
                                            <FormField
                                                name="description"
                                                component="textarea"
                                                control={control}
                                                rules={{ required: "This is required field" }}
                                                type="text"
                                                defaultValue={updateDesignationtData?.description}
                                                placeholder={`Enter designation description`}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                        <ButtonToolbar className="form__button-toolbar">
                                            <Button type="button" onClick={() => history.push("/user/designation")}> Cancel </Button>
                                            <Button color="primary" type="submit">{updateDesignationtData?.id ? "Update" : "Create"}</Button>
                                        </ButtonToolbar>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </form>
                </CardBody>
            </Card>
        </Col>
    );
}

export default UserDesignationCreate;