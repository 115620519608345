import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, Table, UncontrolledDropdown, Row } from "reactstrap";
import { GET_ALL_DEPARTMENT, GET_ALL_USER, GET_USER_BY_ID } from "../../GraphQL/Queries";
import PropagateLoader from "react-spinners/PropagateLoader";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import AssignRoleEdit from "./AssignRoleEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { USER_DELETED_STATUS_UPDATE } from "../../GraphQL/Mutation";
import { useHistory } from "react-router";
import { decryption } from "../HelperFunctions";
import jwt from "jsonwebtoken";
import { usePagination } from "../../utils/helpers";
import { Pagination } from "@mui/material";
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { css } from "@emotion/react";
import Swal from "sweetalert2";

const UserList = () => {
  const history = useHistory();
  const token = localStorage?.getItem("token");
  let userID;
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    userID = decoded?.id;
  });

  // user image and name get from database

  const LoadUserById = useQuery(GET_USER_BY_ID, {
    skip: !userID,
    variables: {
      id: userID,
    },
  });
  const [modal, setModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [allUser, setAllUser] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [userData, setUserData] = useState({});
  const toggle = () => {
    setModal((prevState) => !prevState);
  };
  const userToggle = () => {
    setUserModal((prevState) => !prevState);
  };
  const editRoles = (data) => {
    setUserData(data);
  };
  const UserList = useQuery(GET_ALL_USER);
  const DeptList = useQuery(GET_ALL_DEPARTMENT);
  const [deleteToggle, setDeleteToggle] = useState(false);
  useEffect(() => {
    if (roles?.includes("System Admin") || roles?.includes("System Manager")) {
      setAllUser(UserList?.data?.getUser?.map((item, index) => ({ ...item, indexId: index + 1 })));
    } else {
      let emp = [];
      DeptList?.data?.getAllDepartments?.filter(dept => dept.user_id == LoadUserById?.data?.getUserById?.id)?.map(dept => {
        UserList?.data?.getUser?.filter(item => item?.additionalInfo?.dept_id == dept.id)?.map(employee => emp.push(employee))
      })
      setAllUser(emp?.map((item, index) => ({ ...item, indexId: index + 1 })));
    }
    UserList?.refetch();
  }, [UserList?.data?.getUser, deleteToggle])

  // user delete function
  const [deleteUser] = useMutation(USER_DELETED_STATUS_UPDATE);
  const userDeleteHandler = async (userId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      }
    }).then(async (result) => {
      if (result.isConfirmed) {

        const result = await deleteUser({
          variables: {
            id: Number(userId)
          }
        })

        setDeleteToggle(prevState => !prevState);
        if (result?.data?.userDeletedStatusUpdate?.result_code == "000") {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        }
        if (result?.data?.userDeletedStatusUpdate?.result_code == "002") {
          Swal.fire(result?.data?.userDeletedStatusUpdate?.status, '', 'error');
        }

      }
    })

  }

  // permission
  const permissions = decryption(JSON.parse(localStorage?.getItem("permissions")));
  // dept head wise employee list
  let emp = [];
  DeptList?.data?.getAllDepartments?.filter(dept => dept.user_id == LoadUserById?.data?.getUserById?.id)?.map(dept => {
    allUser?.filter(item => item?.additionalInfo?.dept_id == dept.id)?.map(employee => emp.push(employee))
  })
  // roles
  const roles = decryption(JSON.parse(localStorage?.getItem("roles")));

  // search
  const [filterByAny, setFilterByAny] = useState(null);
  const [filteredUser, setFilterUser] = useState([]);

  const searchOnchangeHandler = (event) => {
    setFilterByAny(event.target.value?.toLowerCase());
  }

  useEffect(() => {
    const filterData = allUser?.filter(item => item?.name?.toLowerCase()?.match(new RegExp(filterByAny?.toLowerCase(), 'i')) || item?.phone?.toLowerCase().match(new RegExp(filterByAny?.toLowerCase(), 'i')) || item?.additionalInfo?.departmentInfo?.name?.toLowerCase().match(new RegExp(filterByAny?.toLowerCase(), 'i')) || item?.additionalInfo?.designationInfo?.name?.toLowerCase().match(new RegExp(filterByAny?.toLowerCase(), 'i')) || item?.email?.toLowerCase().match(new RegExp(filterByAny?.toLowerCase(), 'i')) || item?.additionalInfo?.office_id?.toLowerCase().match(new RegExp(filterByAny?.toLowerCase(), 'i')));

    setFilterUser(filterData);
  }, [filterByAny])


  // material pagination
  let pageData, count;
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  if (filterByAny) {
    count = Math.ceil(filteredUser?.length / PER_PAGE);
    pageData = usePagination(filteredUser, PER_PAGE);
  } else {
    count = Math.ceil(allUser?.length / PER_PAGE);
    pageData = usePagination(allUser, PER_PAGE);
  }
  const handleChange = (e, p) => {
    setPage(p);
    pageData.jump(p);
  }

  //add spinner fro loading
  // import PropagateLoader from "react-spinners/PropagateLoader";
  // import { css } from "@emotion/react";
  let [color, setColor] = useState("rgb(54, 215, 183)");
  const override = css`
        margin: 0 auto;
        display: block;
        width: 100px;
    `;

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row className="justify-content-between">
            <Col xs={5} md={6}>
              <h3 className="page-title">User Lists</h3>
            </Col>
            <Col xs={7} md={6}>
              {permissions?.includes("create_employee") && <button className="btn btn-success float-right" onClick={() => history.push('/user/create')}>
                {" "}
                + User Create
              </button>}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={3} xs={12}>
              <div className="inbox__emails-controls-right w-100" style={{ position: 'initial', display: 'initial' }}>
                <div className="inbox__emails-control-search mr-0">
                  <input className="w-100" onChange={() => searchOnchangeHandler(event)} placeholder="Search by Name, Email, Department, Designation..." />
                  <div className="inbox__emails-control-search-icon"><MagnifyIcon /></div>
                </div>
              </div>
            </Col>
          </Row>
          {
            roles?.includes("System Admin") || roles?.includes("System Manager")
              ? <Table role="table" striped hover responsive>
                <thead>
                  <tr role="row">
                    <th>#</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Designation</th>
                    <th>Department</th>
                    <th>Joined</th>
                    <th>Office Id</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="table-tr-last-child">
                  {
                    UserList.loading
                      ? <tr><td colSpan="10"><PropagateLoader color={color} css={override} loading={true} size={10} /></td></tr>
                      : filterByAny
                        ? filteredUser?.map((item, index) => (<tr key={item?.indexId}>
                          <td onClick={() => {
                            userToggle();
                            setUserDetails(item);
                          }}>{item?.indexId}</td>
                          <td role="cell" onClick={() => {
                            userToggle();
                            setUserDetails(item);
                          }}>
                            <div className="employee-list__img-wrap">
                              <img src={item?.images ? item?.images : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"} alt="image" />
                            </div>
                          </td>
                          <td onClick={() => {
                            userToggle();
                            setUserDetails(item);
                          }}>{item?.name} ({item?.additionalInfo?.office_id})</td>
                          <td onClick={() => {
                            userToggle();
                            setUserDetails(item);
                          }}>{item?.additionalInfo?.designationInfo?.name}</td>
                          <td onClick={() => {
                            userToggle();
                            setUserDetails(item);
                          }}>{item?.additionalInfo?.departmentInfo?.name}</td>
                          <td onClick={() => {
                            userToggle();
                            setUserDetails(item);
                          }}>{moment(item?.additionalInfo?.joining_date).fromNow('yy')}</td>
                          <td onClick={() => {
                            userToggle();
                            setUserDetails(item);
                          }}>{item?.additionalInfo?.office_id}</td>
                          <td onClick={() => {
                            userToggle();
                            setUserDetails(item);
                          }}>{item?.phone}</td>
                          <td onClick={() => {
                            userToggle();
                            setUserDetails(item);
                          }}>{item?.email}</td>
                          <td>

                            {/* dropdown action */}
                            <UncontrolledDropdown>
                              <DropdownToggle className="icon icon--center" outline size="sm">
                                <MoreVertIcon />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown__menu">
                                {userID == item?.id ? null : <DropdownItem>
                                  {permissions?.includes("assign_role") && <button
                                    className="inbox__email-btn w-100"
                                    onClick={() => {
                                      toggle();
                                      editRoles(item);
                                    }}
                                  >
                                    <SettingsIcon fontSize="small" /> Role Assign
                                  </button>}
                                </DropdownItem>}
                                {userID == item?.id ? null : <DropdownItem>
                                  {permissions?.includes("delete_employee") && <button
                                    className="inbox__email-btn w-100"
                                    type="button"
                                    aria-label="edit"
                                    onClick={() => {
                                      userDeleteHandler(item?.id);
                                      setDeleteUserId(item?.id);
                                    }}
                                  >
                                    <DeleteIcon fontSize="small" /> Delete
                                  </button>}
                                </DropdownItem>}
                                <DropdownItem>
                                  {permissions?.includes("update_employee") && <button
                                    className="inbox__email-btn w-100"
                                    type="button"
                                    aria-label="edit"
                                    onClick={() => history.push({
                                      pathname: "/user/create",
                                      state: {
                                        userInfo: item
                                      }
                                    })}
                                  >
                                    <EditIcon fontSize="small" /> Update
                                  </button>}
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>

                          </td>
                        </tr>)) // filterd user close
                        : pageData?.currentData()?.length // without filterd user start
                          ? pageData?.currentData()?.map((item, index) => (<tr key={item?.indexId}>
                            <td onClick={() => {
                              userToggle();
                              setUserDetails(item);
                            }}>{item?.indexId}</td>
                            <td role="cell" onClick={() => {
                              userToggle();
                              setUserDetails(item);
                            }}>
                              <div className="employee-list__img-wrap">
                                <img src={item?.images ? item?.images : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"} alt="image" />
                              </div>
                            </td>
                            <td onClick={() => {
                              userToggle();
                              setUserDetails(item);
                            }}>{item?.name} ({item?.additionalInfo?.office_id})</td>
                            <td onClick={() => {
                              userToggle();
                              setUserDetails(item);
                            }}>{item?.additionalInfo?.designationInfo?.name}</td>
                            <td onClick={() => {
                              userToggle();
                              setUserDetails(item);
                            }}>{item?.additionalInfo?.departmentInfo?.name}</td>
                            <td onClick={() => {
                              userToggle();
                              setUserDetails(item);
                            }}>{moment(item?.additionalInfo?.joining_date).fromNow('yy')}</td>
                            <td onClick={() => {
                              userToggle();
                              setUserDetails(item);
                            }}>{item?.additionalInfo?.office_id}</td>
                            <td onClick={() => {
                              userToggle();
                              setUserDetails(item);
                            }}>{item?.phone}</td>
                            <td onClick={() => {
                              userToggle();
                              setUserDetails(item);
                            }}>{item?.email}</td>
                            <td>

                              {/* dropdown action */}
                              <UncontrolledDropdown>
                                <DropdownToggle className="icon icon--center" outline size="sm">
                                  <MoreVertIcon />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown__menu">
                                  {userID == item?.id ? null : <DropdownItem>
                                    {permissions?.includes("assign_role") && <button
                                      className="inbox__email-btn w-100"
                                      onClick={() => {
                                        toggle();
                                        editRoles(item);
                                      }}
                                    >
                                      <SettingsIcon fontSize="small" /> Role Assign
                                    </button>}
                                  </DropdownItem>}
                                  {userID == item?.id ? null : <DropdownItem>
                                    {permissions?.includes("delete_employee") && <button
                                      className="inbox__email-btn w-100"
                                      type="button"
                                      aria-label="edit"
                                      onClick={() => {
                                        userDeleteHandler(item?.id);
                                        setDeleteUserId(item?.id);
                                      }}
                                    >
                                      <DeleteIcon fontSize="small" /> Delete
                                    </button>}
                                  </DropdownItem>}
                                  <DropdownItem>
                                    {permissions?.includes("update_employee") && <button
                                      className="inbox__email-btn w-100"
                                      type="button"
                                      aria-label="edit"
                                      onClick={() => history.push({
                                        pathname: "/user/create",
                                        state: {
                                          userInfo: item
                                        }
                                      })}
                                    >
                                      <EditIcon fontSize="small" /> Update
                                    </button>}
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>

                            </td>
                          </tr>)) : "There is no user"}
                </tbody>
              </Table>
              : <Table role="table" striped hover responsive>
                <thead>
                  <tr role="row">
                    <th>#</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Designation</th>
                    <th>Department</th>
                    <th>Joined</th>
                    <th>Office Id</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {UserList.loading ? <tr><td colSpan="10"><PropagateLoader color={color} css={override} loading={true} size={10} /></td></tr> : pageData?.currentData()?.length ? pageData?.currentData()?.map((item, index) => (<tr key={item?.id}>
                    <th onClick={() => {
                      userToggle();
                      setUserDetails(item);
                    }}>{index + 1}</th>
                    <td onClick={() => {
                      userToggle();
                      setUserDetails(item);
                    }} role="cell">
                      <div className="employee-list__img-wrap">
                        <img src={item?.images ? item?.images : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"} alt="image" />
                      </div>
                    </td>
                    <td onClick={() => {
                      userToggle();
                      setUserDetails(item);
                    }}>{item?.name} ({item?.username})</td>
                    <td onClick={() => {
                      userToggle();
                      setUserDetails(item);
                    }}>{item?.additionalInfo?.designationInfo?.name}</td>
                    <td onClick={() => {
                      userToggle();
                      setUserDetails(item);
                    }}>{item?.additionalInfo?.departmentInfo?.name}</td>
                    <td onClick={() => {
                      userToggle();
                      setUserDetails(item);
                    }}>{moment(item?.additionalInfo?.joining_date).fromNow('yy')}</td>
                    <td onClick={() => {
                      userToggle();
                      setUserDetails(item);
                    }}>{item?.additionalInfo?.office_id}</td>
                    <td onClick={() => {
                      userToggle();
                      setUserDetails(item);
                    }}>{item?.phone}</td>
                    <td onClick={() => {
                      userToggle();
                      setUserDetails(item);
                    }}>{item?.email}</td>
                    <td>
                      {/* dropdown action */}
                      <UncontrolledDropdown>
                        <DropdownToggle className="icon icon--center" outline size="sm">
                          <MoreVertIcon />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown__menu">
                          {userID == item?.id ? null : <DropdownItem>
                            {permissions?.includes("assign_role") && <button
                              className="inbox__email-btn w-100"
                              onClick={() => {
                                toggle();
                                editRoles(item);
                              }}
                            >
                              <SettingsIcon fontSize="small" /> Role Assign
                            </button>}
                          </DropdownItem>}
                          {userID == item?.id ? null : <DropdownItem>
                            {permissions?.includes("delete_employee") && <button
                              className="inbox__email-btn w-100"
                              type="button"
                              aria-label="edit"
                              onClick={() => {
                                userDeleteHandler(item?.id);
                                setDeleteUserId(item?.id);
                              }}
                            >
                              <DeleteIcon fontSize="small" /> Delete
                            </button>}
                          </DropdownItem>}
                          <DropdownItem>
                            {permissions?.includes("update_employee") && <button
                              className="inbox__email-btn w-100"
                              type="button"
                              aria-label="edit"
                              onClick={() => history.push({
                                pathname: "/user/create",
                                state: {
                                  userInfo: item
                                }
                              })}
                            >
                              <EditIcon fontSize="small" /> Update
                            </button>}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>)) : "There is no user"}
                </tbody>
              </Table>
          }

          {
            <Pagination count={count} page={page} onChange={handleChange} />
          }



          <Modal isOpen={modal} toggle={toggle} animation={true} centered>
            <AssignRoleEdit
              user={userData}
              toggle={toggle}
              refetch={UserList?.refetch}
            />
          </Modal>

          {/* modal for employee or user details view backdrop="static"*/}
          <Modal toggle={userToggle} isOpen={userModal}>
            <div className="employee-profile">
              <div className="row">
                <div className="col-md-4">
                  <div className="profile-img">
                    <img className="img-fluid" src={userDetails?.images ? userDetails?.images : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"} alt="" />
                  </div>
                  <div className="profile-head mt-3">
                    <h4>{userDetails?.name}</h4>
                    <h5>{userDetails?.additionalInfo?.designationInfo?.name} <br /> {userDetails?.additionalInfo?.departmentInfo?.name}</h5>
                  </div>
                  <div className="profile-work">
                    <p>ROLES</p>
                    {
                      userDetails?.role?.map((role, index) => <span key={index} className="proile-rating">{index + 1}. {role?.role_name} <br /></span>)
                    }

                    <p>LEAVE LEFT</p>
                    {
                      userDetails?.gender == "Male"
                        ? <div>
                          <span className="proile-rating">Casual : {userDetails?.additionalInfo?.casual} </span> <br />
                          <span className="proile-rating">Sick : {userDetails?.additionalInfo?.sick} </span> <br />
                          <span className="proile-rating">Anual : {userDetails?.additionalInfo?.anual}</span> <br />
                          <span className="proile-rating">Reward : {userDetails?.additionalInfo?.reward}</span> <br />
                        </div>
                        : userDetails?.gender == "Female" ? <div>
                          <span className="proile-rating">Casual : {userDetails?.additionalInfo?.casual}</span> <br />
                          <span className="proile-rating">Sick : {userDetails?.additionalInfo?.sick} </span> <br />
                          <span className="proile-rating">Anual : {userDetails?.additionalInfo?.anual} </span> <br />
                          <span className="proile-rating">Maternity : {userDetails?.additionalInfo?.maternity} </span> <br />
                          <span className="proile-rating">Menstrual : {userDetails?.additionalInfo?.menstrual}</span> <br />
                        </div>
                          : null
                    }

                  </div>
                </div>
                <div className="col-md-8">
                  <h4 className="border-bottom mb-3 mt-4 pb-2">About</h4>
                  <Table borderless hover responsive size="sm" striped>
                    <tbody>
                      <tr>
                        <td>Login Id</td>
                        <td>{userDetails?.username}</td>
                      </tr>
                      <tr>
                        <td>RFID</td>
                        <td>{userDetails?.additionalInfo?.rfid}</td>
                      </tr>
                      <tr>
                        <td>Employee Id</td>
                        <td>{userDetails?.additionalInfo?.office_id}</td>
                      </tr>
                      <tr>
                        <td>Joining Date</td>
                        <td>{moment(userDetails?.additionalInfo?.joining_date).format('DD-MM-YYYY')} ({moment(userDetails?.additionalInfo?.joining_date).fromNow('yy')})</td>
                      </tr>
                      <tr>
                        <td>Gender</td>
                        <td>{userDetails?.gender}</td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td>{userDetails?.name}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{userDetails?.email}</td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td>{userDetails?.phone}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <h4 className="border-bottom mb-3 mt-4 pb-2">Address</h4>
                  <Table borderless hover responsive size="sm" striped>
                    <tbody>
                      <tr>
                        <td>Division</td>
                        <td>{userDetails?.divisionInfo?.name}</td>
                      </tr>
                      <tr>
                        <td>District</td>
                        <td>{userDetails?.districtInfo?.name}</td>
                      </tr>
                      <tr>
                        <td>Upazilla</td>
                        <td>{userDetails?.upazillaInfo?.name}</td>
                      </tr>
                      <tr>
                        <td>Union</td>
                        <td>{userDetails?.unionInfo?.name}</td>
                      </tr>
                      <tr>
                        <td>Village</td>
                        <td>{userDetails?.village}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Modal>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UserList;
