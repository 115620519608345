import { DEPT_ACTION } from "../actions/deptAction";

const initialState = {
  updateDept: false,
};

export const deptReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEPT_ACTION:
      return {
        ...state,
        updateDept: !state.updateDept,
      };

    default:
      return state;
  }
};
