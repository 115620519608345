import React, { useRef, useState } from 'react';
import { Button, Input } from 'reactstrap';
import PaperclipIcon from 'mdi-react/PaperclipIcon';
import EmoticonIcon from 'mdi-react/EmoticonIcon';
import { useForm } from "react-hook-form";
import SendIcon from 'mdi-react/SendIcon';
import { loginInfo } from '../../../../../utils/helpers';
import { useMutation } from '@apollo/client';
import { CREATE_PROJECT_PROPOSAL_COMMENT } from '../../../../../GraphQL/Mutation';
import axios from 'axios';
import BASE_URL from '../../../../../utils/api/baseUrl'




const ChatField = ({ currentChat, setCommentSubmit, socket, file, setFile }) => {
  const loggedInfo = loginInfo();
  const { register, handleSubmit, reset } = useForm();
  const [data, setData] = useState("");
  const [createProjectProposalComment] = useMutation(CREATE_PROJECT_PROPOSAL_COMMENT);
  // clear input file after upload
  const ref = useRef();

  const resetFile = () => {
      ref.current.value = "";
  };
  // file upload handling
  const fileUploadHandler = (event) => {
    setFile([{ file: event.target.files[0], preview: URL.createObjectURL(event.target.files[0]) }]);
    resetFile();
  }
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("files", file[0]?.file);
    const fileUrl = file[0]?.file && await axios.post(
      `${BASE_URL}/api/files/upload`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    const result = await createProjectProposalComment({
      variables: {
        customer_id: Number(loggedInfo?.id),
        project_proposal_id: Number(currentChat?.id),
        attachment: fileUrl ? fileUrl?.data?.data : null,
        comment: data?.comment
      },
    });
    setFile([]);

    socket.current.emit("sendMessage", {
      senderId: loggedInfo?.id,
      receiverId: currentChat?.conversationUsers?.filter(item => item !== loggedInfo?.id)[0],
      text: data?.comment,
      attachment: fileUrl ? fileUrl?.data?.data : null,
    });
    setCommentSubmit((result?.data?.createProjectProposalComment?.id));
    reset();
  }
  return (
    <>
      <div className="chat__text-field">
        <form className="chat__form" onSubmit={handleSubmit(onSubmit)}>
          <input className="chat__field-input" {...register("comment")} placeholder="Type here…" />
          <div className="chat__field-controls">
            <div className="position-relative" style={{ cursor: "pointer" }}>
              <input onClick={()=>resetFile()} ref={ref} onChange={(e) => fileUploadHandler(e)} style={{ opacity: 0, top: "50%", width: "30px", position: "absolute", left: "50%", transform: "translate(-50%, -50%)", cursor: "pointer", }} type="file" />
              <button
                style={{ cursor: "pointer" }}
                type="button"
                className="chat__icon-button"
                onClick={(e) => { e.preventDefault(); }}
              >
                <PaperclipIcon style={{ cursor: "pointer" }} />

              </button>
            </div>
            <button
              className="chat__icon-button"
              type="submit"
            >
              <SendIcon />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChatField;


{/* <div className="upload-img-container d-flex flex-wrap">
        {file?.map(item => {
          const extension = item?.preview?.split('.')[item?.url?.split('.')?.length - 1];
          const thumb = ["pdf", "PDF", "doc", "docx", "ppt"];
          return (
            thumb?.includes(extension)
              ? <div className="position-relative mt-4 mr-3">
                <img className="upload-img" src="https://cutewallpaper.org/24/file-png/collections.png" alt="image" ></img>
                <span onClick={() => uploadRemoveHandler(item?.url)} className="upload-delete-btn position-absolute">❌</span>
              </div>
              : <div className="position-relative mt-4 mr-3">
                <img className="upload-img" src={item?.preview} alt="image" ></img>
                <span onClick={() => uploadRemoveHandler(item?.preview)} className="upload-delete-btn position-absolute">❌</span>
              </div>
          )
        })}
      </div> */}