import React, { useState } from 'react';
import { Badge, Popover, PopoverHeader, PopoverBody } from 'reactstrap';

const PopoverOnClick = ({ id, value, buttonValue, color }) => {
    const [popoverToggle, setPopoverToggle] = useState(false);
    return (
        <span>
            <Badge color={color} type="button" id={id} onClick={() => setPopoverToggle(prevState => !prevState)} style={{ padding: '2px 4px' }} size="sm">{buttonValue}</Badge>
            <Popover placement="top" isOpen={popoverToggle} target={id} toggle={popoverToggle}>
                <PopoverHeader>{value}</PopoverHeader>
            </Popover>
        </span>
    );
};

export default PopoverOnClick;