import { Col, Table } from "reactstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { GET_ALL_DESIGNATION } from "../../GraphQL/Queries";
import { DELETE_DESIGNATION_BY_ID, DESIGNATION_DELETED_STATUS_UPDATE } from "../../GraphQL/Mutation";
import { successMessage, warningMessage } from '../ReactToastify'
import { Pagination } from "@mui/material";
import { usePagination } from "../../utils/helpers";
import { decryption } from "../HelperFunctions";

const UserDesignationTable = () => {
    const [allDesignation, setAllDesignation] = useState([]);
    const [deleteDesignationId, setDeleteDesignationId] = useState(null);
    const LoadAllDesignation = useQuery(GET_ALL_DESIGNATION);
    const [deleteDesignationById] = useMutation(DESIGNATION_DELETED_STATUS_UPDATE);
    const [deleteToggle, setDeleteToggle] = useState(false);
    const history = useHistory();
    useEffect(() => {
        setAllDesignation(LoadAllDesignation?.data?.getAllDesignation);
        LoadAllDesignation?.refetch();
    }, [LoadAllDesignation?.data?.getAllDesignation, deleteToggle])
    const deleteHandler = async (id) => {
        const result = await deleteDesignationById({
            variables: {
                id: id
            }
        })
        if (result?.data?.userDesignationDeletedStatusUpdate?.result_code == "002") {
            warningMessage(result?.data?.userDesignationDeletedStatusUpdate?.status, 1500)
        }
        if (result?.data?.userDesignationDeletedStatusUpdate?.result_code == "000") {
            successMessage(result?.data?.userDesignationDeletedStatusUpdate?.status, 1500);
            setDeleteToggle(prevState => !prevState);
            history.push("/user/designation");
        }
    }
    // material pagination
    let [page, setPage] = useState(1);
    const PER_PAGE = 10;
    const count = Math.ceil(allDesignation?.length / PER_PAGE);
    const pageData = usePagination(allDesignation, PER_PAGE);
    const handleChange = (e, p) => {
        setPage(p);
        pageData.jump(p);
    };
    // permission
  const permissions = decryption(JSON.parse(localStorage?.getItem("permissions")));
    return (
        <Col md={12}>
            <Table role="table" striped hover responsive>
                <thead>
                    <tr role="row">
                        <th>#</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {pageData?.currentData()?.map(item => (<tr key={item?.id}>
                        <td>{item?.id}</td>
                        <td>{item?.name}</td>
                        <td>{item?.description?.split(' ').slice(0, 15).join(' ')}...</td>
                        <td>
                            {permissions?.includes("delete_designation") && <button
                                className="inbox__email-btn"
                                type="button"
                                aria-label="edit"
                                onClick={() => {
                                    deleteHandler(item?.id);
                                    setDeleteDesignationId(item?.id)
                                }}
                            >
                                <DeleteIcon fontSize="small" />
                            </button>}
                            {permissions?.includes("update_designation") && <button
                                className="inbox__email-btn mr-1"
                                type="button"
                                aria-label="edit"
                                onClick={() => history.push({
                                    pathname: "/user/new-designation",
                                    state: {
                                        designation: item
                                    }
                                })}
                            >
                                <EditIcon fontSize="small" />
                            </button>}
                        </td>
                    </tr>))}
                </tbody>
            </Table>

            <Pagination
                count={count}
                page={page}
                onChange={handleChange}
            />
        </Col>
    );
}

export default UserDesignationTable;