import React from "react";

const UserProfileInfo = ({ data = [] }) => {
  return (
    <div className="mb-3">
      <div
      className="mb-1"
        style={{
          textTransform: "uppercase",
          transition: "0.3s",
        }}
      >
        <h5 style={{ color: "#646777", fontWeight: 800, fontSize: "13px" }}>
          {data?.title ?? "title"}
        </h5>
      </div>
      {data?.data?.length ? (
        data?.data?.map((item, index) => (
          <div key={index + 1}>
            <p className="row">
              <div
                style={{
                  color: "#999999",
                  fontWeight: 500,
                }}
                className="col-auto p-0 pb-1"
              >
                {item?.label ?? "Label"}:
              </div>
              <div className="col p-0 pl-2 pb-1">
                {item?.value ?? "-"}
              </div>
            </p>
          </div>
        ))
      ) : (
        <div>
          <p>No about {data?.title ?? "title"}</p>
        </div>
      )}
    </div>
  );
};

export default UserProfileInfo;
