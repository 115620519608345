import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Card, Col, CardBody } from 'reactstrap';
import { GET_ALL_PROJECT_PROPOSAL_BY_INCLUDE_DEPT_HEAD, GET_DEPARTMENTS_BY_USER_ID, GET_PROJECT_PROPOSAL_BY_CUSTOMER_ID_STATUS } from '../../../../../GraphQL/Queries';
import { arrayCustomIndexing, loginInfo } from '../../../../../utils/helpers';
import { decryption } from '../../../../HelperFunctions';
import Chat from './Chat';
const ChatCard = ({ currentChatFromList, openImageViewer, setImageArray, imageArray }) => {
    // roles
    const roles = decryption(JSON.parse(localStorage?.getItem("roles")));
    const loggedInfo = loginInfo();
    const [proposal, setProposal] = useState([]);

    if (loggedInfo?.role == 'Customer') {
        const proposalCalling = (customer_id, projectStatus) => {
            return useQuery(GET_PROJECT_PROPOSAL_BY_CUSTOMER_ID_STATUS, {
                skip: !customer_id,
                variables: {
                    customer_id,
                    projectStatus
                }
            });
        }
        const proposalResults = proposalCalling(loggedInfo?.id, "In Progress");
        useEffect(() => {
            if (proposalResults?.data?.projectProposalByCustomerAndStatus?.length > 0) {
                setProposal(proposalResults?.data?.projectProposalByCustomerAndStatus)
            }
            proposalResults?.refetch();
        }, [proposalResults?.data?.projectProposalByCustomerAndStatus?.length]);
    } else if (roles?.includes("Head Of Department")) {
        let result = useQuery(GET_ALL_PROJECT_PROPOSAL_BY_INCLUDE_DEPT_HEAD, {
            skip: !loggedInfo?.dept_id,
            variables: {
                user_id: loggedInfo?.id,
            }
        });
        // setLoading(loadProjectProposal.loading);
        useEffect(() => {
            if (result?.data?.getAllProjectProposalByIndividualHeadpDept) {
                setProposal(arrayCustomIndexing(result?.data?.getAllProjectProposalByIndividualHeadpDept?.filter(item => item?.projectStatus == "In Progress")));
                result = result.refetch({
                    dept_id: loggedInfo?.dept_id,
                });
            }
        }, [result?.data?.getAllProjectProposalByIndividualHeadpDept?.length, result?.data?.getAllProjectProposalByIndividualHeadpDept])

    }


    return (
        <Col md={12} lg={12}>
            <Card>
                <CardBody>
                    {proposal?.length > 0 ? <Chat
                        contacts={proposal?.map(item => ({
                            ...item,
                            conversationUsers: [item?.customer_id, Number(item?.projectProposalDeptInfo?.user?.id)]
                        }))}
                        currentUser={loggedInfo}
                        loggedInfo={loggedInfo}
                        currentChatFromList={currentChatFromList}
                        openImageViewer={openImageViewer}
                        setImageArray={setImageArray}
                        imageArray={imageArray}
                    /> : "There is no project proposal"}
                </CardBody>
            </Card>
        </Col>
    );
};

export default ChatCard;