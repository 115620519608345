import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import { Button, Alert } from 'reactstrap';
import PasswordField from '@/shared/components/form/Password';
import { useMutation } from '@apollo/client';
import { CREATE_CUSTOMER } from '../../../GraphQL/Mutation';
import { successMessage, warningMessage } from '../../ReactToastify';

const CustomerRegisterForm = () => {
  const [createCustomer] = useMutation(CREATE_CUSTOMER);
  const onSubmit = async (values) => {
    const formData = {
      fullname: values.fullname,
      email: values.email,
      address: values.address,
      phone: values.phone,
      password: values.password,
    }
    const result = await createCustomer({
      variables: formData,
    });
    if(result?.data?.createCustomer?.result_code == "000"){
      successMessage(result?.data?.createCustomer?.status, 3000);
    }else{
      warningMessage(result?.data?.createCustomer?.status, 3000);
    }
  }
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">Full Name</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <Field
                name="fullname"
                component="input"
                type="text"
                placeholder="Full Name"
                className="input-without-border-radius"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">E-mail</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AlternateEmailIcon />
              </div>
              <Field
                name="email"
                component="input"
                type="email"
                placeholder="example@mail.com"
                required
                className="input-without-border-radius"
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Phone</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <Field
                name="phone"
                component="input"
                type="text"
                placeholder="Phone"
                className="input-without-border-radius"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Address</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon" style={{height: "auto"}}>
                <AccountOutlineIcon />
              </div>
              <Field
                name="address"
                component="textarea"
                type="text"
                placeholder="Address"
                className="input-without-border-radius"
              />
            </div>
          </div>
          <div className="form__form-group form__form-group--forgot">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <Field
                name="password"
                component={PasswordField}
                placeholder="Password"
                className="input-without-border-radius"
                required
                keyIcon
              />
            </div>
          </div>
          <div className="account__btns register__btns">
            <Button type="submit" color="primary" className="account__btn">
              Sign Up
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

CustomerRegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

CustomerRegisterForm.defaultProps = {
  errorMessage: '',
};

export default CustomerRegisterForm;
