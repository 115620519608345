
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { useState } from "react";
import { Table, Col } from 'reactstrap';
import { useHistory } from "react-router";
import { Pagination } from "@mui/material";
import { IsThereWeekend, usePagination } from "../../../utils/helpers";
import { decryption } from "../../HelperFunctions";
import TableDataLoading from "../../../utils/HelperComponent/TableDataLoading";

const HolydayTable = ({ loading, holydays, deleteHandler }) => {
    const history = useHistory();

    // material pagination
    let [page, setPage] = useState(1);
    const PER_PAGE = 10;
    const count = Math.ceil(holydays?.length / PER_PAGE);
    const pageData = usePagination(holydays, PER_PAGE);
    const handleChange = (e, p) => {
        setPage(p);
        pageData.jump(p);
    };
    // permission
    const permissions = decryption(JSON.parse(localStorage?.getItem("permissions")));


    return (
        <Col>
            <Table striped hover responsive>
                <thead>
                    <tr>
                        <th style={{ width: "60px" }}>#</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {loading
                        ? <TableDataLoading colSpan="4" />
                        : pageData?.currentData()?.length
                            ? pageData?.currentData()?.map((item, index) => (<tr>
                                <th style={{ width: "60px" }} scope="row">{index + 1}</th>
                                <td>
                                    {
                                        item?.start_date == item?.end_date
                                            ? moment(item?.start_date).format('LL') + " ( 1 Day )"
                                            : moment(item?.start_date).format('LL')
                                            + " to "
                                            + moment(item?.end_date).format('LL')
                                            + " "
                                            + " ( "
                                            + IsThereWeekend(new Date(item?.start_date), new Date(item?.end_date))?.length 
                                            + (IsThereWeekend(new Date(item?.start_date), new Date(item?.end_date))?.length > 1 ? " Days )" : " Day )")
                                    }
                                </td>
                                <td>{item?.name}</td>
                                <td>
                                    {permissions?.includes("delete_holyday") ? <button
                                        className="inbox__email-btn"
                                        type="button"
                                        aria-label="edit"
                                        onClick={() => {
                                            deleteHandler(item?.id);
                                        }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </button> : null}
                                    {permissions?.includes("update_holyday") ? <button
                                        className="inbox__email-btn mr-1"
                                        type="button"
                                        aria-label="edit"
                                        onClick={() => history.push({
                                            pathname: "/leave/new-holyday",
                                            state: {
                                                holyday: item,
                                            }
                                        })}
                                    >
                                        <EditIcon fontSize="small" />
                                    </button> : null}
                                </td>
                            </tr>))
                            : <tr><td colSpan='4' className="text-center text-success"><b>There is no holyday</b></td></tr>
                    }
                </tbody>

            </Table>

            <Pagination
                count={count}
                page={page}
                onChange={handleChange}
            />

        </Col>
    );
}

export default HolydayTable;