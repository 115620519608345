import PropTypes from "prop-types";
import React from "react";

const Error = ({ error }) => (
  <span className="form__form-group-error" style={{ fontSize: "12px" }}>
    {error}
  </span>
);

Error.propTypes = {
  error: PropTypes.string.isRequired,
};

export default Error;
