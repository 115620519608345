import { Card, CardBody, Col, Table, Button, Container, Row } from "reactstrap";
import TabPlusIcon from "mdi-react/TabPlusIcon";
import { useHistory } from "react-router";
import DeptMemberTable from "./DeptMemberTable";


const DeptMemebr = () => {
    const history = useHistory();
    return (
        <Col md={12}>
            <Card>
                <DeptMemberTable />
            </Card>
        </Col>
    );
}

export default DeptMemebr;