import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { CREATE_ROLE } from "../../GraphQL/Mutation";

const AddRole = ({ toggle, refetch }) => {
  const [roleValue, setRoleValue] = useState("");
  const [createRole, roleData] = useMutation(CREATE_ROLE);
  const handleRole = async () => {
    await createRole({
      variables: {
        role_name: roleValue,
      },
    });
    setRoleValue("");
    await refetch();
    toggle();
  };
  return (
    <>
      <ModalHeader>
        <h1>New Role</h1>
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup as={Row} controlId="formPlaintextEmail">
            <Label column sm="4">
              Enter Role
            </Label>
            <Col sm="6">
              <input
                className="form-control"
                value={roleValue}
                onChange={(e) => {
                  setRoleValue(e.target.value);
                }}
                placeholder="Enter Role Name"
              />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-danger" onClick={toggle}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={handleRole}>
          Save
        </button>
      </ModalFooter>
    </>
  );
};

export default AddRole;
