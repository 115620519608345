import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Dotdotdot from "react-dotdotdot";
import classNames from "classnames";
import CheckIcon from "mdi-react/CheckIcon";
import PaperclipIcon from "mdi-react/PaperclipIcon";
import StarIcon from "mdi-react/StarIcon";
import { EmailProps } from "@/shared/prop-types/EmailProps";
import moment from "moment";
import {
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  ButtonToolbar,
  Alert,
} from "reactstrap";
import { successMessage, warningMessage } from "../../../../ReactToastify";
import {
  CREATE_ATTENDANCE_BY_LEAVE,
  DELETE_ATTENDANCE_BY_CANCELED_LEAVE,
  LEAVE_APPROVE,
  UPDATE_LEAVE,
  UPDATE_REWARD,
  UPDATE_USER_LEAVE_ADDITIONAL_INFO,
} from "../../../../../GraphQL/Mutation";
import { useHistory } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import Swal from "sweetalert2";
import {
  GET_ALL_ADDITIONAL_INFO_BY_RFID,
  GET_ALL_DEPARTMENT,
  GET_ALL_PENALTY_BY_USER_ID_AND_DATE,
  GET_REST_LEAVE_BY_USER_ID,
} from "../../../../../GraphQL/Queries";
import { decryption, leaveTypeListForEmail } from "../../../../HelperFunctions";
import ImageViewer from "react-simple-image-viewer";
import {
  approvalLeaveEmail,
  countWeekends,
  dateRangeArray,
  loginInfo,
  penaltyChecking,
} from "../../../../../utils/helpers";
import axios from "axios";
import { numberToTextNumber } from "../../../../../utils/utilities";

const EmailListItem = ({
  email,
  onLetter,
  itemId,
  isChecked,
  setAnyChangeLeave,
  LoadAllLeaves,
  allLeaves,
}) => {
  const history = useHistory();
  const [isDeny, setIsDeny] = useState(false);
  const [updateLeave] = useMutation(UPDATE_LEAVE);
  const [reliverId, setReliverId] = useState(null);
  const [reliverInfo, setReliverInfo] = useState({});
  const loggedInfo = loginInfo();
  const [updateRestLeave] = useMutation(UPDATE_USER_LEAVE_ADDITIONAL_INFO);
  // ** redwar mutation
  const [updateReward] = useMutation(UPDATE_REWARD);
  const [createAttendanceByLeave] = useMutation(CREATE_ATTENDANCE_BY_LEAVE);
  const [deleteAttendanceByCanceledLeave] = useMutation(
    DELETE_ATTENDANCE_BY_CANCELED_LEAVE
  );
  const LoadAdditionalInfoByRfid = useQuery(GET_ALL_ADDITIONAL_INFO_BY_RFID, {
    skip: !reliverId,
    variables: {
      rfid: reliverId,
    },
  });
  // queries
  // load penalty for penaltyChecking function
  const loadPenalty = useQuery(GET_ALL_PENALTY_BY_USER_ID_AND_DATE, {
    skip: true,
  });

  // mutations
  const [leaveApprove, leaveApproveStatus] = useMutation(LEAVE_APPROVE);

  useEffect(() => {
    // setReliverInfo(LoadAdditionalInfoByRfid?.data)
    setReliverInfo(
      LoadAdditionalInfoByRfid?.data?.getAllAdditionalInfoByRfid[0]
    );
  }, [reliverId]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [modal, setModal] = useState(false);
  const [isCheckedMail, setCheckedMail] = useState(isChecked);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  // image viewer
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  let url = [];

  JSON.parse(email?.images)?.map((item) => {
    url.push(item.image);
  });

  const leaveName = leaveTypeListForEmail
    ?.find((item) => item?.value == email?.leave_type)
    ?.label?.toLowerCase();
  // get additional information for check rest of leave when user leave approved
  const [checkRestLeaveUserId, setCheckRestLeaveUserId] = useState(
    email?.user_id
  );
  const LoadAllAdditionalInfo = useQuery(GET_REST_LEAVE_BY_USER_ID, {
    variables: {
      user_id: checkRestLeaveUserId,
    },
  });
  useEffect(() => {
    LoadAllAdditionalInfo.refetch();
  }, []);

  const itemClass = classNames({
    "inbox__email-list-item": true,
    "inbox__email-list-item--unread": email.unread,
  });

  const onFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  const onChangeSelect = () => {
    setCheckedMail(!isCheckedMail);
  };

  const handleClick = (data) => {
    // console.log(data);
  };
  const modalHandler = () => {
    setModal((prevSate) => !prevSate);
  };

  const onQuickReply = () => {
    setIsQuickReplied(true);
  };
  // leave type for leave details show
  const leaveTypeList = [
    {
      value: 1,
      label: `Casual`,
    },
    {
      value: 2,
      label: `Anual`,
    },
    {
      value: 3,
      label: `Sick`,
    },
    {
      value: 4,
      label: `Menstrual`,
    },
    {
      value: 5,
      label: `Maternity`,
    },
    {
      value: 6,
      label: `Reward`,
    },
  ];

  // deparment head of the department email
  const LoadAllDepartment = useQuery(GET_ALL_DEPARTMENT);

  // attendance type array
  const attendanceTypeArray = [
    {
      value: 101,
      label: `Work From Home`,
    },
    {
      value: 102,
      label: `On Field Duty`,
    },
    {
      value: 201,
      label: `On Leave`,
    },
  ];

  const approvedHandler = async (email, type) => {
    // deny condition here
    if (type == "deny") {
      const { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: "Reason of deny",
        inputPlaceholder: "Type reason of deny...",
        confirmButtonText: "Deny",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        showCancelButton: true,
        allowOutsideClick: false,
      });

      if (text) {
        const deniedBody = `
            <!DOCTYPE html>
            <html>
              <head>
                <meta name="viewport" content="width=device-width, initial-scale=1">
              </head>
              <body>
                <p>Dear ${email?.user?.name?.split(" ")[0]},</p>
                <p>Your application has been <b>denied</b>. The reason of denied:</p>
                <p>${text}</p>
                <p>Best Regards,</p>
                <p>${
                  LoadAllDepartment?.data?.getAllDepartments?.find(
                    (item) => item?.id == email?.user?.additionalInfo?.dept_id
                  )?.user?.name
                }</p>
                <p>&mdash;</p>
                <p>On ${moment(new Date(Number(email?.createdAt))).format(
                  "dddd, Do MMMM YYYY, h:mm:ss a"
                )} <i>${email?.user?.name}&#60;${
          email?.user?.email
        }&#62;</i> wrote:</p>
                <p>Start: ${moment(email?.start_date).format(
                  "DD MMMM YYYY"
                )}</p>
                <p>End: ${moment(email?.end_date).format("DD MMMM YYYY")}</p>
                <p>Total: ${
                  moment
                    .duration(
                      new Date(email?.end_date) - new Date(email?.start_date)
                    )
                    .asDays() + 1
                }${
          moment
            .duration(new Date(email?.end_date) - new Date(email?.start_date))
            .asDays() +
            1 >
          1
            ? " Days"
            : " Day"
        }</p>
                <p>The reason of leave: <br /> ${email?.leave_reason}</p>
              </body>
            </html>`;
        const result = await updateLeave({
          variables: {
            ...email,
            leave_status: "Denied",
            deny_reason: text,
            cc_email: "",
            subject_email: `[Denied] Application for ${
              leaveTypeList?.find((item) => item?.value == email?.leave_type)
                ?.label
            } leave`,
            text_email: `Your application has been denied.`,
            html_email: deniedBody,
            to_email: email?.user?.email,
            created_by: Number(loggedInfo?.id),
          },
        });
        if (result?.data?.updateLeave?.result_code == "000") {
          successMessage("Leave application has been denied.", 3000);
          setAnyChangeLeave((prevState) => !prevState);
          history.push("/leave/mail");
        }
        if (result?.data?.updateLeave?.result_code == "002") {
          warningMessage(result?.data?.updateLeave?.status, 3000);
        }
      }
    }

    if (type == "approve") {
      const startDate = new Date(email?.start_date);
      const endDate = new Date(email?.end_date);
      const diffInTime = endDate.getTime() - startDate.getTime();
      const diffInDays = IsThereWeekend(
        new Date(email?.start_date),
        new Date(email?.end_date)
      )?.length;
      // const diffInDays = diffInTime / (1000 * 3600 * 24) + 1;
      const newValue =
        LoadAllAdditionalInfo?.data?.getRestLeaveByUserId[leaveName] -
        diffInDays;

      const checkStart = moment(email?.start_date).format("YYYY-MM-DD");
      const checkEnd = moment(email?.end_date).format("YYYY-MM-DD");
      const rangeDates = dateRangeArray(checkStart, checkEnd);

      // sending email info
      const sendingEmailInfo = {
        from: "genera.console@gmail.com",
        to: email?.user?.email,
        subject: `[Approved] Application for ${
          leaveTypeList?.find((item) => item?.value == email?.leave_type)?.label
        } leave`,
        cc: "ayon@ipageglobal.com",
        html: approvalLeaveEmail({
          applicant_name: email?.user?.name,
          applicant_email: email?.user?.email,
          approval_name: LoadAllDepartment?.data?.getAllDepartments?.find(
            (item) => item?.id == email?.user?.additionalInfo?.dept_id
          )?.user?.name,
          leave_date: moment(new Date(Number(email?.createdAt))).format(
            "dddd, Do MMMM YYYY, h:mm:ss a"
          ),
          start_date: moment(email?.start_date).format("DD MMMM YYYY"),
          end_date: moment(email?.end_date).format("DD MMMM YYYY"),
          total_day:
            moment
              .duration(new Date(email?.end_date) - new Date(email?.start_date))
              .asDays() + 1,
          leave_reason: email?.leave_reason,
        }),
      };

      // attendance info array
      const attendanceArray = [];
      rangeDates?.map((item) => {
        attendanceArray.push({
          rfid: email?.user?.additionalInfo?.rfid,
          login_date: item,
        });
      });

      // penaltychecking -> if have any penalty status pending, it will be resolve by leave approved
      const penaltyDates = await penaltyChecking(
        checkStart,
        checkEnd,
        Number(email?.user_id),
        loadPenalty
      );

      if (
        diffInDays <=
        LoadAllAdditionalInfo?.data?.getRestLeaveByUserId[leaveName]
      ) {
        // leave approve mutation calling
        await leaveApprove({
          variables: {
            user_id: Number(email?.user_id),
            created_by: Number(loggedInfo?.id),
            leave_id: Number(email?.id),
            penalty_leave_id: Number(email?.leave_type),
            leave_name: leaveName,
            leave_type: "regular",
            penaltyInfo: JSON.stringify(penaltyDates),
            attendanceInfo: JSON.stringify(attendanceArray),
            sendingEmailInfo: JSON.stringify(sendingEmailInfo),
            rest_leave_number: Number(newValue),
          },
        });
        LoadAllAdditionalInfo.refetch();
        LoadAllLeaves.refetch();
        Swal.fire("Success!", `The application has been approved.`, "success");
      } else {
        Swal.fire(
          "Not eligible for leave!",
          `The applicant have only <b>${
            numberToTextNumber[
              LoadAllAdditionalInfo?.data?.getRestLeaveByUserId[leaveName]
            ]
          } ${leaveName?.toUpperCase()}</b> leave.`,
          "error"
        );
      }
    }
    if (type == "cancel") {
      const diffInDays = IsThereWeekend(
        new Date(email?.start_date),
        new Date(email?.end_date)
      )?.length;
      // const diffInDays = diffInTime / (1000 * 3600 * 24) + 1;
      const newValue =
        LoadAllAdditionalInfo?.data?.getRestLeaveByUserId[leaveName] +
        diffInDays;
      let newAllAdditionalInfo = {
        ...LoadAllAdditionalInfo?.data?.getRestLeaveByUserId,
        [leaveName]: newValue,
      };
      const result3 = await updateRestLeave({
        variables: newAllAdditionalInfo,
      });
      LoadAllAdditionalInfo.refetch();
      const result = await updateLeave({
        variables: {
          ...email,
          leave_status: "Pending",
          deny_reason: "",
        },
      });
      const result2 = await deleteAttendanceByCanceledLeave({
        variables: {
          rfid: email?.user?.additionalInfo?.rfid,
          startDate: email?.start_date,
          endDate: email?.end_date,
        },
      });

      // leave update message
      if (result?.data?.updateLeave?.result_code == "000") {
        successMessage(result?.data?.updateLeave?.status, 3000);
        setAnyChangeLeave((prevState) => !prevState);
        history.push("/leave/mail");
      }
      if (result?.data?.updateLeave?.result_code == "002") {
        warningMessage(result?.data?.updateLeave?.status, 3000);
      }

      // attendance delete message
      if (
        result2?.data?.deleteAttendanceByCanceledLeave?.result_code == "000"
      ) {
        successMessage(
          result2?.data?.deleteAttendanceByCanceledLeave?.status,
          3000
        );
        setAnyChangeLeave((prevState) => !prevState);
        history.push("/leave/mail");
      }
      if (
        result2?.data?.deleteAttendanceByCanceledLeave?.result_code == "002"
      ) {
        warningMessage(
          result2?.data?.deleteAttendanceByCanceledLeave?.status,
          3000
        );
      }

      // rest leave update message
      if (result3?.data?.updateRestLeave?.result_code == "000") {
        successMessage(result3?.data?.updateRestLeave?.status, 3000);
        setAnyChangeLeave((prevState) => !prevState);
        history.push("/leave/mail");
      }
      if (result3?.data?.updateRestLeave?.result_code == "002") {
        warningMessage(result3?.data?.updateRestLeave?.status, 3000);
      }
    }
    if (type == "wfh_od") {
      // ** count rewards in weekend duty start
      const weekendCounts = countWeekends(
        new Date(email?.start_date),
        new Date(email?.end_date)
      );
      if (weekendCounts > 0) {
        await updateReward({
          variables: {
            input: {
              user_id: email?.user_id,
              reward: weekendCounts,
            },
          },
        });
      }
      // ** count rewards in weekend duty end

      const startDate = new Date(email?.start_date);
      const endDate = new Date(email?.end_date);
      const diffInTime = endDate.getTime() - startDate.getTime();
      const diffInDays = IsThereWeekend(
        new Date(email?.start_date),
        new Date(email?.end_date)
      )?.length;
      // const diffInDays = diffInTime / (1000 * 3600 * 24) + 1;
      const newValue =
        LoadAllAdditionalInfo?.data?.getRestLeaveByUserId[leaveName] -
        diffInDays;

      const checkStart = moment(email?.start_date).format("YYYY-MM-DD");
      const checkEnd = moment(email?.end_date).format("YYYY-MM-DD");
      const rangeDates = dateRangeArray(checkStart, checkEnd);

      // sending email info
      const sendingEmailInfo = {
        from: "genera.console@gmail.com",
        to: email?.user?.email,
        subject: `[Approved] Application for ${
          attendanceTypeArray?.find((item) => item?.value == email?.leave_type)
            ?.label
        }`,
        cc: "ayon@ipageglobal.com",
        html: approvalLeaveEmail({
          applicant_name: email?.user?.name,
          applicant_email: email?.user?.email,
          approval_name: LoadAllDepartment?.data?.getAllDepartments?.find(
            (item) => item?.id == email?.user?.additionalInfo?.dept_id
          )?.user?.name,
          leave_date: moment(new Date(Number(email?.createdAt))).format(
            "dddd, Do MMMM YYYY, h:mm:ss a"
          ),
          start_date: moment(email?.start_date).format("DD MMMM YYYY"),
          end_date: moment(email?.end_date).format("DD MMMM YYYY"),
          total_day:
            moment
              .duration(new Date(email?.end_date) - new Date(email?.start_date))
              .asDays() + 1,
          leave_reason: email?.leave_reason,
        }),
      };

      // attendance info array
      const attendanceArray = [];
      rangeDates?.map((item) => {
        attendanceArray.push({
          user_id: email?.user?.additionalInfo?.user_id,
          login_date: item,
        });
      });

      // penaltychecking
      const penaltyDates = await penaltyChecking(
        checkStart,
        checkEnd,
        Number(email?.user_id),
        loadPenalty
      );

      // leave approve mutation calling
      await leaveApprove({
        variables: {
          user_id: Number(email?.user_id),
          created_by: Number(loggedInfo?.id),
          leave_id: Number(email?.id),
          penalty_leave_id: Number(email?.leave_type),
          leave_name: leaveName,
          leave_type: "attendance",
          penaltyInfo: JSON.stringify(penaltyDates),
          attendanceInfo: JSON.stringify(attendanceArray),
          sendingEmailInfo: JSON.stringify(sendingEmailInfo),
          rest_leave_number: Number(newValue),
        },
      });
      LoadAllAdditionalInfo.refetch();
      LoadAllLeaves.refetch();
      Swal.fire("Success!", `The application has been approved.`, "success");
    }
  };

  // permission
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );
  // weekend check for attendance
  const IsThereWeekend = (startDate, endDate) => {
    // date format: new Date("2022-03-01");
    let weekendDates = [];
    const initialState = startDate;
    initialState.setDate(startDate.getDate());
    for (
      let date = initialState;
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      if (date.getDay() < 5) {
        weekendDates.push(new Date(date.toString()));
      }
    }
    return weekendDates;
  };

  return (
    <tr
      className={itemClass}
      onClick={() => {
        modalHandler();
        setReliverId(email?.rfid);
      }}
    >
      <th>{itemId + 1}</th>
      <td>
        {email?.user?.name} ({email?.user?.additionalInfo?.office_id})
      </td>
      <td>{moment(email?.start_date).format("LL")}</td>
      <td>{moment(email?.end_date).format("LL")}</td>
      <td>
        {
          IsThereWeekend(new Date(email?.start_date), new Date(email?.end_date))
            ?.length
        }{" "}
        {IsThereWeekend(new Date(email?.start_date), new Date(email?.end_date))
          ?.length > 1
          ? "Days"
          : "Day"}
      </td>
      <td>
        {email?.leave_status == "Pending" || email?.leave_status == "Denied" ? (
          <Badge color="danger">{email?.leave_status}</Badge>
        ) : (
          <Badge color="success">{email?.leave_status}</Badge>
        )}
      </td>
      <td>{moment(new Date(Number(email?.createdAt))).fromNow()}</td>

      {/* modal for leave view in details */}
      <Modal toggle={modalHandler} isOpen={modal} backdrop="static">
        <ModalHeader toggle={modalHandler}>
          <div className="inbox__email-left">
            <div className="inbox__email-ava mr-3">
              <img
                src={
                  email?.user?.images
                    ? email?.user?.images
                    : "https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png"
                }
                alt=""
              />
            </div>
            <div className="inbox__email-info">
              <h5 className="inbox__email-name" style={{ fontSize: 20 }}>
                {email?.user?.name} ({email?.user?.additionalInfo?.office_id})
              </h5>
              <p className="inbox__email-email mt-1" style={{ fontSize: 14 }}>
                {email?.user?.email}
              </p>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <p className="mb-1">
            {moment(new Date(Number(email?.createdAt))).format(
              "dddd, Do MMMM YYYY, h:mm:ss a"
            )}
          </p>
          <div className="flex justify-content-between">
            <h4>
              <b>
                Application for{" "}
                {
                  leaveTypeList?.find(
                    (item) => item?.value == email?.leave_type
                  )?.label
                }
                {
                  attendanceTypeArray?.find(
                    (item) => item?.value == email?.leave_type
                  )?.label
                }
                {leaveTypeList?.find((item) => item?.value == email?.leave_type)
                  ?.label
                  ? " leave"
                  : null}
              </b>
            </h4>
            <div>
              {email?.leave_status == "Pending" ||
              email?.leave_status == "Denied" ? (
                <Badge color="danger">{email?.leave_status}</Badge>
              ) : (
                <Badge color="success">{email?.leave_status}</Badge>
              )}
            </div>
          </div>
          <p>
            <b>Start: </b>
            {moment(email?.start_date).format("DD MMMM YYYY")}
          </p>
          <p>
            <b>End: </b>
            {moment(email?.end_date).format("DD MMMM YYYY")}
          </p>
          <p>
            <b>Total: </b>
            {
              IsThereWeekend(
                new Date(email?.start_date),
                new Date(email?.end_date)
              )?.length
            }{" "}
            {IsThereWeekend(
              new Date(email?.start_date),
              new Date(email?.end_date)
            )?.length > 1
              ? "Days"
              : "Day"}
          </p>

          <p className="mt-3">
            <b>
              Reason: <br />
            </b>{" "}
            {email?.leave_reason}
          </p>
          {LoadAdditionalInfoByRfid?.data?.getAllAdditionalInfoByRfid[0]
            ?.user && (
            <p className="mt-3">
              <b>
                Reliever: <br />
              </b>
              Name:{" "}
              {
                LoadAdditionalInfoByRfid?.data?.getAllAdditionalInfoByRfid[0]
                  ?.user?.name
              }{" "}
              (
              {
                LoadAdditionalInfoByRfid?.data?.getAllAdditionalInfoByRfid[0]
                  ?.rfid
              }
              ) <br />
              Email:{" "}
              {
                LoadAdditionalInfoByRfid?.data?.getAllAdditionalInfoByRfid[0]
                  ?.user?.email
              }
            </p>
          )}
          {email?.deny_reason ? (
            <div className="mt-3 bg-danger text-white rounded px-3 py-2">
              <h4>The application has been denied!</h4>
              <p>
                By{" "}
                {
                  LoadAllDepartment?.data?.getAllDepartments?.find(
                    (item) => item?.id == email?.user?.additionalInfo?.dept_id
                  )?.user?.name
                }{" "}
                (
                {
                  LoadAllDepartment?.data?.getAllDepartments?.find(
                    (item) => item?.id == email?.user?.additionalInfo?.dept_id
                  )?.user?.username
                }
                ) at{" "}
                {moment(new Date(Number(email?.updatedAt))).format(
                  "dddd, Do MMMM YYYY, h:mm:ss a"
                )}
              </p>
              <p>
                <b>Reason</b> <br /> {email?.deny_reason}
              </p>
            </div>
          ) : null}
          {JSON.parse(email?.images)?.length ? (
            <div>
              <p>
                <b>Attachment ({JSON.parse(email?.images)?.length})</b>
              </p>
              {url.map((src, index) => (
                <img
                  src={src}
                  onClick={() => openImageViewer(index)}
                  key={index}
                  style={{
                    margin: "2px",
                    height: "50px",
                    width: "auto",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                  }}
                  alt="image"
                />
              ))}
            </div>
          ) : null}
          {isViewerOpen && (
            <ImageViewer
              src={url}
              currentIndex={currentImage}
              onClose={closeImageViewer}
              disableScroll={false}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
              closeOnClickOutside={true}
            />
          )}
          {console.log(
            email,
            LoadAllDepartment?.data?.getAllDepartments?.find(
              (item) => item?.user?.user_id == email?.approve_by
            )?.user?.name
          )}
          {email?.leave_status == "Approved" ? (
            <div className="mt-3 bg-success text-white rounded px-3 py-2">
              <h4>The application has been approved!</h4>
              <p>
                By {email?.approvedUser?.name ?? ""} (
                {email?.approvedUser?.username ?? ""}) at{" "}
                {moment(new Date(Number(email?.updatedAt))).format(
                  "dddd, Do MMMM YYYY, h:mm:ss a"
                )}
              </p>
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter className="flex justify-content-end align-items-center border-0">
          <ButtonToolbar className="mt-4 justify-content-end">
            <div className="mr-3">
              {permissions?.includes("deny_leave") ? (
                email?.leave_status == "Denied" ||
                email?.leave_status == "Approved" ? null : (
                  <Button
                    onClick={() => {
                      approvedHandler(email, "deny");
                      modalHandler();
                    }}
                    size="sm"
                    color="danger"
                  >
                    Deny
                  </Button>
                )
              ) : null}
            </div>
            <div>
              {permissions?.includes("cancel_leave")
                ? email?.leave_status == "Approved"
                  ? new Date(email?.start_date) > new Date() && (
                      <Button
                        onClick={() => {
                          approvedHandler(email, "cancel");
                          modalHandler();
                        }}
                        size="sm"
                        color="primary"
                      >
                        Cancel
                      </Button>
                    )
                  : null
                : null}
              {permissions?.includes("approve_leave") ? (
                email?.leave_status == "Pending" ? (
                  email?.leave_type == "101" || email?.leave_type == "102" ? (
                    <Button
                      onClick={() => {
                        approvedHandler(email, "wfh_od");
                        modalHandler();
                      }}
                      size="sm"
                      color="primary"
                    >
                      Approve
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        approvedHandler(email, "approve");
                        modalHandler();
                      }}
                      size="sm"
                      color="primary"
                    >
                      Approve
                    </Button>
                  )
                ) : null
              ) : null}
            </div>
          </ButtonToolbar>
        </ModalFooter>
      </Modal>
    </tr>
  );
};

EmailListItem.propTypes = {
  email: EmailProps.isRequired,
  onLetter: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired,
  isChecked: PropTypes.bool.isRequired,
};

export default EmailListItem;
