import React, { useState } from "react";
import { Badge, Card, CardBody, Table } from "reactstrap";
import { Pagination } from "@mui/material";
import { attendanceTypeArray } from "../../../../../utils/utilities";
import {
  dateTime,
  labelName,
  onlyTime,
  timeToMiliseconds,
  usePagination,
} from "../../../../../utils/helpers";

const AttendanceReportWorkingHistory = ({ data, workingHour }) => {
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(data?.length / PER_PAGE);
  const pageData = usePagination(data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    pageData.jump(p);
  };
  return (
    <div>
      <h4 className="mb-2">Working Hour History</h4>
      <div>
        <p className="row">
          <div
            style={{
              color: "#999999",
              fontWeight: 500,
            }}
            className="col-auto p-0 pb-1"
          >
            Total:
          </div>
          <div className="col p-0 pl-2 pb-1">{data?.length ?? 0}</div>
        </p>
      </div>
      <Table role="table" striped hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Attendance</th>
            <th>Login Time</th>
            <th>Logout Time</th>
            <th>Work Hour</th>
            <th>Overtime</th>
            <th>Logout</th>
          </tr>
        </thead>
        <tbody>
          {pageData?.currentData()?.map((workHistory) => {
            return (
              <tr key={workHistory?.id}>
                <td>{workHistory?.id} </td>
                <td>
                  {dateTime(
                    new Date(workHistory?.login_date).getTime(),
                    "DD/MM/YYYY"
                  )}
                </td>
                <td>
                  {workHistory?.type
                    ? labelName(attendanceTypeArray, workHistory?.type)
                    : "Regular"}
                </td>
                <td>
                  {dateTime(
                    new Date(workHistory?.login_time).getTime(),
                    "hh:mm:ss a"
                  )}
                </td>
                <td>
                  {dateTime(
                    new Date(workHistory?.logout_time).getTime(),
                    "hh:mm:ss a"
                  )}
                </td>
                <td>
                  {onlyTime(
                    workHistory?.working_hour_report?.hours,
                    workHistory?.working_hour_report?.minutes,
                    workHistory?.working_hour_report?.seconds
                  )}
                </td>
                <td>
                  {onlyTime(
                    workHistory?.overtime?.hours,
                    workHistory?.overtime?.minutes,
                    workHistory?.overtime?.seconds
                  )}
                </td>
                <td>
                  <Badge color={workHistory?.isLogout ? "success" : "danger"}>
                    {workHistory?.isLogout ? "Yes" : "No"}
                  </Badge>
                </td>
              </tr>
            );
          })}

          <tr className="border-top border-bottom">
            <td colSpan="5" className="text-center"><b>Total</b></td>
            <td>
              <b>
                {onlyTime(
                  workingHour?.working_hour_report?.hours,
                  workingHour?.working_hour_report?.minutes,
                  workingHour?.working_hour_report?.seconds
                )}
              </b>
            </td>
            <td>
              <b>
                {onlyTime(
                  workingHour?.overtime?.hours,
                  workingHour?.overtime?.minutes,
                  workingHour?.overtime?.seconds
                )}
              </b>
            </td>
            <td></td>
          </tr>
        </tbody>
      </Table>
      <div className="mt-3">
        <Pagination count={count} page={page} onChange={handleChange} />
      </div>
    </div>
  );
};

export default AttendanceReportWorkingHistory;
