import { useMutation } from "@apollo/client";
import { PASSWORD_RESET_BY_EMAIL } from "../../Mutation";

const usePasswordResetByEmailMutation = (props) => {
  const [passwordResetByEmail, { data, ...rest }] = useMutation(
    PASSWORD_RESET_BY_EMAIL,
    {
      ...props,
    }
  );
  return {
    ...rest,
    data: data?.passwordResetByEmail,
    passwordResetByEmail,
  };
};

export default usePasswordResetByEmailMutation;
