import { Table } from "reactstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_DEPARTMENT, GET_ALL_DESIGNATION, GET_ALL_USER } from "../../../../GraphQL/Queries";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { DELETE_DEPARTMENT_BY_ID, DEPARTMENT_DELETED_STATUS_UPDATE } from "../../../../GraphQL/Mutation";
import { successMessage } from '../../../ReactToastify'
import { decryption } from "../../../HelperFunctions";

const DeptListTable = () => {
    const [allDept, setAllDept] = useState([]);
    const [deleteDeptId, setDeleteDeptId] = useState(null);
    const LoadAllDepartment = useQuery(GET_ALL_DEPARTMENT);
    const [deleteDepartmentById] = useMutation(DEPARTMENT_DELETED_STATUS_UPDATE);
    const history = useHistory();
    const UserList = useQuery(GET_ALL_USER);
    const DesigList = useQuery(GET_ALL_DESIGNATION);
    const [deleteToggle, setDeleteToggle] = useState(false);
    useEffect(() => {
        setAllDept(LoadAllDepartment?.data?.getAllDepartments);
        LoadAllDepartment?.refetch();
    }, [LoadAllDepartment?.data?.getAllDepartments, deleteToggle])
    const deleteHandler = async (deptId) => {
        await deleteDepartmentById({
            variables: {
                id: deptId
            }
        })
        successMessage("Delete successfully...", 1500);
        setDeleteToggle(prevState => !prevState);
    }
    // permission
    const permissions = decryption(JSON.parse(localStorage?.getItem("permissions")));
    return (
        <Table role="table" striped hover responsive>
            <thead>
                <tr role="row">
                    <th>#</th>
                    <th>Name</th>
                    <th>Head Of Department</th>
                    <th>Description</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {allDept?.map((item,index) => (<tr key={item?.id}>
                    <th onClick={()=> history.push({pathname: "/department/member", state: {deptID: item?.id, deptName: item?.name}})}>{index+1}</th>
                    <td onClick={()=> history.push({pathname: "/department/member", state: {deptID: item?.id, deptName: item?.name}})}>{item?.name}</td>
                    <td onClick={()=> history.push({pathname: "/department/member", state: {deptID: item?.id, deptName: item?.name}})}>{item?.user?.name} ({item?.user?.username})</td>
                    <td onClick={()=> history.push({pathname: "/department/member", state: {deptID: item?.id, deptName: item?.name}})}>{item?.description?.split(' ').slice(0,15).join(' ')}...</td>
                    <td>
                        {permissions?.includes("delete_department") && <button
                            className="inbox__email-btn"
                            type="button"
                            aria-label="edit"
                            onClick={() => {
                                deleteHandler(item?.id);
                                setDeleteDeptId(item?.id)
                            }}
                        >
                            <DeleteIcon fontSize="small" />
                        </button>}
                        {permissions?.includes("update_department") && <button
                            className="inbox__email-btn mr-1"
                            type="button"
                            aria-label="edit"
                            onClick={() => history.push({
                                pathname: "/department/new",
                                state: {
                                    dept: item
                                }
                            })}
                        >
                            <EditIcon fontSize="small" />
                        </button>}
                    </td>
                </tr>))}
            </tbody>
            {/* {_DATA?.currentData().length > 0 && <Pagination
                count={count}
                page={page}
                onChange={handleChange}
            />} */}
        </Table>
    );
}

export default DeptListTable;