import React from "react";
import { Col, Button, Row } from "reactstrap";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SelectField } from "@/shared/components/form/Select";
import FormField from "./FormField";
import moment from "moment";
import { useQuery } from "@apollo/client";
import {
  GET_ALL_USER,
  SEARCH_ATTENDANCE_BETWEEN_DATE,
  SEARCH_ATTENDANCE_BETWEEN_DATE_AND_RFID,
} from "../../../GraphQL/Queries";
import { decryption } from "../../HelperFunctions";
import DatePicker from "react-datepicker";

const SearchForm = ({
  setFilterByUsername,
  setToDate,
  setFromDate,
  toDate,
  fromDate,
  filterByAny,
  userID,
  roles,
  searchResult,
  setFilteredResults,
  filteredAttendance,
  setOnSearch,
  setFilteredAttendance,
  setIsViewResult,
  isViewResult,
  allEmployees,
  setAllEmployees,
  todayCount,
  workingDays,
  LoadWorkingDayData,
}) => {
  const { handleSubmit, control, watch, reset } = useForm();

  const LoadAllUsers = useQuery(GET_ALL_USER);
  const [isDisabledDate, setIsDisabledDate] = useState(false);
  // get previous date of month fron now start
  const prevDateOfMonth = new Date();
  const month = prevDateOfMonth.getMonth();
  prevDateOfMonth.setMonth(prevDateOfMonth.getMonth() - 1);
  while (prevDateOfMonth.getMonth() === month) {
    prevDateOfMonth.setDate(prevDateOfMonth.getDate() - 1);
  }
  // last weeek
  const firstDay = new Date();
  const previousweek = new Date(firstDay.getTime() - 7 * 24 * 60 * 60 * 1000);
  useEffect(() => {
    let attendanceArray = LoadAllUsers?.data?.getUser?.map((item) => {
      return {
        value: item.additionalInfo?.user_id,
        label: `${item.name} - (${item?.additionalInfo?.rfid})`,
      };
    });
    attendanceArray?.length &&
      attendanceArray.unshift({ value: null, label: "None" });
    setAllEmployees(attendanceArray);
  }, [LoadAllUsers?.data?.getUser?.length]);
  // const searchBetweenDate = useQuery(SEARCH_ATTENDANCE_BETWEEN_DATE);
  // const searchBetweenDateAndRfid = useQuery(SEARCH_ATTENDANCE_BETWEEN_DATE_AND_RFID);
  useEffect(() => {
    reset({ user_id: "", search_type: "" });
  }, [filterByAny]);

  const searchType = [
    { value: null, label: "None" },
    { value: 1, label: "Weekly" },
    { value: 2, label: "Monthly" },
  ];
  useEffect(() => {
    if (watch("search_type")?.value) {
      setIsDisabledDate(true);
    }
    if (watch("search_type")?.value == null) {
      setIsDisabledDate(false);
    }
  }, [watch("search_type")]);
  const onSubmit = async (value) => {
    setFilteredAttendance([]);
    LoadWorkingDayData?.refetch({
      input: {
        start_date: fromDate,
        end_date: toDate,
      },
    });
    searchResult(
      fromDate,
      toDate,
      value?.user_id?.value,
      Number(value?.search_type?.value)
    );
    setIsViewResult(true);
  };

  return (
    <Row>
      <Col md={12} className="mx-auto">
        <form
          className="form justify-content-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Col xs={6} md={3} lg={2}>
            <div className="form__form-group">
              <div className="form__form-group-field leave-calender">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={fromDate}
                  placeholderText="From"
                  onChange={(date) => {
                    setFromDate(date);
                  }}
                  showWeekNumbers
                />
              </div>
            </div>
          </Col>
          <Col xs={6} md={3} lg={2}>
            <div className="form__form-group">
              <div className="form__form-group-field leave-calender">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={toDate}
                  placeholderText="To"
                  onChange={(date) => {
                    setToDate(date);
                  }}
                  showWeekNumbers
                />
              </div>
            </div>
          </Col>
          {roles?.includes("System Admin") ||
          roles?.includes("Human Resource (HR)") ||
          roles?.includes("Head Of Department") ? (
            <Col xs={12} md={6} lg={3}>
              <div className="form__form-group mr-3">
                <div className="form__form-group-field">
                  <FormField
                    name="user_id"
                    control={control}
                    component={SelectField}
                    options={allEmployees}
                    placeholder="Select user"
                  />
                </div>
              </div>
            </Col>
          ) : null}
          {roles?.includes("System Admin") ||
          roles?.includes("Human Resource (HR)") ||
          roles?.includes("Head Of Department") ? (
            <Col xs={12} md={6} lg={2}>
              <div className="form__form-group mr-3">
                <div className="form__form-group-field">
                  <FormField
                    name="search_type"
                    control={control}
                    component={SelectField}
                    options={searchType}
                    placeholder="Select weekly/monthly"
                  />
                </div>
              </div>
            </Col>
          ) : null}
          <Col xs={12} md={6} lg={3}>
            <div className="form__form-group">
              <div className="form__form-group-field justify-content-end justify-content-md-center">
                <Button type="submit"> Filter </Button>
                <Button
                  onClick={() => {
                    reset({ user_id: "", search_type: "" });
                    setFilterByUsername(null);
                    setIsDisabledDate(false);
                    setIsViewResult(false);
                    setFromDate(previousweek);
                    setToDate(new Date());
                    setFilteredAttendance([]);
                  }}
                >
                  {" "}
                  Clear{" "}
                </Button>
              </div>
            </div>
          </Col>
        </form>
      </Col>
      <Col md={12}>
        <span className="text-success">
          {isViewResult && filteredAttendance?.length > 0
            ? watch("user_id")?.value
              ? `Attendance: ${filteredAttendance?.length}/${
                  workingDays?.working_days
                } working days, Holiday: ${
                  workingDays?.holidays
                } days, Weekend: ${workingDays?.weekends} days, Total: ${
                  workingDays?.working_days +
                  workingDays?.weekends +
                  workingDays?.holidays
                } days`
              : `Result found ${filteredAttendance?.length} items`
            : null}
        </span>
      </Col>
      <Col md={12}>
        <span className="text-success bold-text">
          {roles?.includes("System Admin") ||
          roles?.includes("Human Resource (HR)") ||
          roles?.includes("Head Of Department")
            ? !isViewResult && todayCount
              ? `Today attendance ${
                  todayCount?.filter((item) => item?.attendance == true)?.length
                } of ${todayCount?.length} users.`
              : null
            : null}
        </span>
      </Col>
    </Row>
  );
};

export default SearchForm;
