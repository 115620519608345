import renderSelectField from "@/shared/components/form/Select";
import { useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "mdi-react/CheckIcon";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonToolbar, Col, Row } from "reactstrap";
import {
  CREATE_CROP,
  CREATE_VARIETY,
  FARMING_PRACTICE,
  UPDATE_FARMING_PRACTICE,
} from "../../../../GraphQL/Mutation";
import { CROP_LIST, GET_UNITS } from "../../../../GraphQL/Queries";
import { farmingAdd } from "../../../../redux/actions/farmerAction";
import { fertilizer, productSell, weeds } from "../../../../utils/utilities";

const FarmingPracticeEdit = ({
  isHorizontal,
  isAboveError,
  name,
  className,
  toggle,
  gen_id,
  farmingPractice,
  pesticideData,
}) => {
  const [farmingData, setFarmingData] = useState({});
  const [selectedCrop, setSelectedCrop] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isCropChecked, setIsCropChecked] = useState(false);
  useEffect(() => {
    setFarmingData(farmingPractice);
  }, []);
  const defaultDiseasePestDose = farmingPractice?.disease_pests_dose?.map(
    (data) => {
      return {
        disease: data.disease,
        dose: data.dose,
        pest: data.pest,
        dose_unit: data.dose_unit,
      };
    }
  );
  const oldFertilizer = farmingPractice?.fertilizer?.map((data) => {
    return { name: data?.name, value: data.value };
  });
  const [value, setValue] = useState({});
  const [season, setSeason] = useState([]);
  const [applyFertilizer, setApplyFertilizer] = useState(
    oldFertilizer?.length ? oldFertilizer : []
  );
  const [inputList, setInputList] = useState(
    defaultDiseasePestDose?.length
      ? defaultDiseasePestDose
      : [
          {
            disease: "",
            pest: "",
            dose: "",
            dose_unit: "",
          },
        ]
  );
  const dispatch = useDispatch();
  const [createUser, { data, loading, error }] = useMutation(FARMING_PRACTICE);
  const [createVariety, { variety }] = useMutation(CREATE_VARIETY);
  const [createCrop, { crop }] = useMutation(CREATE_CROP);
  const [updateUser, update] = useMutation(UPDATE_FARMING_PRACTICE);
  const getUnit = useQuery(GET_UNITS);

  const onSubmit = async (value) => {
    let list = [...inputList];
    await inputList?.map((data, index) => {
      if (!data.disease && !data.dose && !data.pest) {
        list.splice(index, 1);
        setInputList(list);
      }
    });
    if (value?.others_variety) {
      await createVariety({
        variables: {
          crop_id: selectedCrop?.id,
          variety_name: value?.others_variety,
        },
        onQueryUpdated(observableQuery) {
          // Define any custom logic for determining whether to refetch
          if (shouldRefetchQuery(observableQuery)) {
            return observableQuery.refetch();
          }
        },
      });
    }
    if (value?.others_crop) {
      await createCrop({
        variables: {
          crop_name: value?.others_crop,
        },
        onQueryUpdated(observableQuery) {
          // Define any custom logic for determining whether to refetch
          if (shouldRefetchQuery(observableQuery)) {
            return observableQuery.refetch();
          }
        },
      });
    }

    let farmingPracticeValue = {
      gen_id: gen_id,
      risk_of_flood: value?.risk_flood?.label,
      farming_year: value?.years?.label,
      season: value.season?.label,
      crop: value?.others_crop ? value?.others_crop : value.crop?.label,
      variety: value?.others_variety
        ? value?.others_variety
        : value?.variety?.label,
      cultivated_areas: value?.cultivated_area,
      training: value?.train?.label,
      seeds_collection: value?.collect_seeds?.label,
      company: value?.company_name?.length ? value?.company_name : "self",
      disease_pests_dose: list,
      spray_time: value?.spray_count,
      fertilizer: applyFertilizer,
      weeds: value?.weeds?.label,
      production_cost: value?.production_cost,
      sell_product: value?.sell_product?.label,
      selling_price: value?.selling_price,
      current_price: value?.current_price,
      profit: value?.profit,
      price_unit: value?.price_unit?.label,
    };
    if (farmingData?.id) {
      await updateUser({
        variables: { ...farmingPracticeValue, id: farmingData?.id },
        onQueryUpdated(observableQuery) {
          // Define any custom logic for determining whether to refetch
          if (shouldRefetchQuery(observableQuery)) {
            return observableQuery.refetch();
          }
        },
      });
      dispatch(farmingAdd());
      if (!update.error) {
        toggle();
      }
    } else {
      await createUser({
        variables: farmingPracticeValue,
        onQueryUpdated(observableQuery) {
          // Define any custom logic for determining whether to refetch
          if (shouldRefetchQuery(observableQuery)) {
            return observableQuery.refetch();
          }
        },
      });

      dispatch(farmingAdd());
      if (!error) {
        toggle();
      }
    }
  };

  const handleAdd = () => {
    setInputList([
      ...inputList,
      {
        disease: "",
        pest: "",
        dose: "",
        dose_unit: "",
      },
    ]);
  };
  const handleDelete = (index) => {
    const list = [...inputList];
    if (list?.length > 1) {
      list.splice(index, 1);
      setInputList(list);
    }
  };

  function handleDiseaseChange(i, e) {
    const list = [...inputList];
    list[i]["disease"] = e.target.value;
    setInputList(list);
  }
  function handlePestChange(i, e) {
    const list = [...inputList];
    list[i]["pest"] = e.target.value;
    setInputList(list);
  }
  function handleDoseChange(i, e) {
    const list = [...inputList];
    list[i]["dose"] = e.target.value;
    setInputList(list);
  }

  const handleFertilizer = (e) => {
    const check = applyFertilizer?.find((data) => data?.name === e);
    if (check) {
      const list = [...applyFertilizer];
      const index = applyFertilizer.findIndex((data) => data?.name === e);
      list.splice(index, 1);
      setApplyFertilizer(list);
    } else {
      setApplyFertilizer([...applyFertilizer, { name: e, value: "" }]);
    }
  };

  const riskOfFloodDefault = [
    { value: "one", label: "Yes" },
    { value: "two", label: "No" },
  ].find((data) => data.label === farmingData?.risk_of_flood);
  const defaultFirmingYear = [
    { value: "one", label: "2022" },
    { value: "two", label: "2021" },
    { value: "three", label: "2020" },
    { value: "four", label: "2019" },
    { value: "five", label: "2018" },
    { value: "six", label: "2017" },
  ].find((data) => data.label === farmingData?.farming_year);
  const defaultSeason = [
    { value: "one", label: "Robi season" },
    { value: "two", label: "Kharip-01 season" },
    { value: "three", label: "Kharip-02 season" },
  ].find((data) => data.label === farmingData?.season);
  const defaultTrain = [
    { value: "one", label: "Yes" },
    { value: "two", label: "No" },
  ].find((data) => data.label === farmingData?.training);
  const defaultSeedCollection = [
    { value: "one", label: "Self-production seeds" },
    { value: "two", label: "Purchase from market" },
  ].find((data) => data.label === farmingData?.seeds_collection);

  const [allCrop, getAllCrops] = useState([]);
  const cropList = useQuery(CROP_LIST);
  useEffect(() => {
    if (cropList?.data?.getAllCrops?.rows?.length) {
      getAllCrops(cropList?.data?.getAllCrops?.rows);
    }
  }, [cropList?.data?.getAllCrops?.rows?.length, data?.getAllCrops]);

  const getData = useSelector((state) => state.farmer);

  useEffect(() => {
    cropList?.refetch();
  }, [getData]);
  const allCrops = allCrop?.map((data) => {
    return {
      value: data?.id,
      label: data?.crop_name,
    };
  });
  const allVariety = allCrop
    ?.find((data) => data?.crop_name === farmingData?.crop)
    ?.variety?.map((data, index) => {
      return {
        value: index,
        label: data?.variety_name,
      };
    });

  const handleClick = (e) => {
    setIsChecked(!isChecked);
  };

  // check the array of pesticide is null or not if the null then remove it from the array
  const defaultPriceUnit = getUnit?.data?.getUnit?.rows
    ?.map((data, index) => {
      return {
        value: index,
        label: data?.unit_name,
      };
    })
    ?.find((value) => value.label === farmingData?.price_unit);

  console.log(getUnit?.data?.getUnit?.rows);
  return (
    <div>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form, submitting, pristine, values, meta }) => {
          setSelectedCrop(
            allCrop?.find((data) => data?.id === values?.crop?.value)
          );
          console.log(values);
          const pest = pesticideData?.find(
            (pesticideData) =>
              pesticideData?.disease_name === values?.disease?.label
          )?.chemical;
          console.log(pest);
          return (
            <
              form
              className={`form ${isHorizontal && "form--horizontal"}`}
              onSubmit={handleSubmit}
            >
              <Col md={12}>
                <h3 className="page-title">{"Farming Practices"}</h3>
              </Col>
              <Col>
                <Row>
                  <Col md={6}>
                    <div className="form__form-group">
                      <label className="form__form-group-label">
                        Risk of flooding of your land
                      </label>
                      <div className="form__form-group-field">
                        <Field
                          name="risk_flood"
                          component={renderSelectField}
                          options={[
                            { value: "one", label: "Yes" },
                            { value: "two", label: "No" },
                          ]}
                          defaultValue={riskOfFloodDefault}
                          placeholder=" Risk of flooding of your land"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <label className="form__form-group-label">
                        Select year
                      </label>
                      <div className="form__form-group-field">
                        <Field
                          name="years"
                          component={renderSelectField}
                          options={[
                            { value: "one", label: "2022" },
                            { value: "two", label: "2021" },
                            { value: "three", label: "2020" },
                            { value: "four", label: "2019" },
                            { value: "five", label: "2018" },
                            { value: "six", label: "2017" },
                          ]}
                          defaultValue={defaultFirmingYear}
                          placeholder=" Select year"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="form__form-group">
                  <label className="form__form-group-label">
                    Select Season
                  </label>
                  <div className="form__form-group-field">
                    <Field
                      name="season"
                      component={renderSelectField}
                      options={[
                        { value: "one", label: "Robi season" },
                        { value: "two", label: "Kharip-01 season" },
                        { value: "three", label: "Kharip-02 season" },
                      ]}
                      defaultValue={defaultSeason}
                      placeholder=" Select year"
                    />
                  </div>
                </div>

                <Row>
                  <Col md={6}>
                    <div className="form__form-group">
                      <label className="form__form-group-label">
                        Select Crop
                      </label>
                      <div className="form__form-group-field">
                        <Field
                          name="crop"
                          component={renderSelectField}
                          options={allCrops}
                          defaultValue={allCrops?.find(
                            (data) => data.label === farmingData?.crop
                          )}
                          placeholder=" Select Crops"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <label className="checkbox-btn">
                          <input
                            className="checkbox-btn__checkbox"
                            name="occupation"
                            component="input"
                            type="checkbox"
                            value="Others"
                            onClick={(e) =>
                              setIsCropChecked((prevState) => !prevState)
                            }
                          />{" "}
                          <span
                            className="checkbox-btn__checkbox-custom"
                            style={{
                              background: "white",
                              borderColor: "#4ce1b6",
                            }}
                          >
                            <CheckIcon />
                          </span>
                          <span className="checkbox-btn__label">Others</span>
                        </label>
                      </div>
                    </div>
                    {isCropChecked && (
                      <div className="form__form-group">
                        <label className="form__form-group-label">
                          Others Crop
                        </label>
                        <div className="form__form-group-field">
                          <Field
                            name="others_crop"
                            component="input"
                            type="text"
                            placeholder="Others Crop"
                            defaultValue=""
                          />
                        </div>
                      </div>
                    )}
                  </Col>

                  <Col md={6}>
                    <div className="form__form-group">
                      <label className="form__form-group-label">
                        Variety of selected crops
                      </label>
                      <div className="form__form-group-field">
                        <Field
                          name="variety"
                          component={renderSelectField}
                          options={selectedCrop?.variety?.map((data) => {
                            return {
                              value: data.variety_name,
                              label: data.variety_name,
                            };
                          })}
                          defaultValue={allVariety?.find(
                            (data) => data.label === farmingData?.variety
                          )}
                          placeholder=" Select Crops"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <label className="checkbox-btn">
                          <input
                            className="checkbox-btn__checkbox"
                            name="occupation"
                            component="input"
                            type="checkbox"
                            value="Others"
                            onClick={(e) => handleClick(e)}
                          />{" "}
                          <span
                            className="checkbox-btn__checkbox-custom"
                            style={{
                              background: "white",
                              borderColor: "#4ce1b6",
                            }}
                          >
                            <CheckIcon />
                          </span>
                          <span className="checkbox-btn__label">Others</span>
                        </label>
                      </div>
                    </div>
                    {isChecked && (
                      <div className="form__form-group">
                        <label className="form__form-group-label">
                          Others Variety
                        </label>
                        <div className="form__form-group-field">
                          <Field
                            name="others_variety"
                            component="input"
                            type="text"
                            placeholder="Others Variety"
                            defaultValue=""
                          />
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <div className="form__form-group">
                  <label className="form__form-group-label">
                    Select cultivated area of that crop (শতক)
                  </label>
                  <div className="form__form-group-field">
                    <Field
                      name="cultivated_area"
                      component="input"
                      type="number"
                      defaultValue={farmingData?.cultivated_areas}
                      placeholder="Select cultivated area of that crop (শতক)"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <label className="form__form-group-label">
                    Do you get any training from GO/NGO extension workers
                  </label>
                  <div className="form__form-group-field">
                    <Field
                      name="train"
                      component={renderSelectField}
                      options={[
                        { value: "one", label: "Yes" },
                        { value: "two", label: "No" },
                      ]}
                      defaultValue={defaultTrain}
                      placeholder=" Do you get any training from GO/NGO extension workers"
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <label className="form__form-group-label">
                    Where do you collect your seeds?
                  </label>
                  <div className="form__form-group-field">
                    <Field
                      name="collect_seeds"
                      component={renderSelectField}
                      options={[
                        { value: "one", label: "Self-production seeds" },
                        { value: "two", label: "Purchase from market" },
                      ]}
                      defaultValue={defaultSeedCollection}
                      placeholder="  Where do you collect your seeds?"
                    />
                  </div>
                  {values?.collect_seeds?.value === "two" && (
                    <div className="form__form-group">
                      <label className="form__form-group-label">
                        Which Company?
                      </label>
                      <div className="form__form-group-field">
                        <Field
                          name="company_name"
                          component="input"
                          type="text"
                          defaultValue={farmingData?.company}
                          placeholder="Which Company? "
                        />
                      </div>
                    </div>
                  )}
                </div>
                {inputList?.map((data, index) => {
                  return (
                    <div className="disease-form" key={index}>
                      <Row className="align-middle">
                        <Col>
                          <Row>
                            <Col md={6} xl={3}>
                              <div className="form__form-group">
                                <label className="form__form-group-label">
                                  Disease
                                </label>
                                <Field
                                  name="disease"
                                  component={renderSelectField}
                                  options={pesticideData?.map((data, index) => {
                                    return {
                                      value: data?.id,
                                      label: data?.disease_name,
                                    };
                                  })}
                                  defaultValue={pesticideData
                                    ?.map((pesticide, index) => {
                                      return {
                                        value: pesticide?.id,
                                        label: pesticide?.disease_name,
                                      };
                                    })
                                    ?.find(
                                      (value) => value.label === data?.disease
                                    )}
                                  placeholder=" disease"
                                />
                                <OnChange name="disease">
                                  {(val, i) => {
                                    const list = [...inputList];
                                    list[index]["disease"] = val.label;
                                    setInputList(list);
                                  }}
                                </OnChange>
                              </div>
                            </Col>
                            <Col md={6} xl={3}>
                              <div className="form__form-group">
                                <label className="form__form-group-label">
                                  Pest
                                </label>
                                <Field
                                  name="pest"
                                  component={renderSelectField}
                                  options={pest?.map((pest, index) => {
                                    return {
                                      value: pest?.pesticide_id,
                                      label: pest?.company_name,
                                    };
                                  })}
                                  defaultValue={pest
                                    ?.map((pest, index) => {
                                      return {
                                        value: pest?.pesticide_id,
                                        label: pest?.company_name,
                                      };
                                    })
                                    ?.find(
                                      (value) => value.label === data?.pest
                                    )}
                                  placeholder=" pest"
                                />
                                <OnChange name="pest">
                                  {(val, i) => {
                                    const list = [...inputList];
                                    list[index]["pest"] = val.label;
                                    setInputList(list);
                                  }}
                                </OnChange>
                              </div>
                            </Col>
                            <Col md={6} xl={3}>
                              <div className="form__form-group">
                                <label className="form__form-group-label">
                                  Dose
                                </label>
                                <input
                                  name="dose"
                                  type="text"
                                  placeholder="Dose"
                                  value={data.dose || ""}
                                  onChange={(e) => handleDoseChange(index, e)}
                                />
                              </div>
                            </Col>
                            <Col md={6} xl={3}>
                              <div className="form__form-group">
                                <label className="form__form-group-label">
                                  Unit
                                </label>
                                <Field
                                  name="dose_unit"
                                  component={renderSelectField}
                                  options={getUnit?.data?.getUnit?.rows?.map(
                                    (data, index) => {
                                      return {
                                        value: index,
                                        label: data?.unit_name,
                                      };
                                    }
                                  )}
                                  defaultValue={getUnit?.data?.getUnit?.rows
                                    ?.map((data, index) => {
                                      return {
                                        value: index,
                                        label: data?.unit_name,
                                      };
                                    })
                                    ?.find(
                                      (value) => value.label === data?.dose_unit
                                    )}
                                  placeholder=" Unit"
                                />
                                <OnChange name="dose_unit">
                                  {(val, i) => {
                                    const list = [...inputList];
                                    list[index]["dose_unit"] = val.label;
                                    setInputList(list);
                                  }}
                                </OnChange>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          className="disease-form-end mt-3 d-flex align-middle"
                          sm={1}
                        >
                          <IconButton onClick={() => handleDelete(index)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
                <div className="form__form-group">
                  <Button color="success" onClick={handleAdd}>
                    Add
                  </Button>
                </div>
                <div className="form__form-group">
                  <label className="form__form-group-label">
                    How many times do you spray
                  </label>
                  <div className="form__form-group-field">
                    <Field
                      name="spray_count"
                      component="input"
                      type="number"
                      defaultValue={farmingData?.spray_time}
                      placeholder="How many times do you spray? "
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <label className="form__form-group-label">
                    apply the fertilizer
                  </label>
                  {fertilizer?.map((item, index) => (
                    <div key={index}>
                      <label className="checkbox-btn">
                        <Field
                          className="checkbox-btn__checkbox"
                          name="occupation"
                          component="input"
                          type="checkbox"
                          checked={applyFertilizer?.find(
                            (data) => data?.name === item.label
                          )}
                          value={item.label}
                          onClick={() => handleFertilizer(item.label)}
                        />{" "}
                        <span
                          className="checkbox-btn__checkbox-custom"
                          style={{
                            background: "white",
                            borderColor: "#4ce1b6",
                          }}
                        >
                          <CheckIcon />
                        </span>
                        <span className="checkbox-btn__label">
                          {item.label}
                        </span>
                      </label>
                      {applyFertilizer?.find(
                        (data) => data?.name === item.label
                      ) ? (
                        <>
                          <div className="form__form-group">
                            <label className="form__form-group-label">
                              Fertilizer value
                            </label>
                            <div className="form__form-group-field">
                              <Field
                                name={item.label}
                                component="input"
                                type="number"
                                defaultValue={
                                  applyFertilizer?.find(
                                    (data) => data?.name === item.label
                                  )?.value
                                }
                                placeholder="Fertilizer value"
                              />
                              <OnChange name={item.label}>
                                {(val, previous) => {
                                  const list = [...applyFertilizer];
                                  const index = applyFertilizer.findIndex(
                                    (data) => data?.name === item.label
                                  );
                                  list[index]["value"] = val;
                                  setApplyFertilizer(list);
                                }}
                              </OnChange>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
                <div className="form__form-group">
                  <label className="form__form-group-label">
                    How do you control weeds?
                  </label>
                  <div className="form__form-group-field">
                    <Field
                      name="weeds"
                      component={renderSelectField}
                      options={weeds}
                      defaultValue={weeds?.find(
                        (data) => data.label === farmingData?.weeds
                      )}
                      placeholder=" How do you control weeds?"
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <label className="form__form-group-label">
                    How much does your production cost per bigha?
                  </label>
                  <div className="form__form-group-field">
                    <Field
                      name="production_cost"
                      component="input"
                      type="number"
                      defaultValue={farmingData?.production_cost}
                      placeholder="How much does your production cost per bigha?"
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <label className="form__form-group-label">
                    Where do you sell your product
                  </label>
                  <div className="form__form-group-field">
                    <Field
                      name="sell_product"
                      component={renderSelectField}
                      options={productSell}
                      defaultValue={productSell?.find(
                        (data) => data.label === farmingData?.sell_product
                      )}
                      placeholder=" Where do you sell your product"
                    />
                  </div>
                </div>
                <Row>
                  <Col md={6}>
                    <div className="form__form-group">
                      <label className="form__form-group-label">
                        Selling price
                      </label>
                      <div className="form__form-group-field">
                        <Field
                          name="selling_price"
                          component="input"
                          type="number"
                          defaultValue={farmingData?.selling_price}
                          placeholder=" Selling price of your cultivated crops"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <label className="form__form-group-label">
                        Price Unit
                      </label>
                      <Field
                        name="price_unit"
                        component={renderSelectField}
                        options={getUnit?.data?.getUnit?.rows?.map(
                          (data, index) => {
                            return {
                              value: index,
                              label: data?.unit_name,
                            };
                          }
                        )}
                        defaultValue={defaultPriceUnit}
                        placeholder="Price Unit"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="form__form-group">
                  <label className="form__form-group-label">
                    The current market price of that product.
                  </label>
                  <div className="form__form-group-field">
                    <Field
                      name="current_price"
                      component="input"
                      type="number"
                      defaultValue={farmingData?.current_price}
                      placeholder="The current market price of that product."
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <label className="form__form-group-label">
                    Total profit do you make?
                  </label>
                  <div className="form__form-group-field">
                    <Field
                      name="profit"
                      component="input"
                      type="number"
                      defaultValue={farmingData?.profit}
                      placeholder="Total profit do you make?"
                    />
                  </div>
                </div>
                <ButtonToolbar
                  className="form__button-toolbar"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Button color="primary" type="submit">
                    {farmingData?.id ? "Update" : "Save"}
                  </Button>
                  <Button type="button" onClick={toggle}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Col>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default FarmingPracticeEdit;
