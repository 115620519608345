import React, { useState } from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col, Input, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import FormField from "../FormField";
import { useHistory } from "react-router";
import { useMutation } from '@apollo/client';
import { CREATE_COMPANY, UPDATE_COMPANY } from '../../../../GraphQL/Mutation';
import Swal from 'sweetalert2';
import BASE_URL from "../../../../utils/api/baseUrl";
import images from "@/shared/img/uploader/placeholder.png";
import axios from 'axios';

const NewCompany = () => {
    const history = useHistory();
    const updateData = history?.location?.state?.data;
    const [preview, setPreview] = useState("");
    const [image, setImage] = useState(updateData?.logo);
    const { handleSubmit, control, register, formState: { errors }, } = useForm();
    const [createCompany, createFeeback] = useMutation(CREATE_COMPANY);
    const [updateCompany, updateFeedback] = useMutation(UPDATE_COMPANY);
    console.log(preview , image, preview && image);
    const onSubmit = async (data) => {

        // image handler
        const formData = new FormData();
        formData.append("images", preview);
        let isFile;
        if (preview && image) {
            let res = await axios.post(
                `${BASE_URL}/api/image/delete`,
                { oldImage: image },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (res?.data) {
                const file = await axios.post(`${BASE_URL}/api/image/companies/upload`, formData, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                });
                isFile = file?.data?.data;
                setPreview("");
            }

        } else if (preview) {
            const file = await axios.post(`${BASE_URL}/api/image/companies/upload`, formData, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            });

            isFile = file?.data?.data;
            setPreview("");
        }

        // form data
        const createData = {
            name: data?.name,
            description: data?.description,
            logo: isFile ?? image,
        }

        
        if (updateData?.id) { // if update
            const result = await updateCompany({
                variables: {
                    ...createData,
                    id: updateData?.id
                }
            })
            if (result?.data?.updateCompany?.result_code === "000") {
                Swal.fire('Success!', 'Company has been successfully updated!', 'success');
                history.push("/company/list");
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            }
        } else { // else create
            const result = await createCompany({
                variables: createData
            })
            if (result?.data?.createCompany?.result_code === "000") {
                Swal.fire('Success!', 'Company has been successfully created!', 'success');
                history.push("/company/list");
            } else {
                let res = await axios.post(
                    `${BASE_URL}/api/image/delete`,
                    { oldImage: image },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                console.log(res);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            }
        }
    }
    const handleChange = async (e) => {
        e.preventDefault();
        const files = [...e.target.files];
        setPreview(files[0]);
    };
    return (
        <Col md={12}>
            <Col md={12}>
                <h3 className="page-title">{updateData?.id ? "Update" : "New"} Company</h3>
            </Col>
            <Card>
                <CardBody>
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>

                        <Col md={6} className="mx-auto">
                            <Row>
                                <Col md={12}>
                                    <div className="form__form-group">
                                        <div className="input__container">
                                            {preview ? (
                                                <img
                                                    src={URL.createObjectURL(preview)}
                                                    className="image-box"
                                                    style={{ width: "100px" }}
                                                    alt=""
                                                />
                                            ) : image ? (
                                                <img
                                                    src={image}
                                                    style={{ width: "100px" }}
                                                    className="image-box"
                                                    alt=""
                                                />
                                            ) : (
                                                <img
                                                    src={images}
                                                    style={{ width: "100px" }}
                                                    className="image-box"
                                                    alt=""
                                                />
                                            )}
                                            <input
                                                name="images"
                                                id="images"
                                                className="input--box"
                                                type="file"
                                                {...register("images")}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div style={{ width: "200px", margin: "10px  auto 0 auto" }}>
                                            {errors?.images && (
                                                <span style={{ color: "red" }}>image is required</span>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="form__form-group">
                                        <label className="form__form-group-label">Name</label>
                                        <div className="form__form-group-field">
                                            <FormField
                                                name="name"
                                                component="input"
                                                control={control}
                                                rules={{ required: "This is required field" }}
                                                type="text"
                                                defaultValue={updateData?.name}
                                                placeholder={`Enter company name`}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="form__form-group">
                                        <label className="form__form-group-label">Description</label>
                                        <div className="form__form-group-field">
                                            <FormField
                                                name="description"
                                                component="textarea"
                                                control={control}
                                                type="text"
                                                defaultValue={updateData?.description}
                                                placeholder={`Enter company description`}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                        <ButtonToolbar className="form__button-toolbar">
                                            <Button type="button" onClick={() => history.push("/company/list")}> Cancel </Button>
                                            {createFeeback?.loading || updateFeedback?.loading
                                                ? <Button className="btn btn-primary ml-1">
                                                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                                    {updateData?.id ? "Updating..." : "Creating..."}
                                                </Button>
                                                : <Button color="primary" type="submit">{updateData?.id ? "Update" : `Create`}</Button>}
                                        </ButtonToolbar>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </form>
                </CardBody>
            </Card >
        </Col >
    );
}

export default NewCompany;