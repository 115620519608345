/* eslint-disable react/jsx-indent */
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Attendance } from "../../../Attendance";
import { ProposalForm, ProposalList, Chat } from "../../../Customer";
import { decryption } from "../../../HelperFunctions";
import { History, LeaveForm, Mail, Reports, Settings } from "../../../Leave";


export default () => {
  const permission = JSON.parse(localStorage?.getItem("permissions"));


  return (
    <Switch>
      <Route path="/project/new" component={ProposalForm} />
      <Route exact path="/project" component={ProposalList} />
      <Route path="/project/list" component={ProposalList} />
      <Route path="/project/discussion" component={Chat} />
    </Switch>
  );
};
