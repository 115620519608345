import { renderMaskedField } from "@/shared/components/form/FormField";
import PasswordField from "@/shared/components/form/Password";
import { SelectField } from "@/shared/components/form/Select";
import { SelectFieldDisabled } from "@/shared/components/form/DisabledSelect";
import DatePicker from "react-datepicker";
import images from "@/shared/img/uploader/placeholder.png";
import { useMutation, useQuery } from "@apollo/client";
import axios from "axios";
import { default as React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastify";
import { Button, ButtonToolbar, Card, CardBody, Col, Row } from "reactstrap";
import {
  CREATE_COMPANY_COLLABORATION,
  CREATE_USER,
  CREATE_USER_ADDITIONAL_INFO,
  UPDATE_USER,
  UPDATE_USER_ADDITIONAL_INFO,
} from "../../GraphQL/Mutation";
import {
  GET_ALL_DESIGNATION,
  GET_ALL_DEPARTMENT,
  EIXST_RFID,
  GET_ALL_COMPANIES,
} from "../../GraphQL/Queries";
import { userAction } from "../../redux/actions/userAction";
import BASE_URL from "../../utils/api/baseUrl";
import { FormField } from "./FormField";
import { successMessage, warningMessage } from "../ReactToastify";
import jwt from "jsonwebtoken";
import { decryption } from "../HelperFunctions";
const CreateUser = ({ isHorizontal, isAboveError }) => {
  const history = useHistory();
  const updateUserInfo = history?.location?.state?.userInfo;
  const [companyCount, setCompanyCount] = useState(
    updateUserInfo
      ? [
          updateUserInfo?.companyCollaborationInfo?.map((item) => ({
            company: item?.company_id,
            office_id: item?.office_id,
          })),
        ]
      : [{ company: "", office_id: "", isCompany: false, isOffice_id: false }]
  );
  const [allCompanies, setAllCompanies] = useState([]);
  const LoadAllDesignation = useQuery(GET_ALL_DESIGNATION);
  const LoadAllDepartment = useQuery(GET_ALL_DEPARTMENT);
  const LoadAllCompanies = useQuery(GET_ALL_COMPANIES);
  const token = localStorage?.getItem("token");
  const roles = decryption(JSON.parse(localStorage?.getItem("roles")));
  let userID;
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    userID = decoded?.id;
  });

  // set all companies to setAllCompanies state
  useEffect(() => {
    setAllCompanies(
      LoadAllCompanies?.data?.getAllCompanies?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }))
    );
  }, [LoadAllCompanies?.data?.getAllCompanies]);

  const [joiningDate, setJoiningDate] = useState(
    updateUserInfo?.id
      ? new Date(updateUserInfo?.additionalInfo?.joining_date)
      : new Date()
  );
  const [image, setImage] = useState(updateUserInfo?.images);
  const [preview, setPreview] = useState("");
  const [createUser, createUserFeedback] = useMutation(CREATE_USER);
  const [createCompanyCollaboration, createCompanyCollaborationFeedback] =
    useMutation(CREATE_COMPANY_COLLABORATION);
  const [updateUser, updateUserFeedback] = useMutation(UPDATE_USER);
  const [updateUserAdditionalInfo, updateUserAdditionalInfoFeedback] =
    useMutation(UPDATE_USER_ADDITIONAL_INFO);
  const [createUserAdditionalInfo, createUserAdditionalInfoFeedback] =
    useMutation(CREATE_USER_ADDITIONAL_INFO);
  const dispatch = useDispatch();
  // for update
  let defaultDivision = JSON.parse(localStorage?.getItem("division"))?.find(
    (division) => division?.id == updateUserInfo?.division
  );
  let defaultDistrict = JSON.parse(localStorage?.getItem("district"))?.find(
    (district) =>
      district?.id == updateUserInfo?.district &&
      defaultDivision?.id == district?.division_id
  );

  let defaultUpazilla = JSON.parse(localStorage?.getItem("upazilla"))?.find(
    (upazilla) => upazilla?.id == updateUserInfo?.upazilla
  );
  let defaultUnion = JSON.parse(localStorage?.getItem("union"))?.find(
    (union) => union?.id == updateUserInfo?.union
  );
  const {
    handleSubmit,
    reset,
    watch,
    register,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      division: defaultDivision?.id
        ? {
            value: defaultDivision?.id,
            label: defaultDivision?.name,
          }
        : "",
      district: defaultDistrict?.id
        ? {
            value: defaultDistrict?.id,
            label: defaultDistrict?.name,
          }
        : "",
      union: defaultUnion?.id
        ? {
            value: defaultUnion?.id,
            label: defaultUnion?.name,
          }
        : "",
      upazilla: defaultUpazilla?.id
        ? {
            value: defaultUpazilla?.id,
            label: defaultUpazilla?.name,
          }
        : "",
      email: updateUserInfo?.email,
      images: updateUserInfo?.images,
      name: updateUserInfo?.name,
      mobile: updateUserInfo?.phone,
      username: updateUserInfo?.username,
      village: updateUserInfo?.village,
      description: updateUserInfo?.additionalInfo?.description,
      rfid: updateUserInfo?.additionalInfo?.rfid,
    },
  });
  const existRfid = useQuery(EIXST_RFID);

  const onSubmit = async (data) => {
    // form validation
    // const isCompanyValid = companyCount?.map(item => {
    //   if (item?.company == '') {
    //     const list = [...companyCount];
    //     list[index].isCompany = true;
    //     setCompanyCount(list)
    //   }
    //   if (item?.office_id == '') {
    //     const list = [...companyCount];
    //     list[index].isOffice_id = true;
    //     setCompanyCount(list)
    //   }
    // })

    const formData = new FormData();

    formData.append("images", preview);
    let isFile;
    if (preview && image) {
      let res = await axios.post(
        `${BASE_URL}/api/image/delete`,
        { oldImage: image },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res?.data) {
        const file = await axios.post(
          `${BASE_URL}/api/image/users/upload`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );
        isFile = file?.data?.data;
        setPreview("");
      }
    } else if (preview) {
      const file = await axios.post(
        `${BASE_URL}/api/image/users/upload`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );

      isFile = file?.data?.data;
      setPreview("");
    }
    const resultexistRfid = await existRfid.refetch({
      rfid: data?.rfid,
    });
    const createData = {
      district: Number(data?.district?.value),
      division: Number(data?.division?.value),
      upazilla: Number(data?.upazilla?.value),
      union: Number(data?.union.value),
      village: data?.village,
      name: data?.name,
      username: data?.username,
      phone: data?.mobile,
      password: data?.password ? data?.password : updateUserInfo?.password,
      images: isFile ?? image,
      email: data?.email,
      gender: data?.gender?.value,
    };
    const additionalData = {
      rfid: data?.rfid,
      dept_id: Number(data?.dept_id?.value),
      desig_id: Number(data?.desig_id?.value),
      description: data?.description,
      joining_date: String(joiningDate),
    };

    if (updateUserInfo?.id) {
      let rfidSuccess = false;

      if (
        resultexistRfid?.data?.existRFID?.rfid ==
        updateUserInfo?.additionalInfo?.rfid
      ) {
        rfidSuccess = true;
        // successMessage("RFID no changed", 3000)
      } else {
        if (resultexistRfid?.data?.existRFID?.rfid) {
          rfidSuccess = false;
          warningMessage("RFID already taken", 3000);
        } else {
          rfidSuccess = true;
          // successMessage("RFID new", 3000)
        }
      }

      if (rfidSuccess) {
        const result = await updateUser({
          variables: { ...createData, id: Number(updateUserInfo?.id) },
        });
        const result2 = await updateUserAdditionalInfo({
          variables: { ...additionalData, user_id: Number(updateUserInfo?.id) },
        });
        if (
          result?.data?.updateUser?.result_code == "000" &&
          result2?.data?.updateAdditionalInfo?.result_code == "000"
        ) {
          successMessage("Update Successfully...", 3000);
          history.push("/user/list");
        }
        if (result?.data?.updateUser?.result_code == "002") {
          warningMessage(result?.data?.updateUser?.status, 3000);
        }
        if (result2?.data?.updateAdditionalInfo?.result_code == "002") {
          warningMessage(result2?.data?.updateAdditionalInfo?.status, 3000);
        }
      }
    } else {
      // create user
      if (resultexistRfid?.data?.existRFID?.rfid) {
        warningMessage("RFID already taken", 3000);
      }
      if (!resultexistRfid?.data?.existRFID?.rfid) {
        // user create api calling
        const createResult = await createUser({
          variables: createData,
        });

        if (createResult?.data?.createUser?.id) {
          // company collaboration create api calling
          const isCompanyCollaborationDone = companyCount?.map(async (item) => {
            const createCompanyCollaborationResult =
              await createCompanyCollaboration({
                variables: {
                  company_id: Number(item?.company),
                  office_id: item?.office_id,
                  user_id: Number(createResult?.data?.createUser?.id),
                },
              });
            return createCompanyCollaborationResult?.data
              ?.createCompanyCollaboration?.id;
          });
          Promise.all(isCompanyCollaborationDone).then(async (res) => {
            if (
              res.includes(false) ||
              res.includes(null) ||
              res.includes(undefined)
            ) {
              Swal.fire("Faild!", "System error, please try again.", "error");
            } else {
              // additional info create api calling
              const result2 = await createUserAdditionalInfo({
                variables: {
                  ...additionalData,
                  user_id: Number(createResult?.data?.createUser?.id),
                },
              });

              if (
                createResult?.data?.updateUser?.result_code == "000" ||
                result2?.data?.createUserAdditionalInfo?.result_code == "000"
              ) {
                Swal.fire(
                  "Created!",
                  "User has been successfully created.",
                  "success"
                );
                // history.push("/user/list");
              }
            }
          });
        }
      }
    }

    dispatch(userAction);
  };

  const handleChange = async (e) => {
    e.preventDefault();
    const files = [...e.target.files];
    setPreview(files[0]);
  };

  const getDivision = JSON.parse(localStorage?.getItem("division"));
  const getDistrict = JSON.parse(localStorage?.getItem("district"))?.filter(
    (data) => data?.division_id === watch("division")?.value
  );
  const getUpazilla = JSON.parse(localStorage?.getItem("upazilla"))?.filter(
    (data) => data?.district_id == watch("district")?.value
  );
  const getUnion = JSON.parse(localStorage?.getItem("union"))?.filter(
    (data) => data?.upazilla_id === watch("upazilla")?.value
  );

  return (
    <div>
      <ToastContainer />
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody>
            <h3 className="page-title">
              {defaultUnion?.id ? "Update user" : "Create new user"}
            </h3>
            <form
              className={`form ${isHorizontal && "form--horizontal"}`}
              style={{ width: "100%" }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Col md={8} className="mx-auto">
                <Row>
                  <Col md={12}>
                    <div className="form__form-group">
                      <div className="input__container">
                        {preview ? (
                          <img
                            src={URL.createObjectURL(preview)}
                            className="image-box"
                            style={{ width: "100px" }}
                            alt=""
                          />
                        ) : image ? (
                          <img
                            src={image}
                            style={{ width: "100px" }}
                            className="image-box"
                            alt=""
                          />
                        ) : (
                          <img
                            src={images}
                            style={{ width: "100px" }}
                            className="image-box"
                            alt=""
                          />
                        )}
                        <input
                          name="images"
                          id="images"
                          className="input--box"
                          type="file"
                          {...register("images")}
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        style={{ width: "200px", margin: "10px  auto 0 auto" }}
                      >
                        {errors?.images && (
                          <span style={{ color: "red" }}>
                            image is required
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Full Name</span>
                      <div className="form__form-group-field">
                        <FormField
                          name="name"
                          control={control}
                          component="input"
                          errors={errors}
                          rules={{ required: "This is required field" }}
                          isAboveError={isAboveError}
                          placeholder="Name"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Username</span>
                      <div className="form__form-group-field">
                        <FormField
                          name="username"
                          control={control}
                          component="input"
                          errors={errors}
                          rules={{ required: "This is required field" }}
                          isAboveError={isAboveError}
                          placeholder="username"
                          disabled={updateUserInfo ? true : false}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Gender</span>
                      <div className="form__form-group-field">
                        <FormField
                          name="gender"
                          control={control}
                          component={SelectField}
                          errors={errors}
                          options={[
                            {
                              value: "Male",
                              label: "Male",
                            },
                            {
                              value: "Female",
                              label: "Female",
                            },
                          ]}
                          rules={{ required: "This is required field" }}
                          defaultValue={[
                            {
                              value: "Male",
                              label: "Male",
                            },
                            {
                              value: "Female",
                              label: "Female",
                            },
                          ].find(
                            (item) => item?.value == updateUserInfo?.gender
                          )}
                          // value="dhjs"
                          isAboveError={isAboveError}
                          placeholder="Select Gender"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Division</span>
                      <div className="form__form-group-field">
                        <FormField
                          name="division"
                          control={control}
                          component={SelectField}
                          errors={errors}
                          options={getDivision?.map((data) => ({
                            value: data?.id,
                            label: data?.name,
                          }))}
                          rules={{ required: "This is required field" }}
                          isAboveError={isAboveError}
                          placeholder="Division"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">District</span>
                      <div className="form__form-group-field">
                        <FormField
                          name="district"
                          control={control}
                          component={SelectField}
                          errors={errors}
                          options={getDistrict?.map((data) => ({
                            value: data?.id,
                            label: data?.name,
                          }))}
                          rules={{ required: "This is required field" }}
                          isAboveError={isAboveError}
                          placeholder="District"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Upazilla</span>
                      <div className="form__form-group-field">
                        <FormField
                          name="upazilla"
                          control={control}
                          component={SelectField}
                          errors={errors}
                          options={getUpazilla?.map((data) => ({
                            value: data?.id,
                            label: data?.name,
                          }))}
                          rules={{ required: "This is required field" }}
                          isAboveError={isAboveError}
                          placeholder="Upazilla"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Union</span>
                      <div className="form__form-group-field">
                        <FormField
                          name="union"
                          control={control}
                          component={SelectField}
                          errors={errors}
                          options={getUnion?.map((data) => ({
                            value: data?.id,
                            label: data?.name,
                          }))}
                          rules={{ required: "This is required field" }}
                          isAboveError={isAboveError}
                          placeholder="Union"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Village</span>
                      <div className="form__form-group-field">
                        <FormField
                          name="village"
                          control={control}
                          component="input"
                          errors={errors}
                          rules={{ required: "This is required field" }}
                          isAboveError={isAboveError}
                          placeholder="Village"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Mobile Number
                      </span>
                      <div className="form__form-group-field">
                        <FormField
                          name="mobile"
                          control={control}
                          // component="input"
                          component={renderMaskedField}
                          mask={[
                            "(",
                            "+",
                            "8",
                            "8",
                            ")",
                            " ",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          errors={errors}
                          rules={{ required: "This is required field" }}
                          isAboveError={isAboveError}
                          placeholder="(+88) _____ ___ ___"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Email</span>
                      <div className="form__form-group-field">
                        <FormField
                          name="email"
                          control={control}
                          component="input"
                          type="email"
                          errors={errors}
                          rules={{ required: "This is required field" }}
                          isAboveError={isAboveError}
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </Col>
                  {!updateUserInfo?.id &&<Col md={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Password</span>
                      <div className="form__form-group-field password">
                        {updateUserInfo?.id ? (
                          <FormField
                            name="password"
                            control={control}
                            component={PasswordField}
                            errors={errors}
                            isAboveError={isAboveError}
                            placeholder="password"
                          />
                        ) : (
                          <FormField
                            name="password"
                            control={control}
                            component={PasswordField}
                            errors={errors}
                            rules={{ required: "This is required field" }}
                            isAboveError={isAboveError}
                            placeholder="password"
                          />
                        )}
                      </div>
                    </div>
                  </Col>}

                  <Col md={12}>
                    <h5>
                      <b>Additional information</b>
                    </h5>
                    <hr />
                  </Col>

                  {/* for user additional information table */}

                  <Col md={12}>
                    {companyCount?.map((item, index) => (
                      <Row key={index}>
                        <Col md={6}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              Company
                            </span>
                            <div className="form__form-group-field">
                              {roles?.includes("System Admin") ? (
                                <FormField
                                  disabled={true}
                                  name={`company_${item}`}
                                  control={control}
                                  component={
                                    userID == updateUserInfo?.id
                                      ? SelectFieldDisabled
                                      : SelectField
                                  }
                                  options={allCompanies}
                                  defaultValue={allCompanies?.find(
                                    (item) =>
                                      item?.value ==
                                      updateUserInfo?.additionalInfo?.dept_id
                                  )}
                                  placeholder="Select company"
                                  onChange={(e) => {
                                    const list = [...companyCount];
                                    list[index].company = e.value;
                                    setCompanyCount(list);
                                  }}
                                />
                              ) : (
                                <FormField
                                  name="company"
                                  control={control}
                                  component={
                                    userID == updateUserInfo?.id
                                      ? SelectFieldDisabled
                                      : SelectField
                                  }
                                  options={allCompanies}
                                  defaultValue={allCompanies?.find(
                                    (item) =>
                                      item?.value ==
                                      updateUserInfo?.additionalInfo?.dept_id
                                  )}
                                  placeholder="Select department"
                                />
                              )}
                            </div>
                            {companyCount[index]?.required ? (
                              <span
                                class="form__form-group-error"
                                style={{ color: "red", fontSize: "12px" }}
                              >
                                This is required field
                              </span>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="form__form-group">
                            <label className="form__form-group-label">
                              Office ID
                            </label>
                            <div className="form__form-group-field">
                              <FormField
                                name={`office_id_${item}`}
                                component="input"
                                control={control}
                                value={item?.office_id}
                                type="text"
                                placeholder={`Enter office id`}
                                disabled={
                                  userID == updateUserInfo?.id ? true : false
                                }
                                onChange={(e) => {
                                  const list = [...companyCount];
                                  list[index].office_id = e.target.value;
                                  setCompanyCount(list);
                                }}
                              />
                            </div>
                            {companyCount[index]?.required ? (
                              <span
                                class="form__form-group-error"
                                style={{ color: "red", fontSize: "12px" }}
                              >
                                This is required field
                              </span>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="form__form-group">
                            <label className="form__form-group-label"></label>
                            <div className="form__form-group-field">
                              {companyCount[companyCount?.length - 1] ==
                              item ? (
                                index + 1 < allCompanies?.length ? (
                                  <span
                                    onClick={() =>
                                      setCompanyCount((prevState) => [
                                        ...prevState,
                                        { company: "", office_id: "" },
                                      ])
                                    }
                                    style={{ cursor: "pointer" }}
                                    class="lnr lnr-plus-circle bg-success px-2 py-2 rounded text-white mt-1"
                                  ></span>
                                ) : null
                              ) : (
                                <span
                                  onClick={() =>
                                    setCompanyCount(
                                      companyCount?.filter(
                                        (removeItem) => removeItem != item
                                      )
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                  class="lnr lnr-circle-minus bg-danger px-2 py-2 rounded text-white mt-1"
                                ></span>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <label className="form__form-group-label">
                        Joiing Date
                      </label>
                      <div className="form__form-group-field joiningCalender">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={joiningDate}
                          placeholderText="dd/mm/yyyy"
                          onChange={(date) => {
                            setJoiningDate(date);
                          }}
                          showWeekNumbers
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Department</span>
                      <div className="form__form-group-field">
                        {roles?.includes("System Admin") ? (
                          <FormField
                            disabled={true}
                            name="dept_id"
                            control={control}
                            component={
                              userID == updateUserInfo?.id
                                ? SelectFieldDisabled
                                : SelectField
                            }
                            options={LoadAllDepartment?.data?.getAllDepartments?.map(
                              (data) => ({
                                label: data?.name,
                                value: data?.id,
                              })
                            )}
                            defaultValue={LoadAllDepartment?.data?.getAllDepartments
                              ?.map((data) => ({
                                label: data?.name,
                                value: data?.id,
                              }))
                              ?.find(
                                (item) =>
                                  item?.value ==
                                  updateUserInfo?.additionalInfo?.dept_id
                              )}
                            placeholder="Select department"
                          />
                        ) : (
                          <FormField
                            name="dept_id"
                            control={control}
                            component={
                              userID == updateUserInfo?.id
                                ? SelectFieldDisabled
                                : SelectField
                            }
                            options={LoadAllDepartment?.data?.getAllDepartments
                              ?.filter((dept) => dept.user_id == userID)
                              ?.map((data) => ({
                                label: data?.name,
                                value: data?.id,
                              }))}
                            defaultValue={LoadAllDepartment?.data?.getAllDepartments
                              ?.map((data) => ({
                                label: data?.name,
                                value: data?.id,
                              }))
                              ?.find(
                                (item) =>
                                  item?.value ==
                                  updateUserInfo?.additionalInfo?.dept_id
                              )}
                            placeholder="Select department"
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Designation
                      </span>
                      <div className="form__form-group-field">
                        <FormField
                          name="desig_id"
                          control={control}
                          component={
                            userID == updateUserInfo?.id
                              ? SelectFieldDisabled
                              : SelectField
                          }
                          disabled={userID ? true : false}
                          options={LoadAllDesignation?.data?.getAllDesignation?.map(
                            (data) => ({
                              label: data?.name,
                              value: data?.id,
                            })
                          )}
                          defaultValue={LoadAllDesignation?.data?.getAllDesignation
                            ?.map((data) => ({
                              label: data?.name,
                              value: data?.id,
                            }))
                            ?.find(
                              (item) =>
                                item?.value ==
                                updateUserInfo?.additionalInfo?.desig_id
                            )}
                          placeholder="Select designation"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form__form-group">
                      <label className="form__form-group-label">RFID</label>
                      <div className="form__form-group-field">
                        <FormField
                          name="rfid"
                          component="input"
                          control={control}
                          type="text"
                          placeholder={`Enter RFID`}
                          disabled={userID == updateUserInfo?.id ? true : false}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form__form-group">
                      <label className="form__form-group-label">
                        Description
                      </label>
                      <div className="form__form-group-field">
                        <FormField
                          name="description"
                          component="textarea"
                          control={control}
                          type="text"
                          placeholder={`Enter description`}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <ButtonToolbar className="form__button-toolbar">
                        <Button
                          onClick={() => history.push("/user/list")}
                          type="button"
                        >
                          Cancel
                        </Button>

                        {createUserFeedback?.loading ||
                        createCompanyCollaborationFeedback?.loading ||
                        updateUserFeedback?.loading ||
                        updateUserAdditionalInfoFeedback?.loading ||
                        createUserAdditionalInfoFeedback?.loading ? (
                          <Button className="btn btn-primary ml-1">
                            <span
                              className="spinner-border spinner-border-sm mr-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            {updateUserInfo?.id ? "Updating..." : "Creating..."}
                          </Button>
                        ) : (
                          <Button color="primary" type="submit">
                            {updateUserInfo?.id ? "Update" : `Create`}
                          </Button>
                        )}
                      </ButtonToolbar>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export default CreateUser;
