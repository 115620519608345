/* eslint-disable react/jsx-indent */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import EmailConfirmation from "../../../Account/EmailConfimation/index";
import Profile from "../../../Account/Profile/index";

export default () => {
  const token = localStorage?.getItem("token");
  const permission = JSON.parse(localStorage?.getItem("permissions"));

  return (
    <Switch>
      <Route path="/account/profile" component={Profile} />
      <Route
        exact
        path="/account/major_problems"
        render={() =>
          token !== null ? <MajorProblems /> : <Redirect to="/log_in" />
        }
      />
      <Route
        exact
        path="/account/literacy_community"
        render={() =>
          token !== null ? <LiteracyAndCommunity /> : <Redirect to="/log_in" />
        }
      />
      <Route path="/account/profile/:id" component={Profile} />
      <Route path="/account/email_confirmation" component={EmailConfirmation} />
    </Switch>
  );
};
