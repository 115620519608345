import { Card, CardBody, Col, Table, Button, Container, Row } from "reactstrap";
import TabPlusIcon from "mdi-react/TabPlusIcon";
import DeptListTable from "./DeptListTable";
import { useHistory } from "react-router";
import { decryption } from "../../../HelperFunctions";


const Department = () => {
    const history = useHistory();
    // permission
    const permissions = decryption(JSON.parse(localStorage?.getItem("permissions")));
    return (
        <Col md={12}>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={6}>
                            <h3 className="page-title">Department list</h3>
                        </Col>
                        <Col md={6}>
                            {permissions?.includes("create_department") && <Button
                                onClick={() => history.push("/department/new")}
                                className="float-right"
                                color="primary"
                            >
                                <TabPlusIcon />
                                {/* <Link to="/make/request">Make a request</Link> */}
                                New Department
                            </Button>}
                        </Col>
                    </Row>
                    <Row>
                        <DeptListTable />
                    </Row>
                </CardBody>
            </Card>
        </Col>
    );
}

export default Department;