import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { NavLink } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';
import PasswordField from '@/shared/components/form/Password';
import renderCheckBoxField from '../../../shared/components/form/CheckBox'
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { CUSTOMER_LOGIN } from '../../../GraphQL/Mutation';
import Swal from 'sweetalert2';

const CustomerLoginForm = () => {
  const history = useHistory();
  const [token, setToken] = useState('');
  const [customerLogin, { data, loading }] = useMutation(CUSTOMER_LOGIN);

  const onSubmit = async (values) => {
    const formData = {
      email: values.email,
      password: values.password,
    }
    const result = await customerLogin({
      variables: formData
    })
    if(result?.data?.customerLogin?.result_code == "002"){
      Swal.fire({
        icon: 'error',
        text: `Incorrect username or password.`,
      })
    }
    setToken(result.data?.customerLogin?.token);
  }

  useEffect(() => {
    if (token?.length) {
      localStorage.setItem("token", token);
      history.push("/project/list");
    }
  }, [token?.length]);

  return (
    <>
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">
            <span className="account__logo">
              Office Management
            </span>
          </h3>
        </div>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form className="form login-form" onSubmit={handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">Email</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="email"
                    component="input"
                    type="email"
                    placeholder="Email"
                    className="input-without-border-radius"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Password</span>
                <div className="form__form-group-field">
                  <Field
                    name="password"
                    component={PasswordField}
                    placeholder="Password"
                    className="input-without-border-radius"
                    keyIcon
                  />
                  <div className="account__forgot-password">
                    <NavLink to="/reset_password_photo">Forgot a password?</NavLink>
                  </div>
                </div>
              </div>
              <div className="form__form-group">
                <div className="form__form-group form__form-group-field">
                  <Field
                    name="remember_me"
                    component={renderCheckBoxField}
                    label="Remember me"
                    type="checkbox"
                  />
                </div>
              </div>
              <div className="account__btns flex-nowrap">
                {/* <NavLink className="btn btn-primary account__btn" to="/customer_register">Create Account</NavLink> */}
                <Button className="btn btn-outline-primary account__btn mr-1" onClick={() => history.push("/customer_register")}>Create Account</Button>
                {/* <Button className="btn btn-primary account__btn ml-1">Sign In</Button> */}
                {
                  loading
                    ? <Button className="btn btn-primary account__btn ml-1">
                      <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                      Loging...
                    </Button>
                    : <Button className="btn btn-primary account__btn ml-1">Sign In</Button>
                }
              </div>
            </form>
          )}
        </Form>
      </div>
    </>
  );
};

CustomerLoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  errorMsg: PropTypes.string,
  fieldUser: PropTypes.string,
  typeFieldUser: PropTypes.string,
  form: PropTypes.string.isRequired,
};

CustomerLoginForm.defaultProps = {
  errorMessage: '',
  errorMsg: '',
  fieldUser: 'Username',
  typeFieldUser: 'text',
};

export default connect(state => ({
  errorMsg: state.user.error,
}))(CustomerLoginForm);
