/* eslint-disable react/jsx-indent */
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { decryption } from "../../../HelperFunctions";
import CreateUser from "../../../UserManagement/CreateUser";
import Permission from "../../../UserManagement/Permission";
import Roles from "../../../UserManagement/Roles";
import UserDesignation from "../../../UserManagement/UserDesignation";
import UserDesignationCreate from "../../../UserManagement/UserDesignationCreate";
import UserList from "../../../UserManagement/UsersList";

export default () => {
  const permission = JSON.parse(localStorage?.getItem("permissions"));

  return (
    <Switch>
      <Route path="/user/designation" component={UserDesignation} />
      <Route path="/user/new-designation" component={UserDesignationCreate} />
      <Route
        path="/user/roles"
        render={() =>
          decryption(permission)?.includes("view_roles") ? <Roles /> : <Redirect to="/404" />
        }
      />
      <Route
        path="/user/permission"
        render={() =>
          decryption(permission)?.includes("view_permission") ? <Permission /> : <Redirect to="/404" />
        }
      />
      <Route
        path="/user/list"
        render={() =>
          decryption(permission)?.includes("view_employee") ? <UserList /> : <Redirect to="/404" />
        }
      />
      <Route
        path="/user/create"
        render={() =>
          decryption(permission)?.includes("create_employee") || decryption(permission)?.includes("update_employee") ? <CreateUser /> : <Redirect to="/404" />
        }
      />
      <Route
        path="/user/designation"
        render={() =>
          decryption(permission)?.includes("view_designation") ? <UserDesignation /> : <Redirect to="/404" />
        }
      />
      <Route
        path="/user/new-designation"
        render={() =>
          decryption(permission)?.includes("create_designation") ? <UserDesignationCreate /> : <Redirect to="/404" />
        }
      />
    </Switch>
  );
};
