import { useQuery } from "@apollo/client";
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import { Col, Container, Row } from "reactstrap";
import { LOAD_FARMER } from "../../../GraphQL/Queries";
import ProfileMain from "./components/ProfileMain";
import ProfileTabs from "./components/ProfileTabs";
const Calendar = () => {
  const location = useLocation();
  const id = Number(location.pathname.split("/")[3]);
  const farmingAdd = useSelector((state) => state.farmer.farming);
  const [farmer, setFarmer] = useState({});
  const { error, loading, data, refetch } = useQuery(LOAD_FARMER, {
    variables: {
      id,
    },
  });

  useEffect(() => {
    setFarmer(data?.getGenericInfo);
    refetch();
  }, [id, data?.getGenericInfo, farmingAdd]);
  //add spinner fro loading
  let [color, setColor] = useState("rgb(54, 215, 183)");
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  if (loading) {
    return (
      <div className="loader-container">
        <MoonLoader
          color={color}
          loading={loading}
          css={override}
          size={80}
          speedMultiplier={0.6}
        />
      </div>
    );
  }
  return (
    <Container>
      <div className="profile">
        <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>{farmer && <ProfileMain farmer={farmer} />}</Row>
          </Col>
          {farmer && <ProfileTabs farmer={farmer} />}
        </Row>
      </div>
    </Container>
  );
};

export default Calendar;
