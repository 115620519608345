import { Card, CardBody, Col, Table, Button, Container, Row, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import TabPlusIcon from "mdi-react/TabPlusIcon";
import HistoryTable from './HistoryTable';
import { useHistory } from "react-router";
import { useMutation, useQuery, NetworkStatus } from "@apollo/client";
import { GET_ALL_HOLYDAYS, GET_ALL_HOLYDAYS_BY_YEAR } from '../../../GraphQL/Queries';
import { useEffect, useState } from "react";
import { decryption } from "../../HelperFunctions";
import HolydayTable from "./HolydayTable";
import moment from "moment";
import { removeDuplicateYear } from "../../../utils/helpers";
import { DELETE_HOLYDAY } from "../../../GraphQL/Mutation";
import Swal from "sweetalert2";


const Holydays = () => {
    const history = useHistory();

    // state
    const [holydays, setHolydays] = useState([]);
    const [filterYear, setFilterYear] = useState(Number(moment(new Date()).format('YYYY')));
    const [deleteToggle, setDeleteToggle] = useState(false);

    // variables
    const permissions = decryption(JSON.parse(localStorage?.getItem("permissions")));


    // queries
    const getAllHolydays = useQuery(GET_ALL_HOLYDAYS);

    const allYears = []; // empty array for all holydays years
    getAllHolydays?.data?.getAllHolydays?.map((year) => allYears.push(year?.year)); // all holydays year push into allYears array

    const getAllHolydaysByYear = useQuery(GET_ALL_HOLYDAYS_BY_YEAR, {
        skip: !filterYear,
        variables: {
            year: filterYear
        }
    })

    // mutation
    const [deleteHolyday, deleteHolydayStatus] = useMutation(DELETE_HOLYDAY);


    // update holydays state
    useEffect(() => {
        getAllHolydaysByYear?.refetch({
            year: filterYear
        });
        setHolydays(getAllHolydaysByYear?.data?.getAllHolydaysByYear);
        getAllHolydays.refetch();
    }, [getAllHolydaysByYear?.data?.getAllHolydaysByYear, filterYear, deleteToggle]);

    const filterAllHandler = () => {
        getAllHolydays.refetch();
        setHolydays(getAllHolydays?.data?.getAllHolydays);
    }

    if (deleteHolydayStatus?.loading) {
        Swal.showLoading();
    }

    // delete holyday
    const deleteHandler = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            },

            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {

                const result = await deleteHolyday({
                    variables: { id }
                })
                if (result?.data?.deleteHolyday?.result_code == "000") {
                    setDeleteToggle(prevState => !prevState);
                    Swal.fire(
                        'Deleted!',
                        'Holyday has been deleted.',
                        'success'
                    )
                }
                if (result?.data?.deleteHolyday?.result_code == "002") {
                    Swal.fire(result?.data?.deleteHolyday?.status, '', 'error');
                }

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Holyday is safe :)',
                    'error'
                )
            }
        })



    }



    return (
        <Col md={12}>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={6}>
                            <h3 className="page-title">Holydays</h3>
                        </Col>
                        <Col md={6}>
                            <div className="float-right flex align-items-center">
                                <UncontrolledDropdown>
                                    <DropdownToggle className="icon icon--right" outline size="sm">
                                        <p>Filter By <MenuDownIcon /></p>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown__menu">
                                        <DropdownItem onClick={() => filterAllHandler()}>All</DropdownItem>
                                        {
                                            removeDuplicateYear(allYears)?.map((item, index) => (
                                                <DropdownItem onClick={() => setFilterYear(Number(item))}>{item}</DropdownItem>
                                            ))
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                {permissions?.includes("create_holyday") && <Button
                                    onClick={() => history.push("/leave/new-holyday")}
                                    className=""
                                    color="primary"
                                >
                                    <TabPlusIcon />
                                    {/* <Link to="/make/request">Make a request</Link> */}
                                    Add Holyday
                                </Button>}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <HolydayTable
                            loading={getAllHolydaysByYear?.loading}
                            holydays={holydays}
                            deleteHandler={deleteHandler}
                        />
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default Holydays;