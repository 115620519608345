import { Redirect, Route, Switch } from "react-router-dom";
import { AgentProfile, AgentProfileUpdate } from "../../../AgentProfile";

export default () => {
  const token = localStorage?.getItem("token");

  return (
    <Switch>
      <Route path="/profile/info" component={AgentProfile} />
      <Route path="/profile/update" component={AgentProfileUpdate} />
      {/* <Route path="/profile/new-request" component={RequestForm} />
      <Route path="/profile/id" component={RequestDetails} /> */}
      <Route
        exact
        path="/account/generic_info"
        render={() =>
          token !== null ? <GenericInfo /> : <Redirect to="/log_in" />
        }
      />
    </Switch>
  );
};
