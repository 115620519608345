import React, { useState, useEffect } from "react";
import { w3cwebsocket as WebSocket } from "websocket";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Col, Row } from "reactstrap";
import SwitchButton from "./components/SwitchButton";
import Fan from "./components/Fan";
import { toast } from "react-toastify";
import DeviceId from "./components/DeviceId";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const socketApi = "wss://socket-elab.onrender.com";

const SwitchBoard = () => {
  const [deviceId, setDeviceId] = useState(null);
  const [deviceIdEdit, setDeviceIdEdit] = useState(false);
  const localDevice = localStorage.getItem("localDevice");
  const iotLights = localStorage.getItem("iotLights");
  const iotFan = localStorage.getItem("iotFan");
  const [socket, setSocket] = useState(null);
  const localIp = localStorage.getItem("localIp");
  const [ip, setIp] = useState(localIp ?? null);
  const [socketIp, setSocketIp] = useState(socketApi);
  const [ipAddress, setIpAddress] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [socketResponse, setSocketResponse] = useState(null);
  const [speed, setSpeed] = useState(0);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isOn, setIsOn] = useState([
    {
      switchNo: 1,
      state: false,
    },
    {
      switchNo: 2,
      state: false,
    },
  ]);
  const [onLoading, setOnLoading] = useState({ loading: false, switch: null });

  const increaseSpeed = () => {
    const sumSpeed = speed + 10;
    // setSpeed(sumSpeed);
    if (sumSpeed > 100) {
      toast.warning("Maximum fan speed 100");
      return false;
    }
    const sendMessage = {
      type: "command",
      clint_no: 1,
      device_id: deviceId,
      load_no: 3,
      load3_status: sumSpeed,
    };

    setOnLoading({ loading: true, switch: 3 });
    if (!socket || socket.readyState !== WebSocket.OPEN) {
      const newSocket = new WebSocket(socketApi);
      newSocket.onopen = () => {
        newSocket.send(JSON.stringify(sendMessage));
        setOnLoading({ loading: false, switch: 3 });
      };
      newSocket.onmessage = (message) => {
        handleWebSocketMessage(message);
      };
      newSocket.onclose = () => {
        setSocket(null);
      };
      setSocket(newSocket);
    } else {
      socket.send(JSON.stringify(sendMessage));
      setOnLoading({ loading: false, switch: 3 });
    }
  };

  const decreaseSpeed = () => {
    const subSpeed = Math.max(0, speed - 10);

    // setSpeed(subSpeed);
    const sendMessage = {
      type: "command",
      clint_no: 1,
      device_id: deviceId,
      load_no: 3,
      load3_status: subSpeed,
    };
    // Set loading to true when sending a message
    setOnLoading({ loading: true, switch: 3 });
    if (!socket || socket.readyState !== WebSocket.OPEN) {
      const newSocket = new WebSocket(socketApi);
      newSocket.onopen = () => {
        newSocket.send(JSON.stringify(sendMessage));
        setOnLoading({ loading: false, switch: 3 });
      };
      newSocket.onmessage = (message) => {
        handleWebSocketMessage(message);
      };
      newSocket.onclose = () => {
        setSocket(null);
      };
      setSocket(newSocket);
    } else {
      socket.send(JSON.stringify(sendMessage));
      setOnLoading({ loading: false, switch: 3 });
    }
  };

  const switchState = (array, switchNo) => {
    return array?.find((item) => item?.switchNo == switchNo)?.state;
  };

  const handleWebSocketMessage = (event) => {
    const blob = event.data;

    const fileReader = new FileReader();

    fileReader.onload = () => {
      const decodedData = fileReader.result;
      setSocketResponse(decodedData);
      // Do something with the decoded data
    };
    fileReader.readAsText(blob);
  };

  useEffect(() => {
    if (ip) {
      const socket = new WebSocket(socketApi);

      socket.onmessage = (message) => {
        handleWebSocketMessage(message);
      };

      return () => {
        socket.close();
      };
    }
  }, []);

  const handleClick = (switchNo) => {
    const newIsOn = !switchState(isOn, switchNo);
    const sendMessage = {
      Type: "command",
      clint_no: 1,
      device_id: deviceId,
      load_no: switchNo,
      [`load${switchNo}_status`]: newIsOn ? 1 : 0,
    };

    // Set loading to true when sending a message
    setOnLoading({ loading: true, switch: switchNo });

    if (!socket || socket.readyState !== WebSocket.OPEN) {
      // Handle WebSocket connection logic here

      const newSocket = new WebSocket(socketApi);
      newSocket.onopen = () => {
        newSocket.send(JSON.stringify(sendMessage));
        setOnLoading({ loading: false, switch: switchNo });
      };
      newSocket.onmessage = (message) => {
        handleWebSocketMessage(message);
      };
      newSocket.onclose = () => {
        setSocket(null);
      };
      setSocket(newSocket);
    } else {
      socket.send(JSON.stringify(sendMessage));
      setOnLoading({ loading: false, switch: switchNo });
    }
  };

  console.log({ onLoading });

  const handleIp = () => {
    const newSocket = new WebSocket(socketApi);

    setIsConnecting(true);
    setIsConnected(false);
    setIsError(false);

    newSocket.addEventListener("open", () => {
      localStorage.setItem("localIp", socketIp);
      setIp(socketIp);
      setIpAddress(socketIp);
      setIsConnecting(false);
      setIsConnected(true);
      setIsEditable(false);
      newSocket.close();
    });

    newSocket.addEventListener("error", () => {
      setIsConnecting(false);
      setIsConnected(false);
      setIsError(true);
    });
  };

  useEffect(() => {
    if (localIp) {
      setSocketIp(localIp);
      setIpAddress(localIp);
    }
  }, [localIp]);

  useEffect(() => {
    // LED_Satus
    if (socketResponse) {
      const parseSocketResponse = JSON.parse(socketResponse);
      // setIsOn(parseSocketResponse.SW_Status);
      const switchStates = isOn?.map((item) => {
        if (item?.switchNo == parseSocketResponse.load_no) {
          return {
            ...item,
            state:
              parseSocketResponse[`load${item?.switchNo}_status`] == 1
                ? true
                : false,
          };
        }
        return item;
      });
      localStorage.setItem("iotLights", JSON.stringify(switchStates));
      setIsOn(switchStates);

      // ** fan speed condition
      if (parseSocketResponse.load_no == 3) {
        setSpeed(parseSocketResponse.load3_status);
        localStorage.setItem(
          "iotFan",
          JSON.stringify(parseSocketResponse.load3_status)
        );
      }
    }
  }, [socketResponse]);

  // ** webSocket connection check
  useEffect(() => {
    if (socket) {
      socket.addEventListener("open", () => {
        setIsConnecting(false);
        setIsConnected(true);
        socket.close();
      });

      // socket.addEventListener("error", () => {
      //   setIsConnecting(false);
      //   setIsConnected(false);
      //   setIsError(true);
      // });
    } else {
      if (ip) {
        const newSocket = new WebSocket(socketApi);
        newSocket.addEventListener("open", () => {
          setIsConnecting(false);
          setIsConnected(true);
          newSocket.close();
        });
      }

      // newSocket.addEventListener("error", () => {
      //   setIsConnecting(false);
      //   setIsConnected(false);
      //   setIsError(true);
      // });
    }
  }, [socket, ip]);

  const messageColor = (text, color, strong = false) => {
    let message = text;
    if (strong) {
      message = <strong>{text}</strong>;
    }
    return <span className={`text-${color}`}>{message}</span>;
  };

  useEffect(() => {
    if (iotLights) {
      setIsOn(JSON.parse(iotLights));
    }
  }, [iotLights]);

  useEffect(() => {
    if (iotFan) {
      setSpeed(Number(iotFan));
    }
  }, [iotFan]);

  useEffect(() => {
    if (localDevice) {
      setDeviceId(localDevice);
    }
  }, [localDevice]);

  return (
    <Row className="justify-content-center">
      <Col xs={8} md={6}>
        <div className="text-center">
          {isConnecting && <p>Connecting...</p>}
          {isConnected && (
            <p>
              {messageColor("Connected", "success", true)} to WebSocket {ip}{" "}
              {/* <button
                className="inbox__email-btn border rounded"
                onClick={() => setIsEditable(true)}
              >
                <EditIcon fontSize="small" />
              </button> */}
            </p>
          )}
          {isError && (
            <p>
              {messageColor("Failed!", "danger", true)} webSocket api or port is
              not valid
            </p>
          )}

          {deviceIdEdit ? (
            <DeviceId
              deviceId={deviceId}
              setDeviceId={setDeviceId}
              setDeviceIdEdit={setDeviceIdEdit}
            />
          ) : (
            <p>
              {deviceId ? `Device ID: ${deviceId}` : "No device found"}
              <button
                className="inbox__email-btn border rounded ml-2"
                onClick={() => setDeviceIdEdit(true)}
              >
                {deviceId ? (
                  <EditIcon fontSize="small" />
                ) : (
                  <AddCircleIcon fontSize="small" />
                )}
              </button>
            </p>
          )}
          <br />

          {!isEditable && isConnected && deviceId ? (
            <>
              <Row className="mt-4 justify-content-center">
                <SwitchButton
                  switchState={switchState(isOn, 1)}
                  switchController={handleClick}
                  switchNumber={1}
                  onLoading={onLoading}
                />
                <SwitchButton
                  switchState={switchState(isOn, 2)}
                  switchController={handleClick}
                  switchNumber={2}
                  onLoading={onLoading}
                />
                <Fan
                  speed={speed}
                  setSpeed={setSpeed}
                  increaseSpeed={increaseSpeed}
                  decreaseSpeed={decreaseSpeed}
                  onLoading={onLoading}
                  switchNumber={3}
                />
              </Row>
            </>
          ) : (
            <>
              {/* <div className="d-flex justify-content-center flex-column">
                <input
                  className="form-control w-50 text-center m-auto"
                  placeholder="192.168.1.225:8080"
                  type="text"
                  value={socketIp}
                  onChange={(e) => setSocketIp(e.target.value)}
                  onKeyPress={(e) => e.key == "Enter" && handleIp()}
                />
                <p style={{ fontSize: 12 }}>
                  Please reload the browser after connect the websocket. <br />
                  Otherwise switch will not working
                </p>
              </div> */}
              <button
                className="btn btn-sm btn-primary mt-3"
                onClick={handleIp}
              >
                {submitted ? "Edit" : "Connect"}
              </button>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default SwitchBoard;
