import { useMutation } from "@apollo/client";
import { UPDATE_USER_PASSOWRD_BY_USERID } from "../../Mutation";

const useUpdateUserPassowrdByUserIdMutation = (props) => {
  const [updateUserPassowrdByUserId, { data, ...rest }] = useMutation(
    UPDATE_USER_PASSOWRD_BY_USERID,
    {
      ...props,
    }
  );
  return {
    ...rest,
    data: data?.updateUserPassowrdByUserId,
    updateUserPassowrdByUserId,
  };
};

export default useUpdateUserPassowrdByUserIdMutation;
