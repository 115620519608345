import React, { useEffect, useState } from "react";
const permission = JSON.parse(localStorage?.getItem("permissions"));
import jwt from "jsonwebtoken";
import CryptoJS from "crypto-js";

// ========================encrypt-decrypt-start=========================
const key = `${process.env.SECRET_KEY}`;
// Encrypt
export const encryption = (array) => {
  return JSON.stringify(
    CryptoJS?.AES?.encrypt(JSON.stringify(array), key).toString()
  );
};

// Decrypt
export const decryption = (encryption) => {
  const bytes = encryption && CryptoJS?.AES?.decrypt(encryption, key);
  return encryption ? JSON.parse(bytes?.toString(CryptoJS.enc.Utf8)) : null;
};
// ========================encrypt-decrypt-close=========================

// leave type
export const leaveTypeListForEmail = [
  {
    value: 1,
    label: `Casual`,
  },
  {
    value: 2,
    label: `Anual`,
  },
  {
    value: 3,
    label: `Sick`,
  },
  {
    value: 4,
    label: `Menstrual`,
  },
  {
    value: 5,
    label: `Maternity`,
  },
  {
    value: 6,
    label: `Reward`,
  },
];

export const isImgUrl = async (url) => {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onerror = () => resolve(false);
    img.onload = () => resolve(true);
  });
};

export const altImg = async (imageLink) => {
  const isImgUrl = async (url) => {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
      img.onerror = () => resolve(false);
      img.onload = () => resolve(true);
    });
  };
  const res = await isImgUrl(imageLink);
  return res;
};

