import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Company, NewCompany } from "../../../Company";
import { decryption } from "../../../HelperFunctions";

export default () => {
  const permission = JSON.parse(localStorage?.getItem("permissions"));

  return (
    <Switch>
      <Route
        path="/company/list"
        render={() =>
          decryption(permission)?.includes("view_department") ? <Company /> : <Redirect to="/404" />
        }
      />
      <Route
        path="/company/new"
        render={() =>
          decryption(permission)?.includes("create_department") ? <NewCompany /> : <Redirect to="/404" />
        }
      />
    </Switch>
  );
};
