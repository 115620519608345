import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import CustomerRegisterForm from './CustomerRegisterForm';

const CustomerRegister = ({ history }) => {
  const [error, setError] = useState('');

  const registerFireBase = (user) => {
    // createUserWithEmailAndPassword(auth, user.email, user.password)
    //   .then(() => {
    //     history.push('/log_in');
    //   })
    //   .catch((err) => {
    //     setError(err.message);
    //   });
  };

  return (

    <div className="account account--not-photo">
      <div className="account__wrapper">
        {/* <LogInForm onSubmit={showResults} form="log_in_form" /> */}
        <div className="tabs__wrap">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">
                <span className="account__logo">
                  Office Management
                </span>
              </h3>
            </div>
            <CustomerRegisterForm onSubmit={registerFireBase} errorMessage={error} />
            <div className="account__have-account">
              <p>Already have an account? <Link to="/log_in">Login</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerRegister.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(CustomerRegister);
