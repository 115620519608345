import React, { useEffect } from 'react';
import moment from 'moment';

const ChatLeft = ({ comment, openImageViewer, setImageArray, imageArray }) => {
    return (
        <li className="clearfix mb-4">
            <div className="message-data">
                <img src={comment?.avator ? comment.avator : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"} />

                <span className="message-data-time">{comment?.projectProposalWithCommentInfo?.customerInfo?.fullname}</span>
                <span className="message-data-time ml-3">{moment(new Date(Number(comment?.createdAt))).fromNow()}</span>
            </div>
            <div className="message my-message">
                <p>{comment?.comment}</p>
                {comment?.attachment ? imageArray?.map((item, index) =>
                    <img onClick={() => {
                        setImageArray([comment?.attachment])
                        openImageViewer(index)
                    }} style={{ height: '150px', width: '150px', borderRadius: '10px', marginTop: '10px', cursor: "pointer" }} src={comment?.attachment} alt="image" srcset="" />
                ) : null}
            </div>
        </li>
    );
};

export default ChatLeft;