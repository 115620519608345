import { useQuery } from "@apollo/client";
import { LEAVE_APPLICATION_DROPDOWNS } from "../../Queries";

export default (props) => {
  const { data, refetch, loading, error } = useQuery(
    LEAVE_APPLICATION_DROPDOWNS,
    {
      ...props,
    }
  );
  return { data: data?.leaveApplicationDropdowns, loading, refetch, error };
};
