/* eslint-disable react/jsx-indent */
import React from "react";
import { Route, Switch } from "react-router-dom";
import { Attendance } from "../../../Attendance";
import AttendanceReport from "../../../Attendance/Report";

export default () => {
  const permission = JSON.parse(localStorage?.getItem("permissions"));

  return (
    <Switch>
      <Route exact path="/attendance" component={Attendance} />
      <Route exact path="/attendance/reports" component={AttendanceReport} />
    </Switch>
  );
};
