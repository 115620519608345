import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Badge, Button, ButtonToolbar, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { SelectField } from '@/shared/components/form/Select';
import { GET_ALL_SERVICE_TYPE } from '../../../GraphQL/Queries';
import { useMutation, useQuery } from '@apollo/client';
import FormField from './FormField';
import { ASSIGN_PROJECT_PROPOSAL, PROJECT_PROPOSAL_STATUS_UPDATE } from '../../../GraphQL/Mutation';
import { successMessage, warningMessage } from '../../ReactToastify';

const DetailsModal = ({ modal, toggle, data, roles, setIsAssigned, department, deptHeadInfo, setDetailsId, loading, setLoading }) => {
    // useform
    const { handleSubmit, register, reset, control, } = useForm();

    // states
    const [serviceType, setServiceType] = useState([]);

    // mutations
    const [assignProjectProposal] = useMutation(ASSIGN_PROJECT_PROPOSAL);
    const [projectProposalStatusUpdate] = useMutation(PROJECT_PROPOSAL_STATUS_UPDATE);

    // queries
    const loadAllService = useQuery(GET_ALL_SERVICE_TYPE);

    useEffect(() => {
        const list = data?.service_type ? JSON.parse(data?.service_type) : null;

        let service = [];
        if (list) {
            list?.map(item => {
                loadAllService?.data?.getAllServiceType?.find(value => {
                    if (value?.id == item) {
                        service.push(value?.name);
                    }
                })
            })
            setServiceType(service)
        }


    }, [data?.id]);

    useEffect(() => {
        if (data?.dept_id) {
            setDetailsId(data?.dept_id)
        }
    }, [data?.dept_id])

    // functions
    const onSubmit = async (value) => {
        const formData = {
            dept_id: Number(value?.dept_id?.value),
            id: data?.id
        }
        setLoading(true);
        const result = await assignProjectProposal({
            variables: formData
        })
        setLoading(false);

        if (result?.data?.assignProjectProposal?.result_code == "000") {
            successMessage(result?.data?.assignProjectProposal?.status, 3000);
            setIsAssigned(prevState => !prevState);
            toggle();
        } else {
            warningMessage(result?.data?.assignProjectProposal?.status, 3000);
        }
    }
    // project proposal accept by department of head 
    const acceptHandler = async () => {
        setLoading(true);
        const result = await projectProposalStatusUpdate({
            variables: {
                projectStatus: "In Progress",
                id: data?.id
            }
        })
        if (result?.data?.projectProposalStatusUpdate?.result_code == "000") {
            setTimeout(() => {
                setLoading(false);
                successMessage(result?.data?.projectProposalStatusUpdate?.status, 3000);
                setIsAssigned(prevState => !prevState);
                toggle();
            }, 2000)
        } else {
            warningMessage(result?.data?.projectProposalStatusUpdate?.status, 3000);
        }
    }

    // array for type
    const clientTypesRadioArray = [
        {
            label: 'Individual',
            name: 'cilent_type',
            radioValue: 1
        },
        {
            label: 'Company',
            name: 'cilent_type',
            radioValue: 2
        }
    ].find(item => item.radioValue === data?.client_type)?.label;

    const projectType = data?.project_type ? JSON.parse(data?.project_type)[0] : null;
    const projectTypeValue = data?.project_type ? JSON.parse(data?.project_type)[1] : null;

    const type = [
        {
            name: 'project_type',
            label: 'Software',
            radioValue: 1
        },
        {
            name: 'project_type',
            label: 'Hardware',
            radioValue: 2
        },
    ]?.find(item => item?.radioValue == projectType)?.label;

    const software = [
        {
            label: "Web",
            value: 1
        },
        {
            label: "Mobile",
            value: 2
        },
    ]?.find(item => item?.value == projectTypeValue)?.label;
    const hardware = [
        {
            label: "Electronics",
            value: 1
        },
        {
            label: "Power/Renewable",
            value: 2
        },
    ]?.find(item => item?.value == projectTypeValue)?.label;
    // console.log(type, software, hardware);
    return (
        <Modal toggle={toggle} isOpen={modal}>
            <ModalHeader className="border-bottom-0 pb-2" toggle={toggle}>
                <div className="text-modal">Title: {data?.title}</div>
                <div className="subhead timeline__date mt-2">{moment(new Date(Number(data?.createdAt))).format("dddd, Do MMMM YYYY, h:mm:ss a")} by {data?.customerInfo?.fullname}</div>
            </ModalHeader>
            <ModalBody>
                <div className="modal__body">
                    <p>
                        <b>Status: </b>
                        <Badge
                            color={
                                data?.projectStatus == "Pending"
                                    ? "danger"
                                    : data?.projectStatus == "In Progress"
                                        ? "primary"
                                        : data?.projectStatus == "Completed"
                                            ? "success"
                                            : "warning"
                            }
                        >
                            {data?.projectStatus}
                        </Badge>
                    </p>
                    <p>
                        <b>Project Type: </b>
                        <span className="text-capitalize">{type} → {type == "Software" ? software : null} {type == "Hardware" ? hardware : null}</span>
                    </p>
                    <p>
                        <b>Client Type: </b>
                        <span className="text-capitalize">{clientTypesRadioArray}</span>
                    </p>
                    <p>
                        <b>Service Type:</b> {serviceType?.join(", ")}
                    </p>
                    {data?.end ? <p>
                        <b>Duration:</b> {moment(data?.start).format('DD/MM/YYYY')}
                        &nbsp; to &nbsp;
                        {moment(data?.end).format('DD/MM/YYYY')}
                        &nbsp;
                        (
                        {moment(data?.end).diff(moment(data?.start), 'days')}
                        {moment(data?.end).diff(moment(data?.start), 'days') > 1 ? ' days' : ' day'}
                        )
                    </p>
                        : <p> <b>Start:</b> {moment(data?.start).format('DD/MM/YYYY')} </p>}

                    <p><b>Description:</b> {data?.description}</p>

                    {data?.images && JSON.parse(data?.images)?.length ? <p><b>Attachment:</b></p> : null}
                    {
                        <div className="upload-img-container d-flex flex-wrap">
                            {data?.images && JSON.parse(data?.images)?.map(item => {
                                const extension = item?.url?.split('.')[item?.url?.split('.')?.length - 1];
                                const thumb = ["pdf", "PDF", "doc", "docx", "ppt"];
                                return (
                                    thumb?.includes(extension)
                                        ? <div className="position-relative mt-3 mr-3">
                                            <img className="upload-img" src="https://cutewallpaper.org/24/file-png/collections.png" alt="image" ></img>
                                            <a className="position-absolute prev-download-btn" href={item?.url} download><span class="lnr lnr-download"></span></a>
                                        </div>
                                        : <div className="position-relative mt-3 mr-3">
                                            <img className="upload-img" src={item?.url} alt="image" ></img>
                                            <a className="position-absolute prev-download-btn" href={item?.url} download><span class="lnr lnr-download"></span></a>
                                        </div>
                                )
                            })}
                        </div>
                    }
                    {data?.dept_id ? null : roles?.includes("System Admin") ? loading ? "Loading..." : <div className="mt-3">
                        <form
                            className={`form'}`}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="form__form-group">
                                <span className="form__form-group-label">Assign to</span>
                                <div className="form__form-group-field">
                                    <FormField
                                        name="dept_id"
                                        control={control}
                                        component={SelectField}
                                        options={department}
                                        // rules={{ required: "This is required field" }}
                                        placeholder="Select department"
                                    />
                                </div>
                            </div>
                            <ButtonToolbar className="form__button-toolbar float-right">
                                <Button
                                    type="button"
                                    onClick={() => toggle()}
                                    className="mr-1"
                                >
                                    Cancel
                                </Button>
                                {loading ? <Button color="primary">
                                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                    Assigning...
                                </Button> : <Button color="primary" className="ml-1" type="submit">Assign</Button>}
                            </ButtonToolbar>
                        </form>
                    </div> : null}

                    {roles?.includes("System Admin") ? data?.dept_id ? <div className="mt-3 mb-3 bg-success text-white rounded px-3 py-2">
                        <h4>The project proposal has been assigned to <strong><i>{deptHeadInfo?.user?.name}</i></strong> !</h4>
                        <p> Head Of Department, {deptHeadInfo?.name}</p>
                        <p> at {moment(new Date(Number(data?.updatedAt))).format("dddd, Do MMMM YYYY, h:mm:ss a")}</p>
                    </div> : null : null}

                    {data?.projectStatus == "Waiting" ? roles?.includes("Head Of Department") ? <div>
                        <ButtonToolbar className="form__button-toolbar float-right">
                            <Button
                                type="button"
                                onClick={() => toggle()}
                                className="mr-1"
                            >
                                Cancel
                            </Button>
                            {loading ? <Button color="primary">
                                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                Accepting...
                            </Button> : <Button onClick={() => acceptHandler()} color="primary" className="ml-1" type="submit">Accept</Button>}
                        </ButtonToolbar>
                    </div> : null : null}

                </div>

            </ModalBody>
        </Modal >
    );
};

export default DetailsModal;