import { useLazyQuery } from "@apollo/client";
import { ATTENDANCE_SUMMARY } from "../../Queries";

const useAttendanceSummaryQuery = (props) => {
  const { skip, ...rest } = props;
  const [fetchData, { data, ...queryRest }] = useLazyQuery(ATTENDANCE_SUMMARY, {
    ...rest,
    skip: skip !== undefined ? skip : true,
  });

  if (skip !== undefined && skip === false) {
    fetchData();
  }

  return { data: data?.attendanceSummary, ...queryRest };
};

export default useAttendanceSummaryQuery;
