import { useMutation, useQuery } from '@apollo/client';
import TabPlusIcon from 'mdi-react/TabPlusIcon';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { SOFT_DELETE_PROJECT_PROPOSAL } from '../../../GraphQL/Mutation';
import { GET_ALL_DEPARTMENT, GET_ALL_PROJECT_PROPOSAL, GET_ALL_PROJECT_PROPOSAL_BY_CUSTOMER_ID, GET_ALL_PROJECT_PROPOSAL_BY_DEPT_ID, GET_ALL_PROJECT_PROPOSAL_BY_DEPT_ID_STATUS, GET_ALL_PROJECT_PROPOSAL_BY_INCLUDE_DEPT_HEAD, GET_ALL_PROJECT_PROPOSAL_BY_STATUS, GET_DEPARTMENTS_BY_USER_ID, GET_PROJECT_PROPOSAL_BY_CUSTOMER_ID_STATUS } from '../../../GraphQL/Queries';
import { arrayCustomIndexing, loginInfo } from '../../../utils/helpers';
import { decryption } from '../../HelperFunctions';
import { successMessage, warningMessage } from '../../ReactToastify';
import ProjectProposalForm from './ProjectProposalForm';
import ProposalTable from './ProposalTable';

const ProposalList = () => {
    // roles
    const roles = decryption(JSON.parse(localStorage?.getItem("roles")));
    const history = useHistory();
    const loggedInfo = loginInfo();
    const [proposal, setProposal] = useState([]);
    const [deleteToggle, setDeleteToggle] = useState(false);
    const [isAssigned, setIsAssigned] = useState(false);
    const [department, setDepartment] = useState([]);
    const [deptHeadInfo, setDeptHeadInfo] = useState({});
    const [detailsId, setDetailsId] = useState(null);
    const [projectStatus, setProjectStatus] = useState("All");
    const [loading, setLoading] = useState(false);

    // filter handler by onchange
    const filterHandler = () => {
        return (e) => {
            setProjectStatus(e.target.value);
        };
    };

    const LoadDepartment = useQuery(GET_DEPARTMENTS_BY_USER_ID, {
        skip: !loggedInfo?.id,
        variables: {
            user_id: loggedInfo?.id
        }
    });


    if (roles?.includes("System Admin")) {
        const LoadAllDepartment = useQuery(GET_ALL_DEPARTMENT);
        // setLoading(LoadAllDepartment.loading);
        useEffect(() => {
            if (LoadAllDepartment?.data?.getAllDepartments) {
                setDepartment(LoadAllDepartment?.data?.getAllDepartments?.map(item => ({
                    label: item?.name,
                    value: item?.id
                })));
                if (detailsId) {
                    setDeptHeadInfo(LoadAllDepartment?.data?.getAllDepartments?.find(item => item?.id == detailsId))
                }
                LoadAllDepartment.refetch();
            }
        }, [LoadAllDepartment?.data?.getAllDepartments?.length, detailsId])
    }

    const [softDeleteProjectProposal] = useMutation(SOFT_DELETE_PROJECT_PROPOSAL);
    const deleteHandler = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                const result = await softDeleteProjectProposal({
                    variables: { id }
                })
                setDeleteToggle(prevState => !prevState);
                if (result?.data?.softDeleteProjectProposal?.result_code == "000") {
                    Swal.fire(result?.data?.softDeleteProjectProposal?.status, '', 'success');
                } else {
                    Swal.fire(result?.data?.softDeleteProjectProposal?.status, '', 'error');
                }
                
            }
        })
    }



    if (loggedInfo?.role == "Customer") {
        if (projectStatus == "All") {
            let proposals = useQuery(GET_ALL_PROJECT_PROPOSAL_BY_CUSTOMER_ID, {
                skip: !loggedInfo?.id,
                variables: {
                    customer_id: loggedInfo?.id,
                }
            });
            useEffect(async () => {
                setLoading(true);
                proposals = await proposals.refetch({
                    customer_id: loggedInfo?.id,
                });
                setProposal(arrayCustomIndexing(proposals?.data?.projectProposalByCustomer));
                setLoading(false);
            }, [proposals?.data?.projectProposalByCustomer?.length, proposals?.data?.projectProposalByCustomer, deleteToggle, projectStatus])

        } else {
            let proposals = useQuery(GET_PROJECT_PROPOSAL_BY_CUSTOMER_ID_STATUS, {
                skip: !loggedInfo?.id,
                variables: {
                    customer_id: loggedInfo?.id,
                    projectStatus
                }
            });
            useEffect(async () => {
                setLoading(true);
                proposals = await proposals.refetch({
                    customer_id: loggedInfo?.id,
                    projectStatus
                });
                setProposal(arrayCustomIndexing(proposals?.data?.projectProposalByCustomerAndStatus));
                setLoading(proposals?.loading);
            }, [proposals?.data?.projectProposalByCustomerAndStatus?.length, proposals?.data?.projectProposalByCustomerAndStatus, deleteToggle, projectStatus])

        }
    } else if (roles?.includes("System Admin")) {
        if (projectStatus == "All") {
            let loadProjectProposal = useQuery(GET_ALL_PROJECT_PROPOSAL);
            // setLoading(loadProjectProposal.loading);
            useEffect(() => {
                if (loadProjectProposal?.data?.projectProposal) {
                    setProposal(arrayCustomIndexing(loadProjectProposal?.data?.projectProposal));
                    loadProjectProposal = loadProjectProposal.refetch({
                        projectStatus
                    });
                }
            }, [loadProjectProposal?.data?.projectProposal?.length, loadProjectProposal?.data?.projectProposal, isAssigned, projectStatus])
        } else {
            let loadProjectProposal = useQuery(GET_ALL_PROJECT_PROPOSAL_BY_STATUS, {
                skip: !projectStatus,
                variables: {
                    projectStatus
                }
            });
            // setLoading(loadProjectProposal.loading);
            useEffect(() => {
                if (loadProjectProposal?.data?.projectProposalByStatus) {
                    setProposal(arrayCustomIndexing(loadProjectProposal?.data?.projectProposalByStatus));
                    loadProjectProposal = loadProjectProposal.refetch({
                        projectStatus
                    });
                }
            }, [loadProjectProposal?.data?.projectProposalByStatus?.length, loadProjectProposal?.data?.projectProposalByStatus, isAssigned, projectStatus])
        }
    } else if (roles?.includes("Head Of Department")) {
        let result = useQuery(GET_ALL_PROJECT_PROPOSAL_BY_INCLUDE_DEPT_HEAD, {
            skip: !loggedInfo?.dept_id,
            variables: {
                user_id: loggedInfo?.id,
            }
        });
        // setLoading(loadProjectProposal.loading);
        useEffect(() => {
            if (result?.data?.getAllProjectProposalByIndividualHeadpDept) {
                setProposal(arrayCustomIndexing(result?.data?.getAllProjectProposalByIndividualHeadpDept));
                result = result.refetch({
                    dept_id: loggedInfo?.dept_id,
                });
            }
        }, [result?.data?.getAllProjectProposalByIndividualHeadpDept?.length, result?.data?.getAllProjectProposalByIndividualHeadpDept, projectStatus, isAssigned])
    }


    return (
        <Container>
            <Card>
                <CardBody>
                    <Row>
                        <Col xs={6} md={6}>
                            <h3 className="page-title">Project proposal list</h3>
                        </Col>
                        <Col xs={6} md={6}>
                            {loggedInfo?.role == "Customer" ? <Button
                                onClick={() => history.push("/project/new")}
                                className="float-right btn btn-sm"
                                color="primary"
                            >
                                <TabPlusIcon />
                                {/* <Link to="/make/request">Make a request</Link> */}
                                Add New
                            </Button> : null}
                        </Col>
                    </Row>
                    <Row>
                        <ProposalTable
                            roles={roles}
                            loggedInfo={loggedInfo}
                            data={proposal}
                            loading={loading}
                            deleteHandler={deleteHandler}
                            setIsAssigned={setIsAssigned}
                            department={department}
                            deptHeadInfo={deptHeadInfo}
                            setDetailsId={setDetailsId}
                            filterHandler={filterHandler}
                            setLoading={setLoading}
                        />
                    </Row>
                </CardBody>
            </Card>

        </Container>
    );
};

export default ProposalList;