/* eslint-disable no-debugger */
export const FARMER_CREATE = "FARMER_CREATE";
export const FARMING_CREATE = "FARMING_CREATE";
export const CROP = "CROP";
export const TOKEN = "TOKEN";
export const DELETE_CROP = "DELETE_CROP";
export function farmerCreate() {
  return {
    type: FARMER_CREATE,
  };
}

export const farmingAdd = () => {
  return {
    type: FARMING_CREATE,
  };
};
export const cropAction = () => {
  return {
    type: CROP,
  };
};
export const token = (token) => {
  return {
    type: TOKEN,
    payload: token,
  };
};
export const deleteCrop = () => {
  return {
    type: DELETE_CROP,
  };
};
