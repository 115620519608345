import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { UPDATE_USER } from "../../GraphQL/Mutation";
import { GET_ALL_ROLE } from "../../GraphQL/Queries";

const AssignRoleEdit = (props) => {
  const getAllRole = useQuery(GET_ALL_ROLE);
  const [userUpdate, userData] = useMutation(UPDATE_USER);
  const defaultRole = props?.user?.role?.map((role) => {
    return {
      value: role.id,
      label: role.role_name,
    };
  });
  const [existingRole, setExistingRole] = useState(defaultRole ? defaultRole : []);
  const checkRole = (value) => {
    const check = existingRole?.find((role) => role.value == value.id);
    if (check) {
      const list = [...existingRole];
      const index = list.indexOf((role) => role.value == value.id);
      list.splice(index, 1);
      setExistingRole(list);
    } else {
      setExistingRole([
        ...existingRole,
        { value: value.id, label: value.role_name },
      ]);
    }
  };
  const handleSubmit = async () => {
    await userUpdate({
      variables: {
        ...props.user,
        role: existingRole?.map((data) => {
          return { id: data.value };
        }),
      },
    });
    await props.refetch();
    props.toggle();
  };

  return (
    <>
      <ModalHeader>
        <h2>Edit Assigned Role</h2>
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup as={Row} controlId="formPlaintextEmail">
            <Label column sm="4">
              User
            </Label>
            <Col sm="6">{props.user.name}</Col>
          </FormGroup>

          <FormGroup as={Row} controlId="formPlaintextPassword">
            <Label column sm="4">
              Select Role
            </Label>
            <Col sm="6">
              {getAllRole?.data?.getRoles?.map((item) => (
                <>
                  <label>
                    <input
                      type="checkbox"
                      value={item.role_name}
                      checked={
                        existingRole?.find(
                          (value) => value?.label === item?.role_name
                        )
                          ? true
                          : false
                      }
                      onChange={(e) => checkRole(item)}
                    />{" "}
                    {item.role_name}
                  </label>{" "}
                  <br />
                </>
              ))}
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-danger" onClick={props.toggle}>Cancel</button>
        <button className="btn btn-success" onClick={handleSubmit}>
          Update
        </button>
      </ModalFooter>
    </>
  );
};

export default AssignRoleEdit;
