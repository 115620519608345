import { useMutation } from "@apollo/client";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import jwt from "jsonwebtoken";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import { DELETE_FARMING_PRACTICE } from "../../../../GraphQL/Mutation";
import { farmingAdd } from "../../../../redux/actions/farmerAction";

function FarmingDetailsCard({
  setSearchItem,
  farmerInfo,
  farmingData,
  farmingModal,
  setModal,
  setFarmingModal,
  modal,
  editFarmingHandler,
  gen_id,
  creator_id,
  pesticideData,
}) {
  // dark mode css in
  const theme = useSelector((state) => state.theme.className);
  //get token from the local storage;
  const token = localStorage?.getItem("token");
  let user;
  //verify that token ;
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    user = decoded;
  });

  const dispatch = useDispatch();
  const [updateFarmingPractice, { loading, data, error }] = useMutation(
    DELETE_FARMING_PRACTICE
  );
  // if (loading) {
  //     return (
  //         <div>
  //             <h1>Deleting...</h1>
  //         </div>
  //     );
  // }
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  return (
    <Accordion
      expanded={expanded === farmerInfo?.id}
      onChange={handleChange(farmerInfo?.id)}
      className="bg-accordion example"
      style={{ backgroundColor: theme }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id={`accordion-${farmerInfo?.id}`}
      >
        <div>
          {farmerInfo?.season} ({farmerInfo?.farming_year})
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div className="p-3">
            <Row className="border-bottom">
              <Col md={12}>
                <div className="flex justify-content-between align-items-center mb-3">
                  <h4 className="bold-text">Farming Practice</h4>
                  {(user?.id === creator_id || user?.role === "admin") && (
                    <div className="flex">
                      <IconButton
                        onClick={async () => {
                          swalWithBootstrapButtons
                            .fire({
                              title: "Are you sure?",
                              text: "You won't be able to revert this!",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonText: "Yes, delete it!",
                              cancelButtonText: "No, cancel!",
                              reverseButtons: true,
                            })
                            .then(async (result) => {
                              if (result.isConfirmed) {
                                await updateFarmingPractice({
                                  variables: { id: farmingData?.id },
                                  onQueryUpdated(observableQuery) {
                                    // Define any custom logic for determining whether to refetch
                                    if (shouldRefetchQuery(observableQuery)) {
                                      return observableQuery.refetch();
                                    }
                                  },
                                });
                                dispatch(farmingAdd());
                                setFarmingModal(!farmingModal);
                                swalWithBootstrapButtons.fire(
                                  "Deleted!",
                                  "Your file has been deleted.",
                                  "success"
                                );
                              } else if (
                                /* Read more about handling dismissals below */
                                result.dismiss === Swal.DismissReason.cancel
                              ) {
                                swalWithBootstrapButtons.fire(
                                  "Cancelled",
                                  "Your imaginary file is safe :)",
                                  "error"
                                );
                              }
                            });
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>{" "}
                      <IconButton
                        onClick={() => {
                          editFarmingHandler(farmingData);
                          setModal(!modal);

                          setFarmingModal(!farmingModal);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>{" "}
                    </div>
                  )}
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="profile-info">
                  <h5 className="subhead">Company</h5>
                  <h5 className="mb-3"> {farmerInfo?.company}</h5>
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="profile-info">
                  <h5 className="subhead">Crop</h5>
                  <h5 className="mb-3">{farmerInfo?.crop}</h5>
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="profile-info">
                  <h5 className="subhead">Cultivate Area</h5>
                  <h5 className="mb-3"> {farmerInfo?.cultivated_areas}</h5>
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="profile-info">
                  <h5 className="subhead">Market Price</h5>
                  <h5 className="mb-3">{farmerInfo?.current_price}</h5>
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="profile-info">
                  <h5 className="subhead">Seed Collection</h5>
                  <h5 className="mb-3">{farmerInfo?.production_cost}</h5>
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="profile-info">
                  <h5 className="subhead">Profit</h5>
                  <h5 className="mb-3">{farmerInfo?.profit}</h5>
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="profile-info">
                  <h5 className="subhead">Risk of flood</h5>
                  <h5 className="mb-3">{farmerInfo?.risk_of_flood}</h5>
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="profile-info">
                  <h5 className="subhead">Seed Collection</h5>
                  <h5 className="mb-3">{farmerInfo?.seeds_collection}</h5>
                </div>
              </Col>{" "}
              <Col xs={6} md={4}>
                <div className="profile-info">
                  <h5 className="subhead">Sell Product</h5>
                  <h5 className="mb-3">{farmerInfo?.sell_product}</h5>
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="profile-info">
                  <h5 className="subhead">Selling Price</h5>
                  <h5 className="mb-3">{farmerInfo?.selling_price}</h5>
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="profile-info">
                  <h5 className="subhead">Spray Time</h5>
                  <h5 className="mb-3">{farmerInfo?.spray_time}</h5>
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="profile-info">
                  <h5 className="subhead">Training</h5>
                  <h5 className="mb-3">{farmerInfo?.training}</h5>
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="profile-info">
                  <h5 className="subhead">Variety</h5>
                  <h5 className="mb-3">{farmerInfo?.variety}</h5>
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="profile-info">
                  <h5 className="subhead">Weeds</h5>
                  <h5 className="mb-3">{farmerInfo?.weeds}</h5>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12}>
                <h4 className="bold-text mb-3">Fertilizer</h4>
              </Col>
              <Col md={12}>
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {farmerInfo?.fertilizer?.map((data, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: "1px solid #eff1f5" }}
                      >
                        <td scope="row">{index + 1}</td>
                        <td>{data?.name}</td>
                        <td>{data.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12}>
                <h4 className="bold-text mb-3">Disease Pets And Dose</h4>
              </Col>
              <Col md={12}>
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Disease</th>
                      <th>Pests</th>
                      <th>Dose</th>
                    </tr>
                  </thead>
                  <tbody>
                    {farmerInfo?.disease_pests_dose?.map((data, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: "1px solid #eff1f5" }}
                      >
                        <td scope="row">{index + 1}</td>
                        <td>{data.disease}</td>
                        <td>{data.pest}</td>
                        <td>{data.dose}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default FarmingDetailsCard;
