import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Table,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  ButtonToolbar,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import Clock from "react-live-clock";
import { usePagination } from "../../../utils/helpers";
import { Pagination } from "@mui/material";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/react";
import PopoverOnClick from "../../../utils/HelperComponent/PopoverOnClick";

const AttendanceTable = ({ allAttendances, isLoadingAttendance, roles }) => {
  const todayCheck = moment(new Date()).format("YYYY-MM-DD");
  // material pagination
  let [page, setPage] = useState(1);
  const PER_PAGE = 30;
  const count = Math.ceil(allAttendances?.length / PER_PAGE);
  const pageData = usePagination(allAttendances, PER_PAGE);
  //add spinner fro loading
  // import PropagateLoader from "react-spinners/PropagateLoader";
  // import { css } from "@emotion/react";
  let [color, setColor] = useState("rgb(54, 215, 183)");
  const override = css`
    margin: 0 auto;
    display: block;
    width: 100px;
  `;
  // <PropagateLoader color={color} css={override} loading={true} size={10} />

  const handleChange = (e, p) => {
    setPage(p);
    pageData.jump(p);
  };
  // attendance type array
  const attendanceTypeArray = [
    {
      value: 101,
      label: `Work From Home`,
    },
    {
      value: 102,
      label: `On Field Duty`,
    },
    {
      value: 201,
      label: `On Leave`,
    },
  ];
  const [popoverToggle, setPopoverToggle] = useState(false);
  return (
    <Table role="table" striped hover responsive>
      <thead>
        <tr role="row">
          <th>#</th>
          <th>Name</th>
          <th>Last Access</th>
          <th>Date</th>
          <th>First login</th>
          <th>Last logout</th>
          <th>Duration</th>
        </tr>
      </thead>
      <tbody>
        {isLoadingAttendance ? (
          <tr>
            <td colSpan="7">
              <PropagateLoader
                color={color}
                css={override}
                loading={true}
                size={10}
              />
            </td>
          </tr>
        ) : allAttendances?.length > 0 ? (
          pageData?.currentData()?.map((item, index) => (
            <tr
              className={`${
                (item?.attendance == false && "bg-not-attendance") ||
                (item?.lateEntry == true && "bg-not-attendance")
              }`}
              key={item?.id}
            >
              <th>{item?.indexId ? item?.indexId : index + 1}</th>
              <td>
                {item?.employeeInfo?.user?.name}({item?.rfid})
                {/* {
                                        item?.user_ip
                                            && item?.login_device == null
                                            ? <Badge title="Web Login" style={{ padding: '2px 4px' }} size="sm"><span style={{ fontSize: 10 }} className="lnr lnr-earth"></span></Badge>
                                            : null
                                    } */}
              </td>
              <td>{item?.user_ip ? item?.user_ip : "—"}</td>
              <td>{item?.login_date ? item?.login_date : "—"}</td>
              <td>
                {item?.login_time
                  ? `${moment(item?.login_time).format("hh:mm:ss A")} `
                  : "—"}
                {item?.login_device ? `(${item?.login_device})` : null}
                {item?.type ? (
                  item?.type == 201 ? (
                    <PopoverOnClick
                      id={`on_leave${item?.id}`}
                      value="On Leave"
                      buttonValue="OL"
                    />
                  ) : item?.type == 101 ? (
                    <PopoverOnClick
                      id={`wfh${item?.id}`}
                      value="Work From Home"
                      buttonValue="WFH"
                    />
                  ) : item?.type == 102 ? (
                    <PopoverOnClick
                      id={`ofd${item?.id}`}
                      value="On Field Duty"
                      buttonValue="OFD"
                    />
                  ) : null
                ) : null}
                {item?.reason ? (
                  <PopoverOnClick
                    color="primary"
                    id={`reason${item?.id}`}
                    value={item.reason}
                    buttonValue="?"
                  />
                ) : null}
              </td>
              <td>
                {item?.logout_time
                  ? moment(item?.logout_time).format("hh:mm:ss A")
                  : "—"}{" "}
                {item?.logout_device ? `(${item?.logout_device})` : null}
              </td>
              <td>
                {item?.logout_time ? (
                  moment
                    .utc(
                      moment(item?.logout_time, "YYYY-MM-DD HH:mm:ss").diff(
                        moment(item?.login_time, "YYYY-MM-DD HH:mm:ss")
                      )
                    )
                    .format("HH:mm:ss")
                ) : item?.login_time ? (
                  moment(item?.login_time).format("YYYY-MM-DD") ==
                  moment(new Date()).format("YYYY-MM-DD") ? (
                    <Clock
                      date={moment.utc(
                        moment(new Date(), "YYYY-MM-DD HH:mm:ss").diff(
                          moment(item?.login_time, "YYYY-MM-DD HH:mm:ss")
                        )
                      )}
                      format={"HH:mm:ss"}
                      ticking={true}
                      timezone={"utc"}
                    />
                  ) : (
                    "—"
                  )
                ) : (
                  "—"
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="7" className="text-success text-center">
              <b>Today you have no attendance.</b>
            </td>
          </tr>
        )}
      </tbody>
      {<Pagination count={count} page={page} onChange={handleChange} />}
    </Table>
  );
};

export default AttendanceTable;
