import { toast } from 'react-toastify';

// the -----ToastContainer-------- component was placed in the app.jsx line number 22

// global success message
export const successMessage = (message, second) => {
    toast.success(message, {
        position: "top-right",
        autoClose: second,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
    });
}

// global warning message
export const warningMessage = (message, second) => {
    toast.warn(message, {
        position: "top-right",
        autoClose: second,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
    })
}