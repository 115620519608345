import React from "react";
import { NavLink } from "react-router-dom";
import ResetPasswordForm from "@/shared/components/reset_password/ResetPasswordForm";
import usePasswordResetByEmailMutation from "../../../GraphQL/hooks/mutations/usePasswordResetByEmailMutation";
import { toast } from "react-toastify";

const ResetPassword = (props) => {
  const { passwordResetByEmail, loading, data } =
    usePasswordResetByEmailMutation();

  const onSubmit = async (data) => {
    
    const email = data?.email;

    if (!email) {
      toast.error("Email is required");
      return false;
    }

    const resetData = await passwordResetByEmail({
      variables: { email },
    });

    const { message, status_code } = resetData?.data?.passwordResetByEmail;
    console.log(message, status_code);
  };
  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">Office Management</h3>
            <h4 className="account__subhead subhead">Password reset</h4>
          </div>

          <div
            className={`text-${
              data?.status_code === 200 ? "success" : "danger"
            }`}
          >
            {data?.message}
          </div>
          <ResetPasswordForm {...props} onSubmit={onSubmit} loading={loading} />

          <div className="account__have-account">
            <p>
              Remember your password? <NavLink to="/log_in">Login</NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
