import { ThemeProps } from "@/shared/prop-types/ReducerProps";
import PropTypes from "prop-types";
import React from "react";

const ToggleTheme = ({ theme, changeToLight, changeToDark }) => (
  <label className="toggle-btn customizer__toggle" htmlFor="theme_toggle">
    <input
      className="toggle-btn__input"
      type="checkbox"
      name="theme_toggle"
      id="theme_toggle"
      checked={theme.className === "theme-dark"}
      onChange={theme.className === "theme-dark" ? changeToLight : changeToDark}
    />
    <span className="toggle-btn__input-label" />
  </label>
);

ToggleTheme.propTypes = {
  theme: ThemeProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
};

export default ToggleTheme;
