import React from "react";
import { Col, Row } from "reactstrap";
import UserProfileInfo from "./Components/Info";
import { BLANK_PROFILE } from "@/utils/utilities";

const UserProfile = ({ data = [], profile = {} }) => {
  return (
    <Row>
      <Col xs={12} className="mb-4">
        <div className="d-flex flex-column align-items-center">
          <div className="profile__avatar">
            <img src={profile?.image ?? BLANK_PROFILE} alt="image" />
          </div>
          <div className="profile__data text-center mt-4">
            <h4 className="profile__name">{profile?.name ?? "-"}</h4>
            <p className="profile__work">
              {profile?.desig ?? "-"} , {profile?.dept ?? "-"}
            </p>
          </div>
        </div>
      </Col>
      <Col xs={12}>
        <Row>
          <Col xs={6}>
            <Row>
              <Col xs={12}>
                <UserProfileInfo
                  data={data?.find((item) => item?.title === "Contact")}
                />
              </Col>
              <Col xs={12}>
                <UserProfileInfo
                  data={data?.find((item) => item?.title === "Address")}
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs={12}>
                <UserProfileInfo
                  data={data?.find((item) => item?.title === "Office Info")}
                />
              </Col>
              <Col xs={12}>
                <UserProfileInfo
                  data={data?.find((item) => item?.title === "Leave Left")}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UserProfile;
