import React, { useState } from "react";
import AttendanceReportSearchForm from "./Components/SearchForm";
import AttendanceSummarySearchResult from "../SearchResult";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { dateTime } from "../../../../utils/helpers";
import { isEmptyObject } from "../../../../utils/utilities";
const AttendanceReportSearch = () => {
  const [results, setResults] = useState({});
  const [searchData, setSearchData] = useState({});
  const isEmptyResults = isEmptyObject(results);
  const isEmptySearchData = isEmptyObject(searchData);

  const onClear = () => {
    setSearchData({});
    setResults({});
  };
  return (
    <>
      <Col md={12}>
        <Card>
          <CardBody>
            <h4 className="ml-3 mb-3 text-center">Attendance Report</h4>
            <h6 className="ml-3 mb-3 text-center">{new Date().toLocaleString()}</h6>
            <Row>
              <Col md={6} className="mx-auto">
                <AttendanceReportSearchForm
                  setResults={setResults}
                  setSearchData={setSearchData}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

      {!isEmptyResults && !isEmptySearchData && (
        <Col md={12}>
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="text-success">
                    Search for {dateTime(searchData?.start_date, "D MMM YYYY")}{" "}
                    to {dateTime(searchData?.end_date, "D MMM YYYY")}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="text-right">
                    <Button onClick={onClear} className="btn-sm mb-0">
                      Clear
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      )}

      {!isEmptyResults && (
        <Col md={12}>
          {results?.status_code === 200 && (
            <AttendanceSummarySearchResult data={results} />
          )}

          {results?.status_code === 404 && <div>{results?.message}</div>}
        </Col>
      )}
    </>
  );
};

export default AttendanceReportSearch;
