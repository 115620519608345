import { useMutation, useQuery } from "@apollo/client";
import { IconButton } from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import jwt from "jsonwebtoken";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Col, Row } from "reactstrap";
import { UPDATE_GENERIC_INFO } from "../../../../GraphQL/Mutation";
import { LOAD_FARMER } from "../../../../GraphQL/Queries";
import { farmingAdd } from "../../../../redux/actions/farmerAction";

const PersonalInfo = ({ farmer }) => {
  const history = useHistory();
  const [farmers, setFarmers] = useState({});
  //get token from the local storage;
  const token = localStorage?.getItem("token");
  let user;
  //verify that token ;
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    user = decoded;
  });
  // get all farmer from the database;
  const LoadFarmer = useQuery(LOAD_FARMER, {
    variables: {
      id: farmer?.id,
    },
  });
  // load all farmer into the setfarmers state
  useEffect(() => {
    if (LoadFarmer?.data?.getGenericInfo) {
      setFarmers(LoadFarmer?.data?.getGenericInfo);
    }
    // updateData.refetch();
  }, [LoadFarmer?.data?.getGenericInfo]);
  const theme = useSelector((state) => state.theme.className);
  // age calculated function
  function getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  const [Update, updateData] = useMutation(UPDATE_GENERIC_INFO);
  const dispatch = useDispatch();
  return (
    <div className="personal-info">
      <Row>
        <Col md={10}>
          <h4 className="bold-text">
            <LightbulbIcon fontSize="small" /> Personal Information
          </h4>
        </Col>
        {(user?.id === farmer?.agent_id || user?.role === "admin") && (
          <Col md={2}>
            <button
              className="inbox__email-btn float-right"
              type="button"
              aria-label="edit"
              onClick={async () => {
                await Update({
                  variables: {
                    ...farmer,
                    status: false,
                    union: Number(farmer?.union),
                  },
                  onQueryUpdated(observableQuery) {
                    // Define any custom logic for determining whether to refetch
                    if (shouldRefetchQuery(observableQuery)) {
                      return observableQuery.refetch();
                    }
                  },
                });
                history.push("/farmer_list");
                dispatch(farmingAdd());
              }}
            >
              <DeleteIcon fontSize="small" />
            </button>
            <button
              className="inbox__email-btn float-right"
              type="button"
              aria-label="edit"
              onClick={() =>
                history.push({
                  pathname: "/account/generic_info",
                  state: {
                    id: farmer?.id ? farmer?.id : "",
                    farmers,
                  },
                })
              }
            >
              <EditIcon fontSize="small" />
            </button>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={6}>
          <div className="profile-info">
            <h5 className="subhead">Full Name</h5>
            <h5> {farmer.name}</h5>
          </div>
        </Col>
        <Col md={6}>
          <div className="profile-info">
            <h5 className="subhead">Father Or Spouse</h5>
            <h5>{farmer.spouse_or_father}</h5>
          </div>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col md={6}>
          <div className="profile-info">
            <h5 className="subhead">Gender</h5>
            <h5> {farmer.sex}</h5>
          </div>
        </Col>
        <Col md={6}>
          <div className="profile-info">
            <h5 className="subhead">Age</h5>
            <h5>{getAge(farmer.age)}</h5>
          </div>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col md={6}>
          <div style={{ display: "flex" }}>
            <div className="profile-info">
              <h5 className="subhead">National ID</h5>
              <h5> {farmer.nid}</h5>
            </div>
            <IconButton className="ml-2 mt-2 nid-check">
              <CheckCircleIcon fontSize="small" />
            </IconButton>
          </div>
        </Col>
        <Col md={6}>
          <div className="profile-info">
            <h5 className="subhead">Education Level</h5>
            <h5>{farmer.education_level}</h5>
          </div>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col md={6}>
          <div className="profile-info">
            <h5 className="subhead">Family Type</h5>
            <h5> {farmer.family_type?.toUpperCase()}</h5>
          </div>
        </Col>
        <Col md={6}>
          <div className="profile-info">
            <h5 className="subhead">Family Type</h5>
            <h5> {farmer.experience}</h5>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PersonalInfo;
