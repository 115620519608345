import React from 'react';
import PropTypes from 'prop-types';
import MenuIcon from 'mdi-react/MenuIcon';
import MoreVertIcon from 'mdi-react/MoreVertIcon';
import VideoIcon from 'mdi-react/VideoIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import StarIcon from 'mdi-react/StarIcon';
import { ContactProps } from '@/shared/prop-types/ChatProps';
import { Badge } from 'reactstrap';

const ChatTopbar = ({ onClick, contact }) => (
  <div className="chat__topbar">
    <button className="chat__topbar-button chat__topbar-button--menu" type="button" onClick={onClick}>
      <MenuIcon className="chat__topbar-button-icon" />
    </button>
    {contact
      && (
        <div className="chat__topbar-contact">
          <p className="chat__topbar-contact-name">{contact.customerInfo.fullname}</p>
          <p className="chat__topbar-contact-post">{contact.customerInfo.email}</p>
        </div>
      )}
    <div className="chat__topbar-right">
      {/* <Badge
        color={
          contact?.projectStatus == "Pending"
            ? "danger"
            : contact?.projectStatus == "On Progress"
              ? "primary"
              : data?.projectStatus == "Completed"
                ? "success"
                : "warning"
        }
      >
        {contact?.projectStatus}
      </Badge> */}
    </div>
  </div>
);

ChatTopbar.propTypes = {
  onClick: PropTypes.func.isRequired,
  contact: ContactProps,
};

ChatTopbar.defaultProps = {
  contact: null,
};

export default ChatTopbar;
