import React from "react";
import PasswordChangeForm from "./PasswordChangeForm";
import { toast } from "react-toastify";
import useUpdateUserPassowrdByUserIdMutation from "../../GraphQL/hooks/mutations/useUpdateUserPassowrdByUserIdMutation";

const ChangePassword = ({ userId, setIsChangePassword }) => {
  const onCancel = () => {
    setIsChangePassword(false);
  };

  const { updateUserPassowrdByUserId, loading } =
    useUpdateUserPassowrdByUserIdMutation();

  const onSubmit = async (data) => {
    const password = data?.password;
    const c_password = data?.c_password;
    if (password !== c_password) {
      toast.error("Password not matched");
      return false;
    }

    const changedData = await updateUserPassowrdByUserId({
      variables: {
        id: userId,
        password,
      },
    });

    const { message, status_code } =
      changedData?.data?.updateUserPassowrdByUserId;

    if (status_code === 202) {
      toast.success(message);
      onCancel();
    } else {
      toast.error(message);
    }
  };

  return (
    <div>
      <PasswordChangeForm
        onCancel={onCancel}
        onSubmit={onSubmit}
        loading={loading}
      />
    </div>
  );
};

export default ChangePassword;
