import React, { useEffect } from "react";
import {
  Badge,
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import { SelectField } from "@/shared/components/form/Select";
import FormField from "./FormField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "react-datepicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@mui/material";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { Box } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { IsThereWeekend, loginInfo } from "../../../utils/helpers";
import moment from "moment";
import {
  CREATE_LEAVE,
  CREATE_PENALTY,
  CREATE_PENALTY_REVIEW,
  UPDATE_PENALTY,
} from "../../../GraphQL/Mutation";
import { useMutation, useQuery } from "@apollo/client";
import { successMessage, warningMessage } from "../../ReactToastify";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import Swal from "sweetalert2";
import { GET_PENALTY_BY_ID } from "../../../GraphQL/Queries";
import {
  badgeColor,
  actionStatusCode,
  attendanceTypeArray,
} from "../../../utils/utilities";
// review reason array
export const reviewReasons = [
  {
    value: 1,
    label: `Forgot to punch`,
  },
  {
    value: 2,
    label: `Forgot to bring ID card`,
  },
  {
    value: 3,
    label: `Forgot to web attendance`,
  },
];

const PenaltyForm = () => {
  const { handleSubmit, control, watch } = useForm();

  // variables
  const loggedInfo = loginInfo();
  const history = useHistory();
  const updateData = useParams();
  // state
  const [date, setDate] = useState(
    updateData?.date ? new Date(updateData?.date) : null
  );
  const [errorMessage, setErrorMessage] = useState({}); // form validation error message

  // mutations
  const [createPenaltyReview, createPenaltyReturn] = useMutation(
    CREATE_PENALTY_REVIEW
  );
  const [updatePenalty, updatePenaltyReturn] = useMutation(UPDATE_PENALTY);

  // queries
  const loadPenalty = useQuery(GET_PENALTY_BY_ID, {
    skip: !updateData?.id,
    variables: {
      id: Number(updateData?.id),
    },
  });
  // refetch penalty
  useEffect(() => {
    loadPenalty?.refetch();
  }, [loadPenalty?.data?.getPenaltyById]);

  // for validation required message clear
  const errorMessageClear = (fieldName, dependency) => {
    useEffect(() => {
      if (fieldName) {
        setErrorMessage((prevState) => ({
          ...prevState,
          [fieldName]: "",
        }));
      }
    }, [dependency]);
  };
  errorMessageClear("reason", watch("reason"));
  errorMessageClear("type", watch("type"));
  errorMessageClear("date", date);

  // form validation function
  const filedErrorMessage = (filedName, filedValue) => {
    if (filedValue === undefined || filedValue === null || filedValue === "") {
      setErrorMessage((prevState) => ({
        ...prevState,
        [filedName]: "This field is required!",
      }));
    }
  };
  // is form valid function
  const isFormValid = (value) => {
    filedErrorMessage("reason", value?.reason?.value);
    filedErrorMessage("type", value?.type?.value);
    filedErrorMessage("date", date);
    if (date && value?.reason?.value && value?.type?.value) {
      return true;
    } else {
      return false;
    }
  };

  // submit form
  const onSubmit = async (value) => {
    const formData = {
      penalty_id: Number(updateData?.id),
      attendance_type: Number(value?.type?.value),
      attendance_reason: Number(value?.reason?.value),
    };
    // if form is valid
    if (isFormValid(value)) {
      const result = await createPenaltyReview({
        variables: formData,
      });
      if (result?.data?.createPenaltyReview?.result_code == "000") {
        Swal.fire(
          "Applied!",
          result?.data?.createPenaltyReview?.status,
          "success"
        );
        // redirect to holydays list
        history.push({ pathname: "/leave/penalty" });
      }
      if (result?.data?.createPenaltyReview?.result_code == "002") {
        Swal.fire(result?.data?.createPenaltyReview?.status, "", "error");
      }
    }
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Col md={12}>
            <h3 className="page-title">Review a penalty</h3>
          </Col>

          {loadPenalty?.loading ? ( // is loading penalty
            "loading"
          ) : loadPenalty?.data?.getPenaltyById?.user_id == loggedInfo?.id &&
            loadPenalty?.data?.getPenaltyById?.penalty_date ==
              updateData?.date ? ( // if login user valid
            loggedInfo?.id &&
            loadPenalty?.data?.getPenaltyById?.reviewPenaltyInfo?.penalty_id ==
              updateData?.id ? ( // if penalty review status in progress
              <Col md={6} className="mx-auto text-center py-5">
                <h5 className="timeline__title mb-3">
                  Your application already{" "}
                  <Badge
                    color={
                      badgeColor?.find(
                        (status) =>
                          status?.code ==
                          loadPenalty?.data?.getPenaltyById?.penalty_status
                      )?.name
                    }
                  >
                    {
                      actionStatusCode?.find(
                        (status) =>
                          status?.code ==
                          loadPenalty?.data?.getPenaltyById?.penalty_status
                      )?.name
                    }
                  </Badge>
                  !
                </h5>
                {/* <h4 className="subhead timeline__date">at {moment(new Date(Number(loadPenalty?.data?.getPenaltyById?.updatedAt))).format("YYYY-MM-DD HH:mm:ss a")}</h4> */}
                <p>
                  You are claim a review for the date of
                  <b>
                    {" "}
                    {loadPenalty?.data?.getPenaltyById?.penalty_date}{" "}
                    {reviewReasons
                      ?.find(
                        (item) =>
                          item?.value ==
                          loadPenalty?.data?.getPenaltyById?.reviewPenaltyInfo
                            ?.attendance_reason
                      )
                      ?.label?.toLocaleLowerCase()}
                  </b>{" "}
                  give{" "}
                  {"aeiou".includes(
                    attendanceTypeArray
                      ?.find(
                        (item) =>
                          item?.value ==
                          loadPenalty?.data?.getPenaltyById?.reviewPenaltyInfo
                            ?.attendance_type
                      )
                      ?.label?.toLocaleLowerCase()[0]
                  )
                    ? "an "
                    : "a "}
                  <b>
                    {attendanceTypeArray
                      ?.find(
                        (item) =>
                          item?.value ==
                          loadPenalty?.data?.getPenaltyById?.reviewPenaltyInfo
                            ?.attendance_type
                      )
                      ?.label?.toLocaleLowerCase()}
                  </b>{" "}
                  attendance.
                </p>
              </Col>
            ) : (
              <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <Col md={6} className="mx-auto">
                  <Row>
                    <Col md={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Review Date
                        </span>
                        <div className="form__form-group-field leave-calender">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select date"
                            selected={date}
                            onChange={(update) => {
                              setDate(update);
                            }}
                            showWeekNumbers
                            disabled={updateData?.id ? true : false}
                          />
                        </div>
                        {errorMessage?.date ? (
                          <span className="text-danger">
                            <b>{errorMessage?.date}</b>
                          </span>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Reason of not having attendance
                        </span>
                        <div className="form__form-group-field ">
                          <FormField
                            name="reason"
                            control={control}
                            component={SelectField}
                            options={reviewReasons}
                            defaultValue={
                              updateData?.id &&
                              reviewReasons?.find(
                                (item) => item?.label == updateData?.reason
                              )
                            }
                            placeholder="Select reason"
                          />
                        </div>
                        {errorMessage?.reason ? (
                          <span className="text-danger">
                            <b>{errorMessage?.reason}</b>
                          </span>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Attendance type
                        </span>
                        <div className="form__form-group-field ">
                          <FormField
                            name="type"
                            control={control}
                            component={SelectField}
                            options={attendanceTypeArray?.slice(0, 3)}
                            defaultValue={
                              updateData?.id &&
                              attendanceTypeArray?.find(
                                (item) => item?.label == updateData?.type
                              )
                            }
                            placeholder="Select attendance type"
                          />
                        </div>
                        {errorMessage?.type ? (
                          <span className="text-danger">
                            <b>{errorMessage?.type}</b>
                          </span>
                        ) : null}
                      </div>
                    </Col>

                    <Col xs={12}>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <ButtonToolbar className="form__button-toolbar">
                          <Button
                            type="button"
                            onClick={() => history.push("/leave/penalty")}
                          >
                            {" "}
                            Cancel{" "}
                          </Button>
                          {createPenaltyReturn?.loading ? (
                            <Button color="primary" type="submit">
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              {"Applying..."}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Apply"}
                            </Button>
                          )}
                        </ButtonToolbar>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </form>
            )
          ) : (
            <Col md={6} className="mx-auto text-center py-5">
              <span
                style={{ fontSize: 100 }}
                className="lnr lnr-warning"
              ></span>
              <h5 className="timeline__title mb-3 text-danger mt-4">
                You are not valid user for penalty review.
              </h5>

              <span
                type="button"
                onClick={() => history.push("/leave/penalty")}
              >
                <span className="lnr lnr-arrow-left-circle"></span> Back
              </span>
            </Col>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default PenaltyForm;
