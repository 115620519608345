import React from "react";
import { Badge, Col } from "reactstrap";

const SwitchButton = ({
  switchState,
  switchController,
  switchNumber,
  onLoading,
}) => {
  return (
    <Col lg={6} className="mb-3">
      <div className="card border px-2 py-4 align-items-center shadow-sm ">
        <img
          onClick={() => switchController(switchNumber)}
          src={
            switchState == true
              ? "https://www.w3schools.com/js/pic_bulbon.gif"
              : switchState == false
              ? "https://www.w3schools.com/js/pic_bulboff.gif"
              : "https://static.vecteezy.com/system/resources/previews/009/408/676/original/light-bulb-transparent-free-png.png"
          }
          style={{ width: 100 }}
          type="button"
        />
        <h6 className="mt-2">
          <Badge
            style={{ fontSize: 15 }}
            className="mt-3"
            color="warning"
            size="small"
          >
            Load {switchNumber}
          </Badge>
        </h6>
        {onLoading?.switch === switchNumber && onLoading?.loading
          ? "Processing..."
          : ""}
      </div>
    </Col>
  );
};

export default SwitchButton;
