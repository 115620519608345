import React from "react";
import AttendanceReportSearch from "./Search";

const AttendanceReport = () => {
  return (
    <AttendanceReportSearch />
  );
};

export default AttendanceReport;
