import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import AttendanceReportUserProfile from "./Components/UserProfile";
import AttendanceReportTimeSlotSummary from "./Components/TimeSlotSummary";
import AttendanceReportWorkingTime from "./Components/WorkingTime";
import AttendanceReportWorkingHistory from "./Components/WorkingHistory";

const AttendanceSummarySearchResult = ({ data }) => {
  return (
    <Row>
      <Col md={4}>
        <Card>
          <CardBody>
            <AttendanceReportUserProfile data={data?.user} />
            <hr />
          </CardBody>
        </Card>
      </Col>
      <Col md={8}>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <AttendanceReportTimeSlotSummary data={data} />
              </CardBody>
            </Card>
          </Col>
          <Col md={12}>
            <Card>
              <CardBody>
                <AttendanceReportWorkingTime data={data?.workingHour} />
              </CardBody>
            </Card>
          </Col>
          <Col md={12}>
            <Card>
              <CardBody>
                <AttendanceReportWorkingHistory
                  data={data?.workingHour?.work_hour_report_history}
                  workingHour={data?.workingHour}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AttendanceSummarySearchResult;
