import moment from "moment";
import { useState } from "react";
import { Table, Badge, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Pagination } from "@mui/material";
import { usePagination } from "../../../utils/helpers";

const ReportsTable = ({ allLeaves }) => {
  const [email, setEmail] = useState({});
  const [modal, setModal] = useState(false);
  const modalHandler = () => {
    setModal((prevSate) => !prevSate);
  };

  const leaveTypeList = [
    {
      value: 1,
      label: "Casual",
    },
    {
      value: 2,
      label: "Anual",
    },
    {
      value: 3,
      label: "Sick",
    },
    {
      value: 4,
      label: "Menstrual",
    },
    {
      value: 5,
      label: "Maternity",
    },
    {
      value: 6,
      label: `Reward`,
    },
  ];
  // attendance type array
  const attendanceTypeArray = [
    {
      value: 101,
      label: `Work From Home`,
    },
    {
      value: 102,
      label: `On Field Duty`,
    },
    {
      value: 201,
      label: `On Leave`,
    },
  ];

  // material pagination
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(allLeaves?.length / PER_PAGE);
  const pageData = usePagination(allLeaves, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    pageData.jump(p);
  };

  return (
    <Table role="table" striped hover responsive>
      <thead>
        <tr role="row">
          <th>#</th>
          <th>Name</th>
          <th>Leave Start</th>
          <th>Leave End</th>
          <th>Day</th>
          <th>Leave Type</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {pageData?.currentData()?.length ? (
          pageData?.currentData()?.map((item) => (
            <tr
              onClick={() => {
                modalHandler();
                setEmail(item);
              }}
              key={item?.indexId ? item.indexId : index + 1}
            >
              <td>{item?.indexId ? item.indexId : index + 1}</td>
              <td>
                {item?.user?.name} ({item?.user?.username})
              </td>
              <td>{moment(item?.start_date).format("LL")}</td>
              <td>{moment(item?.end_date).format("LL")}</td>
              <td>
                {moment
                  .duration(
                    new Date(item?.end_date) - new Date(item?.start_date)
                  )
                  .asDays() + 1}{" "}
                {moment
                  .duration(
                    new Date(item?.end_date) - new Date(item?.start_date)
                  )
                  .asDays() +
                  1 >
                1
                  ? "Days"
                  : "Day"}
              </td>
              <td>
                {
                  leaveTypeList.find(
                    (leaveItem) => leaveItem?.value === item?.leave_type
                  )?.label
                }
                {
                  attendanceTypeArray?.find(
                    (attendanceType) =>
                      attendanceType?.value == item?.leave_type
                  )?.label
                }
              </td>
              <td>
                {item?.leave_status == "Pending" ||
                item?.leave_status == "Denied" ? (
                  <Badge color="danger">{item?.leave_status}</Badge>
                ) : (
                  <Badge color="success">{item?.leave_status}</Badge>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9" className="text-center text-success">
              <b>There is no leave...</b>
            </td>
          </tr>
        )}
      </tbody>

      <Pagination count={count} page={page} onChange={handleChange} />

      {/* modal for leave view in details */}
      <Modal toggle={modalHandler} isOpen={modal}>
        <ModalHeader toggle={modalHandler}>
          <div className="inbox__email-left">
            <div className="inbox__email-ava mr-3">
              <img
                src={
                  email?.user?.images
                    ? email?.user?.images
                    : "https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png"
                }
                alt=""
              />
            </div>
            <div className="inbox__email-info">
              <h5 className="inbox__email-name" style={{ fontSize: 20 }}>
                {email?.user?.name} ({email?.user?.additionalInfo?.office_id})
              </h5>
              <p className="inbox__email-email mt-1" style={{ fontSize: 14 }}>
                {email?.user?.email}
              </p>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <p className="mb-1">
            {moment(new Date(Number(email?.createdAt))).format("DD MMMM YYYY")}
          </p>
          <div className="flex justify-content-between">
            <h4>
              <b>
                Application for{" "}
                {
                  leaveTypeList?.find(
                    (item) => item?.value == email?.leave_type
                  )?.label
                }
                {
                  attendanceTypeArray?.find(
                    (attendanceType) =>
                      attendanceType?.value == email?.leave_type
                  )?.label
                }{" "}
                leave
              </b>
            </h4>
            <div>
              {email?.leave_status == "Pending" ||
              email?.leave_status == "Denied" ? (
                <Badge color="danger">{email?.leave_status}</Badge>
              ) : (
                <Badge color="success">{email?.leave_status}</Badge>
              )}
            </div>
          </div>
          <p>
            <b>Start: </b>
            {moment(email?.start_date).format("DD MMMM YYYY")}
          </p>
          <p>
            <b>End: </b>
            {moment(email?.end_date).format("DD MMMM YYYY")}
          </p>
          <p>
            <b>Total: </b>
            {moment
              .duration(new Date(email?.end_date) - new Date(email?.start_date))
              .asDays() + 1}
            {moment
              .duration(new Date(email?.end_date) - new Date(email?.start_date))
              .asDays() +
              1 >
            1
              ? " Days"
              : " Day"}
          </p>
          <p className="mt-3">
            <b>
              The reason of leave: <br />
            </b>{" "}
            {email?.leave_reason}
          </p>
          {email?.deny_reason ? (
            <div className="mt-3 bg-danger text-white rounded px-3 py-2">
              <h4>The application has been denied!</h4>
              <p>The reason of denied is {email?.deny_reason}</p>
            </div>
          ) : null}
        </ModalBody>
      </Modal>
    </Table>
  );
};

export default ReportsTable;
