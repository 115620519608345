import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Badge, Card, CardBody, Col, Modal, Table } from "reactstrap";
import { GET_ALL_ROLE } from "../../GraphQL/Queries";
import AddRole from "./AddRole";
import EditRole from "./EditRole";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { usePagination } from "../../utils/helpers";
import { Pagination } from "@mui/material";
import { decryption } from "../HelperFunctions";
import { ROLE_DELETED_STATUS_UPDATE } from "../../GraphQL/Mutation";
import { successMessage, warningMessage } from "../ReactToastify";
import Swal from "sweetalert2";
import { capitalize } from "@material-ui/core";

const Roles = () => {
  const getAllRole = useQuery(GET_ALL_ROLE);
  const [editData, setEditData] = useState();
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteRoleById] = useMutation(ROLE_DELETED_STATUS_UPDATE)


  const [allRoles, setAllRoles] = useState([]);
  useEffect(() => {
    if (getAllRole?.data?.getRoles?.length) {
      setAllRoles(getAllRole?.data?.getRoles);
      getAllRole.refetch();
    }
  }, [getAllRole?.data?.getRoles?.length])
  // delete permission
  const deleteRole = async (id) => {
    Swal.fire({
      title: 'Are you sure you want to delete this role?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      }
    }).then(async (result) => {
      if (result.isConfirmed) {

        const result = await deleteRoleById({
          variables: {
            id: id
          }
        })
        getAllRole?.refetch();
        if (result?.data?.roleDeletedStatusUpdate?.result_code == "002") {
          Swal.fire(result?.data?.roleDeletedStatusUpdate?.status, '', 'error');
        }
        if (result?.data?.roleDeletedStatusUpdate?.result_code == "000") {
          Swal.fire(result?.data?.roleDeletedStatusUpdate?.status, '', 'success');
        }

      }
    })
  }
  const toggle = () => {
    setModal((prevState) => !prevState);
  };
  const editToggle = () => {
    setEditModal((prevState) => !prevState);
  };
  // material pagination
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(allRoles?.length / PER_PAGE);
  const pageData = usePagination(allRoles, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    pageData.jump(p);
  };
  // permission
  const permissions = decryption(JSON.parse(localStorage?.getItem("permissions")));
  return (
    <Col>
      <Card>
        <CardBody>
          <div>
            <div className="float-left">
              {" "}
              <h3 className="page-title">Role Lists</h3>
            </div>
            <div className="float-right">
              {permissions?.includes("create_roles") && <button className="btn btn-success" onClick={toggle}>
                {" "}
                + New Role
              </button>}
            </div>
          </div>
          <Table role="table" striped hover responsive>
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Name</th>
                <th style={{ width: '350px' }}>Permissions</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {pageData?.currentData()?.map((data) => (
                <tr key={data?.id}>
                  <th scope="row">{data?.id}</th>
                  <td>{data.role_name}</td>
                  <td style={{ width: '450px' }}>
                    {data.permissions?.map((permission) => {
                      return <Badge className="m-1">{capitalize(permission.permission_name?.split('_')?.join(' '))}{
                      } </Badge>;
                    })}
                  </td>
                  <td>
                    {permissions?.includes("delete_roles") && <button
                      className="inbox__email-btn"
                      type="button"
                      aria-label="edit"
                      onClick={() => deleteRole(data?.id)}
                    >
                      <DeleteIcon fontSize="small" />
                    </button>}
                    {permissions?.includes("update_roles") && <button
                      className="inbox__email-btn mr-1"
                      type="button"
                      aria-label="edit"
                      onClick={() => {
                        setEditData(data);
                        editToggle();
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </button>}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            count={count}
            page={page}
            onChange={handleChange}
          />
        </CardBody>
      </Card>

      <Modal
        isOpen={modal}
        toggle={toggle}
        animation={true}
        centered
        className="theme-light ltr-support login-modal-form"
      >
        <AddRole toggle={toggle} refetch={getAllRole.refetch} />
      </Modal>

      <Modal isOpen={editModal} toggle={editToggle} animation={true} centered>
        <EditRole
          role={editData}
          toggle={editToggle}
          refetch={getAllRole.refetch}
          onCloseEdit={editToggle}
        />
      </Modal>
    </Col>
  );
};

export default Roles;
