import React from "react";
import { useForm } from "react-hook-form";
import { Button, ButtonToolbar, Col } from "reactstrap";
import FormField from "./FormField";
import PasswordField from "@/shared/components/form/Password";

const PasswordChangeForm = ({
  onCancel = () => {},
  onSubmit = () => {},
  loading = false,
}) => {
  const { handleSubmit, control, watch, reset, errors } = useForm();
  console.log(errors);
  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <Col md={12}>
        <div className="form__form-group">
          <span className="form__form-group-label">New Password</span>
          <div className="form__form-group-field password">
            <FormField
              name="password"
              control={control}
              component={PasswordField}
              errors={errors}
              rules={{ required: "This is required field" }}
              placeholder="New Password"
            />
          </div>
        </div>
      </Col>
      <Col md={12}>
        <div className="form__form-group">
          <span className="form__form-group-label">Confirm Password</span>
          <div className="form__form-group-field password">
            <FormField
              name="c_password"
              control={control}
              component={PasswordField}
              errors={errors}
              rules={{ required: "This is required field" }}
              placeholder="Confirmf Password"
            />
          </div>
        </div>
      </Col>

      <Col xs={12}>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <ButtonToolbar className="form__button-toolbar">
            <Button
              disabled={loading}
              onClick={onCancel}
              className="btn-sm ml-3"
              color="danger"
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              className="btn-sm ml-3"
              color="primary"
              type="submit"
            >
              {loading ? "Changing..." : "Change"}
            </Button>
          </ButtonToolbar>
        </div>
      </Col>
    </form>
  );
};

export default PasswordChangeForm;
