import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Badge, Button, ButtonToolbar, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { SelectField } from '@/shared/components/form/Select';
import FormField from './FormField';
import { checkBoxValueRetrive } from '../../../utils/helpers';
import { decryption } from '../../HelperFunctions';
import { GET_ALL_SERVICE_TYPE } from '../../../GraphQL/Queries';
import { useQuery } from '@apollo/client';

const ProposalContainer = ({ data, setImageArray2, openImageViewer2 }) => {

    useEffect(() => {
        // const extension = item?.url?.split('.')[item?.url?.split('.')?.length - 1];
        // const thumb = ["pdf", "PDF", "doc", "docx", "ppt"];
        // thumb?.includes(extension)

        // data?.images && JSON.parse(data?.images)?.map((item) => {

        // })


        setImageArray2(data?.images && JSON.parse(data?.images)?.map((item) => item?.url))
    }, [data])



    // roles
    const roles = decryption(JSON.parse(localStorage?.getItem("roles")));
    const [serviceType, setServiceType] = useState([]);

    // queries
    const loadAllService = useQuery(GET_ALL_SERVICE_TYPE);

    useEffect(() => {
        const list = data?.service_type ? JSON.parse(data?.service_type) : null;

        let service = [];
        if (list) {
            list?.map(item => {
                loadAllService?.data?.getAllServiceType?.find(value => {
                    if (value?.id == item) {
                        service.push(value?.name);
                    }
                })
            })
            setServiceType(service)
        }


    }, [data?.id]);

    // array for type
    const clientTypesRadioArray = [
        {
            label: 'Individual',
            name: 'cilent_type',
            radioValue: 1
        },
        {
            label: 'Company',
            name: 'cilent_type',
            radioValue: 2
        }
    ].find(item => item.radioValue === data?.client_type)?.label;

    const projectType = data?.project_type ? JSON.parse(data?.project_type)[0] : null;
    const projectTypeValue = data?.project_type ? JSON.parse(data?.project_type)[1] : null;

    const type = [
        {
            name: 'project_type',
            label: 'Software',
            radioValue: 1
        },
        {
            name: 'project_type',
            label: 'Hardware',
            radioValue: 2
        },
    ]?.find(item => item?.radioValue == projectType)?.label;

    const software = [
        {
            label: "Web",
            value: 1
        },
        {
            label: "Mobile",
            value: 2
        },
    ]?.find(item => item?.value == projectTypeValue)?.label;
    const hardware = [
        {
            label: "Electronics",
            value: 1
        },
        {
            label: "Power/Renewable",
            value: 2
        },
    ]?.find(item => item?.value == projectTypeValue)?.label;
    return (
        <div >
            <div className="text-modal">Title: {data?.title}</div>
            <div className="subhead timeline__date mt-2">{moment(new Date(Number(data?.createdAt))).format("dddd, Do MMMM YYYY, h:mm:ss a")} by {data?.customerInfo?.fullname}</div>
            <div className="modal__body">
                <p>
                    <b>Status: </b>
                    <Badge
                        color={
                            data?.projectStatus == "Pending"
                                ? "danger"
                                : data?.projectStatus == "In Progress"
                                    ? "primary"
                                    : data?.projectStatus == "Completed"
                                        ? "success"
                                        : "warning"
                        }
                    >
                        {data?.projectStatus}
                    </Badge>
                </p>
                <p>
                    <b>Project Type: </b>
                    <span className="text-capitalize">{type} → {type == "Software" ? software : null} {type == "Hardware" ? hardware : null}</span>
                </p>
                <p>
                    <b>Client Type: </b>
                    <span className="text-capitalize">{clientTypesRadioArray}</span>
                </p>
                <p>
                    <b>Service Type:</b> {serviceType?.join(", ")}
                </p>
                {data?.end ? <p>
                    <b>Duration:</b> {moment(data?.start).format('DD/MM/YYYY')}
                    &nbsp; to &nbsp;
                    {moment(data?.end).format('DD/MM/YYYY')}
                    &nbsp;
                    (
                    {moment(data?.end).diff(moment(data?.start), 'days')}
                    {moment(data?.end).diff(moment(data?.start), 'days') > 1 ? ' days' : ' day'}
                    )
                </p>
                    : <p> <b>Start:</b> {moment(data?.start).format('DD/MM/YYYY')} </p>}

                <p><b>Description:</b> {data?.description}</p>

                {data?.images && JSON.parse(data?.images)?.length ? <p><b>Attachment:</b></p> : null}
                {
                    <div className="upload-img-container d-flex flex-wrap">
                        {data?.images && JSON.parse(data?.images)?.map((item, index) => {
                            const extension = item?.url?.split('.')[item?.url?.split('.')?.length - 1];
                            const thumb = ["pdf", "PDF", "doc", "docx", "ppt"];
                            return (
                                thumb?.includes(extension)
                                    ? <div className="position-relative mt-3 mr-3">
                                        <img className="upload-img" src="https://cutewallpaper.org/24/file-png/collections.png" alt="image" ></img>
                                        <a className="position-absolute prev-download-btn" href={item?.url} download><span class="lnr lnr-download"></span></a>
                                    </div>
                                    : <div className="position-relative mt-3 mr-3">
                                        <img onClick={() => openImageViewer2(index)} className="upload-img" src={item?.url} alt="image" ></img>
                                        <a className="position-absolute prev-download-btn" href={item?.url} download><span class="lnr lnr-download"></span></a>
                                    </div>
                            )
                        })}
                    </div>
                }
            </div>


        </div >
    );
};

export default ProposalContainer;