import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Modal, Table } from "reactstrap";
import {
  DELETE_PERMISSION_BY_ID,
  PERMISSION_DELETED_STATUS_UPDATE,
} from "../../GraphQL/Mutation";
import { GET_ALL_PERMISSIONS } from "../../GraphQL/Queries";
import AddPermission from "./AddPermission";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Pagination } from "@mui/material";
import { usePagination } from "../../utils/helpers";
import { decryption } from "../HelperFunctions";
import { successMessage, warningMessage } from "../ReactToastify";
import Swal from "sweetalert2";
import { capitalize } from "@material-ui/core";

const Permission = () => {
  const getAllPermission = useQuery(GET_ALL_PERMISSIONS);
  const [deletePermissionById] = useMutation(PERMISSION_DELETED_STATUS_UPDATE);
  const [modal, setModal] = useState(false);
  const [allPermissions, setAllPermissions] = useState([]);
  useEffect(() => {
    if (getAllPermission?.data?.getPermissions?.length) {
      setAllPermissions(getAllPermission?.data?.getPermissions);
      getAllPermission.refetch();
    }
  }, [getAllPermission?.data?.getPermissions?.length]);
  const toggle = () => {
    setModal((prevState) => !prevState);
  };
  // delete permission
  const deletePermission = async (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this permission?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await deletePermissionById({
          variables: {
            id: id,
          },
        });
        getAllPermission?.refetch();
        if (result?.data?.permissionDeletedStatusUpdate?.result_code == "002") {
          Swal.fire(
            result?.data?.permissionDeletedStatusUpdate?.status,
            "",
            "error"
          );
        }
        if (result?.data?.permissionDeletedStatusUpdate?.result_code == "000") {
          Swal.fire(
            result?.data?.permissionDeletedStatusUpdate?.status,
            "",
            "success"
          );
        }
      }
    });
  };

  // material pagination
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(allPermissions?.length / PER_PAGE);
  const pageData = usePagination(allPermissions, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    pageData.jump(p);
  };
  // permission
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );

  return (
    <Col>
      <Card>
        <CardBody>
          <div>
            <div className="float-left">
              {" "}
              <h3 className="page-title">Permission Lists</h3>
            </div>
            <div className="float-right">
              {permissions?.includes("create_permission") && (
                <button className="btn btn-success" onClick={toggle}>
                  {" "}
                  + New Permissions
                </button>
              )}
            </div>
          </div>
          <Table role="table" striped hover responsive>
            <thead>
              <tr role="row">
                <th>#</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {pageData?.currentData()?.map((data) => (
                <tr key={data?.id} role="row">
                  <th scope="row">{data?.id}</th>
                  <td>{capitalize(data?.permission_name.replace("_", " "))}</td>
                  <td>
                    {permissions?.includes("delete_permission") && (
                      <button
                        className="inbox__email-btn"
                        type="button"
                        aria-label="edit"
                        onClick={() => deletePermission(data?.id)}
                      >
                        <DeleteIcon fontSize="small" />
                      </button>
                    )}
                    {/* {permissions?.includes("update_permission") && (
                      <button
                        className="inbox__email-btn mr-1"
                        type="button"
                        aria-label="edit"
                        onClick={() => toggle()}
                      >
                        <EditIcon fontSize="small" />
                      </button>
                    )} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Pagination count={count} page={page} onChange={handleChange} />
        </CardBody>
      </Card>

      <Modal
        isOpen={modal}
        toggle={toggle}
        animation={true}
        centered
        className="theme-light ltr-support login-modal-form"
      >
        <AddPermission toggle={toggle} refetch={getAllPermission.refetch} />
      </Modal>
    </Col>
  );
};

export default Permission;
