import { PENDING_LEAVE_ACTION } from "../actions/pendingLeave";

const initialState = {
  email: {},
};

export const pendingLeaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case PENDING_LEAVE_ACTION:
      return {
        ...state,
        email: action?.payload,
      };

    default:
      return state;
  }
};
