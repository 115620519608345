import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { ContactProps, MessageProps } from '@/shared/prop-types/ChatProps';
import ChatRight from './ChatRight';
import ChatLeft from './ChatLeft';

const bubbleClass = (active) => {
  classNames({
    chat__bubble: true,
    'chat__bubble--active': active,
  });
};

const ChatBubble = ({ contact, message, active, loggedInfo, openImageViewer, setImageArray, imageArray }) => {
  const scrollRef = useRef(null);
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth", block: 'end', });
  }, [contact]);
  return (
    <div className={bubbleClass(active)}>
      <div className="chat-history">
        <ul className="m-b-0">
          {contact?.map(item => loggedInfo?.id == item?.customer_id ?
            <ChatRight imageArray={imageArray} setImageArray={setImageArray} openImageViewer={openImageViewer} comment={item} />: <ChatLeft imageArray={imageArray} setImageArray={setImageArray} openImageViewer={openImageViewer} comment={item} />)}
            
       
        </ul>
      </div>
      <div ref={scrollRef}/>
    </div>
  );
};

export default ChatBubble;
