import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import FmdGoodIcon from '@mui/icons-material/FmdGood';

const AddressInfo = ({ farmer }) => {
  const theme = useSelector((state) => state.theme.className);
  const defaultDistrict = JSON.parse(localStorage?.getItem("district"))?.find(
    (district) => district?.id == farmer?.district
  );
  const defaultDivision = JSON.parse(localStorage?.getItem("division"))?.find(
    (division) => division?.id == farmer?.division
  );
  const defaultUpazilla = JSON.parse(localStorage?.getItem("upazilla"))?.find(
    (upazilla) => upazilla?.id == farmer?.upazilla
  );
  const defaultUnion = JSON.parse(localStorage?.getItem("union"))?.find(
    (union) => union?.id == farmer?.union
  );
  return (
    <div className="personal-info" style={{ marginTop: "20px" }}>
      <div>
        <h4 className="bold-text"><FmdGoodIcon fontSize="small" /> Address Information</h4>
      </div>

      <Row className="mt-1">
        <Col md={6}>
          <div className="profile-info">
            <h5 className="subhead">Division</h5>
            <h5>{defaultDivision?.name}</h5>
          </div>
        </Col>
        <Col md={6}>
          <div className="profile-info">
            <h5 className="subhead">District</h5>
            <h5>{defaultDistrict?.name}</h5>
          </div>
        </Col>
      </Row>

      <Row className="mt-1">
        <Col md={6}>
          <div className="profile-info">
            <h5 className="subhead">Upazilla</h5>
            <h5>{defaultUpazilla?.name}</h5>
          </div>
        </Col>
        <Col md={6}>
          <div className="profile-info">
            <h5 className="subhead">Union</h5>
            <h5>{defaultUnion?.name}</h5>
          </div>
        </Col>
      </Row>

      <Row className="mt-1">
        <Col md={12}>
          <div className="profile-info">
            <h5 className="subhead">Village</h5>
            <h5>{farmer?.village}</h5>
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <ul>
            <div className="details">
              <li className="title">Division:</li>
              <li
                className="value"
                style={{ color: theme === "theme-dark" && "#cecece" }}
              >
                {defaultDivision?.name}
              </li>
            </div>
            <div className="details">
              <li className="title">Union:</li>
              <li
                className="value"
                style={{ color: theme === "theme-dark" && "#cecece" }}
              >
                {defaultUnion?.name}
              </li>
            </div>
          </ul>
        </Col>
        <Col>
          <ul>
            <div className="details">
              <li className="title">District:</li>
              <li
                className="value"
                style={{ color: theme === "theme-dark" && "#cecece" }}
              >
                {defaultDistrict?.name}
              </li>
            </div>
            <div className="details">
              <li className="title">Village:</li>
              <li
                className="value"
                style={{ color: theme === "theme-dark" && "#cecece" }}
              >
                {farmer.village}
              </li>
            </div>
          </ul>
        </Col>
        <Col>
          <ul>
            <div className="details">
              <li className="title">Upazilla:</li>
              <li
                className="value"
                style={{ color: theme === "theme-dark" && "#cecece" }}
              >
                {defaultUpazilla?.name}
              </li>
            </div>
          </ul>
        </Col>
      </Row> */}
    </div>
  );
};

export default AddressInfo;
