import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/react";

const TableDataLoading = ({colSpan}) => {
    // loading component
    let color = "rgb(54, 215, 183)";
    const override = css`
        margin: 0 auto;
        display: block;
        width: 100px;
    `;
    return (
        <tr>
            <td colSpan={colSpan}><PropagateLoader color={color} css={override} loading={true} size={10} /></td>
        </tr>
    );
};

export default TableDataLoading;