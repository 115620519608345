/* eslint-disable react/jsx-indent */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import NotFound404 from "../../../DefaultPage/404";
import { SwitchBoard } from "../../../IotSwitch";

export default () => {
  const token = localStorage?.getItem("token");
  return (
    <Switch>
      <Route
        exact
        path="/iot/switch-board"
        render={() =>
          token !== null ? <SwitchBoard /> : <Redirect to="/log_in" />
        }
      />
      
      <Route path="/iot" component={NotFound404} />
    </Switch>
  );
};
