import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Department, DeptMember, NewDepartment, NewDeptMember } from "../../../Department";
import { decryption } from "../../../HelperFunctions";

export default () => {
  const permission = JSON.parse(localStorage?.getItem("permissions"));

  return (
    <Switch>
      <Route
        path="/department/list"
        render={() =>
          decryption(permission)?.includes("view_department") ? <Department /> : <Redirect to="/404" />
        }
      />
      <Route
        path="/department/new"
        render={() =>
          decryption(permission)?.includes("create_department") ? <NewDepartment /> : <Redirect to="/404" />
        }
      />
      <Route
        path="/department/member"
        render={() =>
          decryption(permission)?.includes("view_department_member") ? <DeptMember /> : <Redirect to="/404" />
        }
      />
    </Switch>
  );
};
