import { useQuery } from "@apollo/client";
import jwt from "jsonwebtoken";
import TabPlusIcon from "mdi-react/TabPlusIcon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Input, Modal, ModalBody } from "reactstrap";
import { GET_ALL_PESTICIDE } from "../../../../GraphQL/Queries";
// import FarmingDetails from "./FarmingDetails";
import FarmingDetailsCard from "./FarmingDetailsCard";
import FarmingPracticeEdit from "./FarmingPracticeEdit";
const FarmingPractice = ({ farmer, gen_id, creator_id }) => {
  const [modal, setModal] = useState(false);
  const [farmingModal, setFarmingModal] = useState(false);
  const [pesticideData, setPesticideData] = useState([]);
  const [farmingData, setFarmingData] = useState({});
  const getPestiCides = useQuery(GET_ALL_PESTICIDE);
  useEffect(() => {
    if (getPestiCides?.data?.pesticideList?.rows?.length) {
      setPesticideData(getPestiCides?.data?.pesticideList?.rows);
    }
  }, [
    getPestiCides?.data?.pesticideList?.count,
    getPestiCides?.data?.pesticideList?.rows?.length,
  ]);
  console.log(pesticideData);
  // edit farming handler to set state 'farmingData' from FarmingDetailsCard
  const editFarmingHandler = (farmingData) => {
    setFarmingData(farmingData);
  };

  const toggle = () => {
    setModal((prevState) => !prevState);
    setFarmingData({});
  };

  const farmingToggle = () => {
    setFarmingModal((prevState) => !prevState);
  };
  const theme = useSelector((state) => state.theme.className);
  // filter by year <>

  // farming practice details integration with accordion

  const recentYear = [];

  // filter year from 'farmer' array
  farmer?.map((year) => recentYear.push(year?.farming_year));

  // Sort year in descending order
  const yearDesc = recentYear.sort(function (a, b) {
    return b - a;
  });

  // remove duplicate year from 'year array'
  let uniqueYear = [...new Set(yearDesc)];

  // filter item initialization in state
  const [searchItem, setSearchItem] = useState(uniqueYear[0]);
  useEffect(()=>{
    setSearchItem(uniqueYear[0])
  },[uniqueYear?.length])
  
  console.log("uniqueYear", uniqueYear);
  console.log("searchItem", searchItem);

  //get token from the local storage;
  const token = localStorage?.getItem("token");
  let user;
  //verify that token ;
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    user = decoded;
  });

  return (
    <Col md={12} lg={12}>
      <div className="mb-4 flex justify-content-between">
        {/* filter year get from here */}
        {farmer?.length > 0 ? (
          <Input
            className="react-select"
            style={{ width: "150px" }}
            name="select"
            type="select"
            onChange={(event) => setSearchItem(event.target.value)}
          >
            {uniqueYear.map((year) => (
              <option value={year}>{year}</option>
            ))}
          </Input>
        ) : (
          <div></div>
        )}

        {(user?.id === creator_id || user?.role === "admin") && (
          <Button className="mb-0" color="primary" onClick={toggle}>
            <TabPlusIcon />
            Firm Practice
          </Button>
        )}
      </div>
      {
        // filter by year
        farmer?.length > 0 &&
          farmer
            ?.filter((val) => {
              if (searchItem == "") {
                return val;
              } else if (val?.farming_year?.includes(searchItem)) {
                return val;
              }
            })?.map((farmerInfo, index) => (
              <FarmingDetailsCard
                setSearchItem={setSearchItem}
                editFarmingHandler={editFarmingHandler}
                farmingData={farmerInfo}
                setModal={setModal}
                setFarmingModal={setFarmingModal}
                farmingModal={farmingModal}
                modal={modal}
                key={farmerInfo.id}
                farmerInfo={farmerInfo}
                gen_id={gen_id}
                pesticideData={pesticideData}
                creator_id={creator_id}
              />
            ))
      }
      {
        // if farmeming practice not available
        farmer?.length == 0 && (
          <div>
            <h1 className="text-center">Please add farming practice</h1>
          </div>
        )
      }

      {/* FarmingPracticeEdit modal for create and update 'farmingData' */}
      <Modal
        className={`${
          theme === "theme-dark" ? "theme-dark" : "theme-light"
        } ltr-support login-modal-form`}
        isOpen={modal}
        toggle={toggle}
      >
        <ModalBody>
          {farmingData && (
            <FarmingPracticeEdit
              toggle={toggle}
              gen_id={gen_id}
              farmingPractice={farmingData}
              pesticideData={pesticideData}
            />
          )}
        </ModalBody>
      </Modal>
    </Col>
  );
};

export default FarmingPractice;
