import React from "react";
import { decodeToken } from "react-jwt";
import { Redirect, Route } from "react-router-dom";
import Layout from "../../../Layout/index";
import Dashboard from "../../../Dashboard";
import Account from "./Account";
import AgentProfile from "./AgentProfile";
import Leave from "./Leave";
import Department from "./Department";
import UserManagement from "./UserManagement";
import Customer from "./Customer";
import { loginInfo } from "../../../../utils/helpers";
import Company from "./Company";
import IotSwitchRoutes from "./IotSwitchRoutes";
import Attendance from "./Attendance";
export default () => {
  const token = localStorage?.getItem("token");

  const user = decodeToken(token);
  const loggedInfo = loginInfo();

  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Route path="/account" component={Account} />
        <Route path="/profile" component={AgentProfile} />
        <Route path="/user" component={UserManagement} />
        <Route path="/attendance" component={Attendance} />
        <Route path="/leave" component={Leave} />
        <Route path="/department" component={Department} />
        <Route path="/company" component={Company} />
        <Route path="/project" component={Customer} />
        <Route path="/iot" component={IotSwitchRoutes} />
        {/* <Route path="/attendance" component={Attendance} /> */}
        {loggedInfo?.role == "Customer" ? <Route
          exact
          path="/"
          render={() =>
            <Redirect to="/project/list" />
          }
        /> : <Route
          exact
          path="/"
          render={() =>
            token !== null ? <Dashboard /> : <Redirect to="/log_in" />
          }
        />}
      </div>
    </div>
  );
};
