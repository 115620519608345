import React, { useState } from "react";

const ReadMore = ({ children, slice, length }) => {
  const text = children;
  const textSlice = slice ?? 15;
  const textLength = length ?? 50;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text?.split(" ").slice(0, textSlice).join(" ") : text}
      {text?.length > textLength ? (
        <span onClick={toggleReadMore} className="read-or-hide text-primary">
          {isReadMore ? "...read more" : " show less"}
        </span>
      ) : null}
    </p>
  );
};

export default ReadMore;
