import React, { useEffect, useRef, useState } from 'react';
import { Row, Button, ButtonToolbar, Card, CardBody, Col } from "reactstrap";
import { Field, Form } from "react-final-form";
import renderDropZoneMultipleField from "@/shared/components/form/DropZoneMultiple";
import renderSelectField from "@/shared/components/form/Select";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDateRangePicker from '@mui/lab/MobileDateRangePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import { Box } from '@material-ui/core';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@mui/material";
import { useHistory } from 'react-router';
import axios from 'axios';
import BASE_URL from "../../../utils/api/baseUrl";
import { useMutation } from '@apollo/client';
import { CREATE_PROJECT_PROPOSAL, UPDATE_PROJECT_PROPOSAL } from '../../../GraphQL/Mutation';
import { successMessage, warningMessage } from '../../ReactToastify';
import { loginInfo } from '../../../utils/helpers';
import FormField from '@/shared/components/form/FormField';
import { useQuery } from "@apollo/client";
import { GET_ALL_SERVICE_TYPE } from '../../../GraphQL/Queries';
import { projectTypes, applicationTypes, checkBoxValueRetrive } from '../../../utils/helpers';
import validate from './validate';
import { OnChange } from 'react-final-form-listeners';
import { CustomCheckBoxField } from '../../../shared/components/form/CustomCheckBox';
import CustomRadioButton from '../../../shared/components/form/CustomRadioButton';
import Swal from 'sweetalert2';




const ProposalForm = () => {

    // clear input file after upload
    const ref = useRef();

    const resetFile = () => {
        ref.current.value = "";
    };
    // react hooks
    const history = useHistory();

    // variables
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    const loggedInfo = loginInfo();
    const updateData = history?.location?.state?.data;

    // states
    const [start, setStart] = useState(updateData?.start ? new Date(updateData?.start) : new Date());
    const [end, setEnd] = useState(updateData?.end ? new Date(updateData?.end) : null)
    const [images, setImages] = useState([]);
    const [projectExpand, setProjectExpand] = useState({
        hardware: false,
        software: false
    })


    // mutations
    const [createProjectProposal, { data, loading }] = useMutation(CREATE_PROJECT_PROPOSAL);
    const [updateProjectProposal] = useMutation(UPDATE_PROJECT_PROPOSAL);

    // queries
    const loadAllService = useQuery(GET_ALL_SERVICE_TYPE);
    useEffect(() => {
        loadAllService?.refetch();
    }, [loadAllService?.data?.getAllServiceType]);

    const serviceTypesArray = loadAllService?.data?.getAllServiceType?.map(item => ({
        label: item?.name,
        name: item?.id
    }))
    // default value
    const defaultServiceType = updateData?.service_type && JSON?.parse(updateData?.service_type);
    const defaultProjectType = updateData?.project_type && JSON?.parse(updateData?.project_type)[0];
    const defaultProjectTypeValue = updateData?.project_type && JSON?.parse(updateData?.project_type)[1];
    const [serviceType, setServiceType] = useState(defaultServiceType ? defaultServiceType : [])
    // functions
    const onChangeHandler = async (event) => {
        const value = Object.values(event.target.files);
        URL.createObjectURL(event.target.files[0]);
        value?.map(async (value) => {
            const formData = new FormData();
            formData.append("files", value);
            const file = await axios.post(
                `${BASE_URL}/api/files/upload`,
                formData,
                {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                }
            );
            setImages((prevState) => [...prevState, { url: file?.data?.data }]);
        });
        resetFile(); // reset input file


    }
    const onSubmit = async (values) => {
        // form validation start
        if (!values.title) {
            warningMessage(`Title field shouldn’t be empty`, 3000);
            return false;
        }
        if (serviceType?.length == 0) {
            warningMessage("Service type shouldn’t be empty", 3000);
            return false;
        }
        if (!values.project_type) {
            warningMessage("Project type shouldn’t be empty", 3000);
            return false;
        }
        if (projectExpand?.software) {
            if (!values.software_type) {
                warningMessage("Software type shouldn’t be empty", 3000);
                return false;
            }
        }
        if (projectExpand?.hardware) {
            if (!values.hardware_type) {
                warningMessage("Hardware type shouldn’t be empty", 3000);
                return false;
            }
        }
        if (!values.cilent_type) {
            warningMessage("Client type field shouldn’t be empty", 3000);
            return false;
        }
        if (!values.description) {
            warningMessage("Description field shouldn’t be empty", 3000);
            return false;
        }
        // form validation close

        const formData = {
            title: values.title,
            description: values.description,
            images: JSON.stringify(images),
            start: start,
            end: end,
            customer_id: loggedInfo?.id,
            client_type: values?.cilent_type,
            project_type: values?.project_type == 1 ? JSON.stringify([1, values?.software_type?.value]) : values?.project_type == 2 ? JSON.stringify([2, values?.hardware_type?.value]) : null,
            service_type: JSON.stringify(serviceType),
        }
        const updateFormData = {
            id: updateData?.id,
            title: values.title,
            description: values.description,
            images: JSON.stringify(images),
            start: start,
            end: end,
            client_type: values?.cilent_type,
            project_type: values?.project_type == 1 ? JSON.stringify([1, values?.software_type?.value]) : values?.project_type == 2 ? JSON.stringify([2, values?.hardware_type?.value]) : null,
            service_type: JSON.stringify(serviceType),
        }



        if (updateData?.id) {
            const result = await updateProjectProposal({
                variables: updateFormData
            })
            if (result?.data?.updateProjectProposal?.result_code == "000") {
                Swal.fire({
                    icon: 'success',
                    title: result?.data?.updateProjectProposal?.status,
                })
                history.push("/project/list");
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something went wrong!',
                    text: 'Try again.',
                })
            }
        } else {
            const result = await createProjectProposal({
                variables: formData
            })
            if (result?.data?.createProjectProposal?.result_code == "000") {
                Swal.fire({
                    icon: 'success',
                    title: result?.data?.createProjectProposal?.status,
                })
                history.push("/project/list");
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something went wrong!',
                    text: 'Try again.',
                })
            }
        }


    }




    // default value for update
    // const defaultServiceType = loadAllService?.data?.getAllServiceType?.map(item => ({
    //     label: item?.name,
    //     value: item?.id
    // })).find(item => item?.value == updateData?.service_type ? updateData?.service_type : null)



    // project proposal form array
    const projectTypesArray = [
        {
            name: 'software',
            label: 'Software',
            defaultChecked: true
        },
        {
            name: 'hardware',
            label: 'Hardware',
        },
    ];

    const clientTypesArray = [
        {
            label: 'Individual',
            name: 'individual',
            defaultChecked: true
        },
        {
            label: 'Company',
            name: 'company'
        }
    ];
    const projectTypesRadioArray = [
        {
            name: 'project_type',
            label: 'Software',
            radioValue: 1
        },
        {
            name: 'project_type',
            label: 'Hardware',
            radioValue: 2
        },
    ];
    const clientTypesRadioArray = [
        {
            label: 'Individual',
            name: 'cilent_type',
            radioValue: 1
        },
        {
            label: 'Company',
            name: 'cilent_type',
            radioValue: 2
        }
    ];
    const softwareType = [
        {
            label: "Web",
            value: 1
        },
        {
            label: "Mobile",
            value: 2
        },
    ]
    const hardwareType = [
        {
            label: "Electronics",
            value: 1
        },
        {
            label: "Power/Renewable",
            value: 2
        },
    ]

    const onChange = (value) => {
    }

    // remove uploaded file
    const uploadRemoveHandler = async (url) => {

        // first remove from s3 bucket
        let res = await axios.post(
            `${BASE_URL}/api/files/delete`,
            { url },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        // then remove from images state
        if (res.data) {
            const restImg = images?.filter(item => item?.url != url);
            setImages(restImg)
        }
    }



    return (
        <Col md={12} lg={12} xl={12}>
            <Card>
                <CardBody>
                    <Col md={12}>
                        <h3 className="page-title">{updateData?.id ? "Update" : "New "} project proposal`</h3>
                    </Col>
                    <Form onSubmit={onSubmit}>
                        {({ handleSubmit, values }) => {
                            useEffect(() => {
                                if (values?.project_type == 1) {
                                    setProjectExpand({ hardware: false, software: true })
                                } else if (values?.project_type == 2) {
                                    setProjectExpand({ hardware: true, software: false })
                                }
                            }, [values?.project_type])
                            return (
                                <form className="form" onSubmit={handleSubmit}>
                                    <Col md={12} lg={6} xl={6} className="mx-auto">
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Title</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="title"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Enter project title"
                                                    isAboveError
                                                    defaultValue={updateData?.title ? updateData?.title : null}
                                                />
                                            </div>
                                        </div>

                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Duration</span>
                                            <div className="form__form-group-field leave-calender">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <MobileDatePicker
                                                        label="Start"
                                                        inputFormat='dd/MM/yyyy'
                                                        value={start}
                                                        onChange={(newValue) => {
                                                            setStart(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                    &nbsp;&nbsp;&nbsp;
                                                    <MobileDatePicker
                                                        label="End"
                                                        inputFormat='dd/MM/yyyy'
                                                        value={end}
                                                        onChange={(newValue) => {
                                                            setEnd(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />


                                                </LocalizationProvider>

                                            </div>

                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Service Type</span>
                                            <div className="form__form-group-field">
                                                {serviceTypesArray?.map(item => (
                                                    <div key={`index_${item.label}`} className="form__form-group form__form-group-field">
                                                        <CustomCheckBoxField
                                                            name={`${item.name}`}
                                                            type="checkbox"
                                                            value={item.label}
                                                            id={item.id}
                                                            onChange={(e) => {
                                                                const check = serviceType.find(item => item == e.target.name)
                                                                if (!check) {
                                                                    setServiceType([...serviceType, e.target.name])
                                                                }

                                                                else {
                                                                    let list = [...serviceType];
                                                                    const index = serviceType.findIndex(data => data == e.target.name);
                                                                    list.splice(index, 1);

                                                                    setServiceType(list);
                                                                }
                                                            }}
                                                            label={item.label}
                                                            checked={serviceType?.find(values => values == item.name)}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Project Type</span>
                                            <div className="form__form-group-field">
                                                {projectTypesRadioArray?.map((item, index) => (
                                                    <div key={`index_${item.label}`} className="form__form-group form__form-group-field">
                                                        <Field
                                                            name={`${item.name}`}
                                                            component={renderRadioButtonField}
                                                            label={item.label}
                                                            radioValue={index + 1}
                                                            initialValue={defaultProjectType ? defaultProjectType : null}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {projectExpand?.software ? <div className="form__form-group">
                                            <span className="form__form-group-label">Software Type</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="software_type"
                                                    component={renderSelectField}
                                                    options={softwareType}
                                                    defaultValue={softwareType?.find(item => item?.value == defaultProjectTypeValue)}
                                                    placeholder="Select software type"
                                                    isAboveError
                                                />
                                            </div>
                                        </div> : null}
                                        {projectExpand?.hardware ? <div className="form__form-group">
                                            <span className="form__form-group-label">Hardware Type</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="hardware_type"
                                                    component={renderSelectField}
                                                    options={hardwareType}
                                                    defaultValue={hardwareType?.find(item => item?.value == defaultProjectTypeValue)}
                                                    placeholder="Select hardware type"
                                                    isAboveError
                                                />
                                            </div>
                                        </div> : null}

                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Client Type</span>
                                            {clientTypesRadioArray?.map(item => (
                                                <div key={`index_${item.label}`} className="form__form-group form__form-group-field">
                                                    <Field
                                                        name={`${item.name}`}
                                                        component={renderRadioButtonField}
                                                        label={item.label}
                                                        radioValue={item.radioValue}
                                                        initialValue={updateData?.client_type ? updateData?.client_type : null}
                                                    />
                                                </div>
                                            ))}

                                        </div>


                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Description</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="description"
                                                    component="textarea"
                                                    type="text"
                                                    placeholder="Enter project descriptions"
                                                    defaultValue={updateData?.description ? updateData?.description : null}
                                                />
                                            </div>
                                        </div>


                                        <div className="input-group">
                                            <input ref={ref} type="file" id="files" onChange={(event) => onChangeHandler(event)} className="hidden-xs-up" name="files" multiple />
                                        </div>


                                        {images?.length > 0 && <p className="text-success">{images?.length} {images?.length > 1 ? " files" : " file"} ready for upload...</p>}
                                        <div className="upload-img-container d-flex flex-wrap">
                                            {images?.map(item => {
                                                const extension = item?.url?.split('.')[item?.url?.split('.')?.length - 1];
                                                const thumb = ["pdf", "PDF", "doc", "docx", "ppt"];
                                                return (
                                                    thumb?.includes(extension)
                                                        ? <div className="position-relative mt-4 mr-3">
                                                            <img className="upload-img" src="https://cutewallpaper.org/24/file-png/collections.png" alt="image" ></img>
                                                            <span onClick={() => uploadRemoveHandler(item?.url)} className="upload-delete-btn position-absolute">❌</span>
                                                        </div>
                                                        : <div className="position-relative mt-4 mr-3">
                                                            <img className="upload-img" src={item?.url} alt="image" ></img>
                                                            <span onClick={() => uploadRemoveHandler(item?.url)} className="upload-delete-btn position-absolute">❌</span>
                                                        </div>
                                                )
                                            })}
                                        </div>
                                        <div className="d-flex justify-content-end mt-3">
                                            <Button
                                                type="button"
                                                onClick={() => history.push("/project/list")}
                                            >
                                                Cancel
                                            </Button>
                                            {loading
                                                ? <Button className="btn btn-primary ml-1">
                                                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                                    Creating...
                                                </Button>
                                                : <Button color="primary" type="submit">{updateData?.id ? "Update" : `Create`}</Button>}
                                        </div>
                                    </Col>
                                </form>
                            );
                        }}
                    </Form>
                </CardBody>
            </Card>
        </Col>
    );
};

export default ProposalForm;