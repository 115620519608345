/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CustomerRegister from "../../Account/CustomerRegister";
import LockScreen from "../../Account/LockScreen/index";
import LogIn from "../../Account/LogIn/index";
import LogInPhoto from "../../Account/LogInPhoto/index";
import Register from "../../Account/Register/index";
import RegisterPhoto from "../../Account/RegisterPhoto/index";
import ResetPassword from "../../Account/ResetPassword/index";
import ResetPasswordPhoto from "../../Account/ResetPasswordPhoto";
import NotFound404 from "../../DefaultPage/404/index";
import MainWrapper from "../MainWrapper";
import WrappedRoutes from "./WrappedRoutes";

const Router = () => {
  const [token, setToken] = useState(
    JSON.stringify(localStorage?.getItem("token"))
  );
  useEffect(() => {
    setToken(JSON.stringify(localStorage?.getItem("token")));
  }, [JSON.stringify(localStorage?.getItem("token")).length]);
  return (
    <MainWrapper>
      <main>
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              token !== null ? <WrappedRoutes /> : <Redirect to="/log_in" />
            }
          />
          <Route path="/404" component={NotFound404} />
          <Route path="/lock_screen" component={LockScreen} />
          <Route path="/log_in" component={LogIn} />
          <Route path="/log_in_photo" component={LogInPhoto} />
          <Route path="/register" component={Register} />
          <Route path="/register_photo" component={RegisterPhoto} />
          <Route path="/customer_register" component={CustomerRegister} />
          <Route path="/reset_password" component={ResetPassword} />
          <Route path="/reset_password_photo" component={ResetPasswordPhoto} />
          <Route path="/" component={WrappedRoutes} />
        </Switch>
      </main>
    </MainWrapper>
  );
};

export default Router;
