import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { CREATE_PERMISSIONS } from "../../GraphQL/Mutation";

const AddPermission = ({ toggle, refetch }) => {
  const [permissionValue, setPermissionValue] = useState("");
  const [createPermission, permissionData] = useMutation(CREATE_PERMISSIONS);
  const handlePermissions = async () => {
    await createPermission({
      variables: {
        permission_name: permissionValue,
      },
    });
    setPermissionValue("");
    await refetch();
    toggle();
  };

  return (
    <>
      <ModalHeader>
        <h1>New Permission</h1>
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup as={Row} controlId="formPlaintextEmail">
            <Label column sm="4">
              Enter Permission
            </Label>
            <Col sm="6">
              <input
                className="form-control"
                value={permissionValue}
                onChange={(e) => setPermissionValue(e.target.value)}
                placeholder="Enter Permission Name"
              />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-danger" onClick={toggle}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={handlePermissions}>
          Save
        </button>
      </ModalFooter>
    </>
  );
};

export default AddPermission;
