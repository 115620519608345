import React, { useState } from "react";

const DeviceId = ({ setDeviceId, setDeviceIdEdit, deviceId }) => {
  const [onDeviceId, setOnDeviceId] = useState(deviceId ?? "");
  const handleClick = () => {
    localStorage.setItem("localDevice", onDeviceId);
    setDeviceId(onDeviceId);
    setDeviceIdEdit(false);
  };
  return (
    <>
      <div className="d-flex justify-content-center mt-3">
        <input
          className="form-control w-50 text-center"
          placeholder="Enter device id"
          type="text"
          value={onDeviceId}
          onChange={(e) => setOnDeviceId(e.target.value)}
          onKeyPress={(e) => e.key == "Enter" && handleClick()}
        />
      </div>
      <button
        className="btn btn-sm btn-primary mt-3"
        onClick={() => handleClick()}
      >
        {deviceId ? "Update" : "Add"}
      </button>
    </>
  );
};

export default DeviceId;
