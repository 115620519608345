import classnames from "classnames";
import React, { useState } from "react";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import FarmersProblem from "./FarmersProblem";
import FarmingPractice from "./FarmingPractice";
import FinancialInfo from "./FinancialInfo";
import ProfileActivities from "./ProfileActivities";

const ProfileTabs = ({ farmer }) => {
  const [activeTab, setActiveTab] = useState("1");
  const initialValues = {
    username: "Larry Boom",
    email: "boom@mail.com",
  };

  const handleToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <Col md={12} lg={12} xl={8}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => handleToggle("1")}
                >
                  Generic Info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => handleToggle("4")}
                >
                  Farming Practice
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <ProfileActivities farmer={farmer} />
              </TabPane>
              <TabPane tabId="2">
                <FinancialInfo
                  farmer={farmer?.finance}
                  gen_id={farmer.id}
                  creator_id={farmer?.agent_id}
                />
              </TabPane>
              <TabPane tabId="3">
                <FarmersProblem
                  farmer={farmer?.farmerProblem}
                  gen_id={farmer.id}
                  creator_id={farmer?.agent_id}
                />
              </TabPane>
              <TabPane tabId="4">
                <FarmingPractice
                  farmer={farmer?.farmingPractice}
                  gen_id={farmer.id}
                  creator_id={farmer?.agent_id}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default ProfileTabs;
