import { useMutation } from "@apollo/client";
import { CREATE_LEAVE_DEDUCTION } from "../../Mutation";

export default (props) => {
  const [createLeaveDeduction, { data, loading }] = useMutation(
    CREATE_LEAVE_DEDUCTION,
    {
      ...props,
    }
  );
  return {
    data: data?.createLeaveDeduction,
    loading,
    createLeaveDeduction,
  };
};
