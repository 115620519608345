import { useMutation } from "@apollo/client";
import { css } from "@emotion/react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SavingsIcon from "@mui/icons-material/Savings";
import jwt from "jsonwebtoken";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import MoonLoader from "react-spinners/MoonLoader";
import { Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import { DELETE_FINANCE } from "../../../../GraphQL/Mutation";
import { farmingAdd } from "../../../../redux/actions/farmerAction";
const FinancialInfo = ({ farmer, gen_id, creator_id }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [createUser, { loading, data, error }] = useMutation(DELETE_FINANCE);
  //get token from the local storage;
  const token = localStorage?.getItem("token");
  let user;
  //verify that token ;
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    user = decoded;
  });
  let [color, setColor] = useState("rgb(54, 215, 183)");
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  return (
    <div
      className="personal-info"
      style={{ border: "none", marginTop: "20px" }}
    >
      <Row>
        <Col md={10}>
          <h4 className="bold-text">
            <SavingsIcon fontSize="small" /> Financial Information{" "}
          </h4>
        </Col>
        {(user?.id === creator_id || user?.role === "admin") && (
          <Col md={2}>
            <button
              className="inbox__email-btn float-right"
              type="button"
              aria-label="edit"
              onClick={async () => {
                swalWithBootstrapButtons
                  .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No, cancel!",
                    reverseButtons: true,
                  })
                  .then(async (result) => {
                    if (result.isConfirmed) {
                      await createUser({
                        variables: { gen_id },
                        onQueryUpdated(observableQuery) {
                          // Define any custom logic for determining whether to refetch
                          if (shouldRefetchQuery(observableQuery)) {
                            return observableQuery.refetch();
                          }
                        },
                      });
                      dispatch(farmingAdd());
                      swalWithBootstrapButtons.fire(
                        "Deleted!",
                        "Your file has been deleted.",
                        "success"
                      );
                    } else if (
                      /* Read more about handling dismissals below */
                      result.dismiss === Swal.DismissReason.cancel
                    ) {
                      swalWithBootstrapButtons.fire(
                        "Cancelled",
                        "Your imaginary file is safe :)",
                        "error"
                      );
                    }
                  });
              }}
            >
              <DeleteIcon fontSize="small" />
            </button>

            <button
              className="inbox__email-btn float-right mr-1"
              type="button"
              aria-label="edit"
              onClick={() =>
                history.push({
                  pathname: "/account/finance_info",
                  state: {
                    id: gen_id ? gen_id : "",
                    financeData: farmer,
                  },
                })
              }
            >
              <EditIcon fontSize="small" />
            </button>
          </Col>
        )}
      </Row>
      {farmer?.gen_id ? (
        loading ? (
          <div>
            <MoonLoader
              color={color}
              loading={loading}
              css={override}
              size={80}
              speedMultiplier={0.6}
            />
          </div>
        ) : (
          <div>
            <Row className="mt-1">
              <Col md={6}>
                <div className="profile-info">
                  <h5 className="subhead">Land Area:</h5>
                  <h5>
                    {" "}
                    {`${
                      farmer?.land_area ? farmer?.land_area + " Acres" : ""
                    } `}{" "}
                  </h5>
                </div>
              </Col>
              <Col md={6}>
                <div className="profile-info">
                  <h5 className="subhead">Own Or Leases:</h5>
                  <h5>{farmer?.own_leases}</h5>
                </div>
              </Col>
            </Row>

            <Row className="mt-1">
              <Col md={6}>
                <div className="profile-info">
                  <p className="bold-text">
                    Financial Support For Cultivation:
                  </p>
                  <ul className="list list--icon">
                    {farmer?.finance_support?.map((data, index) => (
                      <li>
                        <p>
                          <span className="lnr lnr-checkmark-circle" />
                          {data?.name}{" "}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col md={6}></Col>
            </Row>
            <br />
            <Row>
              <Col md={10}>
                <p className="bold-text">Occupation </p>
              </Col>
            </Row>
            {farmer?.occupation?.map((data, index) => (
              <Row className="my-2">
                <Col md={6}>
                  <div className="profile-info">
                    <h5 className="subhead">Name:</h5>
                    <h5>{data?.name}</h5>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="profile-info">
                    <h5 className="subhead">Income:</h5>
                    <h5>{data?.incomes}</h5>
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        )
      ) : (
        <div>
          <h4 className="text-center">Content is not available</h4>
        </div>
      )}
    </div>
  );
};

export default FinancialInfo;
